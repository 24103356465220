import * as amplitude from "@amplitude/analytics-browser";

const helpers = {
  initialize() {
    if (import.meta.env.VITE_AMPLITUDE_ANALYTICS_KEY) {
      amplitude.init(import.meta.env.VITE_AMPLITUDE_ANALYTICS_KEY);
    }
  },

  trackEvent(
    event: string,
    data: { [key: string]: string | number | boolean } = {}
  ) {
    if (import.meta.env.VITE_AMPLITUDE_ANALYTICS_KEY) {
      amplitude.track(event, data);
    }
  },
};

export default Object.freeze(helpers);
