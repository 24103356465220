<script setup lang="ts">
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
import type { ITouchPoint } from '@/types/ITouchPoint';
import {
	BaseButton,
	BaseBadge,
	BaseAvatar,
	TextAccordion,
} from '@magma-app/magma-lapilli';
import { onMounted } from 'vue';
import { ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useI18n } from 'vue-i18n';

const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

const route = useRoute();

const isCopied = ref(false);
const isInitialized = ref(false);

const { t } = useI18n();

const handleCopyMessage = () => {
	if (!relationsStore.relation?.relation?.helpee?.welcomeMessage) return;

	window.navigator.clipboard.writeText(
		relationsStore.relation?.relation?.helpee?.welcomeMessage
	);
	isCopied.value = true;

	setTimeout(() => {
		isCopied.value = false;
	}, 2000);
};

const handleTouchPointClick = (touchpoints: ITouchPoint[]) => {
	const moment = relationsStore.relation?.relation?.relationMoments[0];

	if (!moment) return;

	commonStore.setTouchpointsModal({
		moment: moment,
		name: relationsStore.relation?.relation?.helpee.firstname,
		open: true,
		touchpoints: touchpoints,
	});
};

watchEffect(async () => {
	isInitialized.value = false;

	await relationsStore.getRelation({
		relationId: parseInt(route?.params.relationId as string),
		type: 'helper',
	});

	isInitialized.value = true;

	const driverObj = driver({
		showProgress: true,
		showButtons: ['next', 'close'],
		popoverClass: 'magma-tour',
		progressText: ``,
		onPopoverRender: (popover, opts) => {
			const currentStep = opts.state.activeIndex;
			popover.progress.innerHTML = `
				<button class="${currentStep === 0 ? ' is-active' : ''}"></button>
				<button class="${currentStep === 1 ? ' is-active' : ''}"></button>
				<button class="${currentStep === 2 ? ' is-active' : ''}"></button>
				<button class="${currentStep === 3 ? ' is-active' : ''}"></button>
			`;
		},

		nextBtnText: t('startRelationTour.next'),
		prevBtnText: t('startRelationTour.prev'),
		doneBtnText: t('startRelationTour.finish'),

		steps: [
			{
				element: '.start-tour',
				popover: {
					title: t('startRelationTour.first.title'),
					description: t('startRelationTour.first.subtitle'),
				},
			},
			{
				element: '.step-2',
				popover: {
					title: t('startRelationTour.second.title'),
					description: t('startRelationTour.second.subtitle'),
					side: 'bottom',
					align: 'center',
				},
			},
			{
				element: '.step-3',
				popover: {
					title: t('startRelationTour.third.title'),
					description: t('startRelationTour.third.subtitle'),
					side: 'top',
					align: 'center',
				},
			},
			{
				element: '.step-4',
				popover: {
					title: t('startRelationTour.fourth.title'),
					description: t('startRelationTour.fourth.subtitle'),
					side: 'top',
					align: 'center',
				},
			},
		],
	});

	if (relationsStore?.relation?.isFirstRelation) {
		driverObj.drive();
	}
});
</script>

<template>
	<div
		v-if="isInitialized"
		class="start-tour w-full sm:h-[calc(100dvh_-_64px)] max-h-[100dvh] bg-background-subtle-hover sm:flex justify-center sm:items-center flex-col overflow-y-hidden box-border"
	>
		<div
			class="w-full sm:rounded-xl sm:border border-border-subtle sm:w-[390px] h-dvh sm:h-auto bg-white sm:overflow-hidden sm:shadow-lg flex flex-col relative"
		>
			<header class="w-full h-16 flex items-center justify-between px-5">
				<div class="flex items-center gap-2">
					<img
						:src="userStore.user?.organization?.logoUrl"
						class="w-8 h-8 rounded-full"
					/>
					<p
						class="text-mgm-txt-sm text-foreground-emphasis font-medium truncate"
					>
						{{ userStore.campaignInfo?.organizationName }}
					</p>
				</div>
				<img
					:src="userStore.user?.profilePictureUrl"
					class="w-8 h-8 rounded-full"
				/>
			</header>
			<div
				class="w-full h-44 sm:h-40 bg-background-brand-default flex items-center justify-center"
			>
				<img
					:src="relationsStore.relation?.relation?.helpee.profilePictureUrl"
					class="w-16 h-16 rounded-full border border-border-default shadow-lg -mt-3 sm:mt-0"
				/>
			</div>

			<div
				class="rounded-t-2xl bg-white pt-6 pb-24 px-5 overflow-y-auto z-10 flex-1 bottom-0 -mt-3 w-full"
			>
				<div class="step-2">
					<h1
						class="text-mgm-txt-xl font-medium text-foreground-emphasis mb-3"
						data-intro="Hello step one!"
					>
						{{ $t('helperStartRelation.meet') }}
						{{ relationsStore.relation?.relation?.helpee.firstname }}
					</h1>

					<section
						class="mb-3"
						v-if="relationsStore.relation?.relation?.helpee.expectation !== ''"
					>
						<p class="text-mgm-txt-sm text-foreground-default">
							{{ $t('helperStartRelation.qualification') }}
						</p>
						<TextAccordion
							:content="
								relationsStore.relation?.relation?.helpee.expectation || ''
							"
							:seeMoreButton="$t('generic.seeMore')"
							:seeLessButton="$t('generic.seeLess')"
						/>
					</section>

					<section
						v-for="criteria in relationsStore.relation?.relation?.helpee
							.criteria"
						class="mb-3"
					>
						<p class="text-mgm-txt-sm text-foreground-default">
							{{ criteria.labelHelpee }}
						</p>
						<p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
							{{ criteria.value }}
						</p>
					</section>

					<div
						v-if="relationsStore.relation?.relation?.helpee.touchPoints?.length"
						class="mb-6"
					>
						<p class="text-mgm-txt-sm text-foreground-default">
							{{ $t('helperStartRelation.touchPoints') }}
						</p>
						<div class="flex flex-wrap">
							<BaseBadge
								v-for="touchpoint in relationsStore.relation?.relation?.helpee
									.touchPoints"
								:key="touchpoint.name"
								:text="touchpoint.name"
								:class="[
									'mr-2 mt-2 last:mr-0',
									relationsStore.relation?.isReported
										? 'cursor-default'
										: 'cursor-pointer',
								]"
								size="lg"
								color="grey"
								icon-type="custom"
								@click="
									handleTouchPointClick(
										relationsStore.relation?.relation?.helpee.touchPoints
									)
								"
							>
								<template #icon>
									<BaseAvatar
										:src="touchpoint.icon"
										size="xs"
										shape="squared"
									/>
								</template>
							</BaseBadge>
						</div>
					</div>
				</div>

				<div class="step-3">
					<p class="text-mgm-txt-sm text-foreground-emphasis mb-2">
						{{ $t('helperStartRelation.messageExample') }}
					</p>
					<div
						class="w-full h-40 bg-background-subtle border border-border-subtle rounded-lg overflow-y-auto px-3 py-2 scrollbar"
					>
						<p
							class="text-foreground-default text-mgm-txt-sm"
							v-html="
								relationsStore.relation?.relation?.helpee?.welcomeMessage?.replace(
									/\n/g,
									'<br />'
								)
							"
						></p>
					</div>
					<BaseButton
						class="w-full mt-2"
						state="scdr"
						@click="handleCopyMessage"
					>
						{{
							isCopied
								? $t('helperStartRelation.copied')
								: $t('helperStartRelation.copyMessage')
						}}
					</BaseButton>
				</div>

				<div
					v-if="relationsStore.relation?.relation?.helpee.touchPoints?.length"
					class="step-4 pt-3 pb-10 fixed sm:static bottom-0 left-0 w-full bg-white px-4 sm:px-0"
				>
					<BaseButton
						class="w-full mt-2"
						state="prmr"
						@click="
							handleTouchPointClick(
								relationsStore.relation?.relation?.helpee.touchPoints
							)
						"
					>
						{{ $t('helperStartRelation.send') }}
					</BaseButton>
				</div>
			</div>
		</div>
		<div class="hidden sm:block">
			<a
				class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-border-default hover:text-foreground-subtle focus:text-foreground-subtle flex gap-2 items-center"
				href="https://www.magma.app/"
				target="_blank"
			>
				{{ $t('generic.powered') }}
				<img src="/img/logo_magma_full_bw.svg" alt="Magma" />
			</a>
		</div>
	</div>
</template>

<style>
.driver-popover.magma-tour {
	background-color: theme('colors.white');
	border-radius: theme('borderRadius.xl');
	padding: theme('spacing.4');
	width: calc(100% - 40px);
	max-width: none !important;
}

@media screen and (min-width: 640px) {
	.driver-popover.magma-tour {
		width: 350px;
	}
}

.driver-popover.magma-tour .driver-popover-title {
	color: #1c1f27;
	font-size: theme('fontSize.sm');
	font-weight: 500 !important;
	width: 66%;
}

.driver-popover.magma-tour .driver-popover-close-btn {
	top: 12px !important;
	right: 8px !important;
}

.driver-popover.magma-tour .driver-popover-description {
	color: #5f6c85;
	font-size: theme('fontSize.sm');
	font-weight: theme('fontWeight.normal');
}

.driver-popover.magma-tour .driver-popover-progress-text {
	display: flex !important;
	align-items: center;
	gap: 8px;
}

.driver-popover.magma-tour .driver-popover-progress-text button {
	background-color: #a4bcfd;
	border-radius: 100px;
	height: 8px;
	width: 8px;
	border: none;
	display: flex;
	margin: 0;
	padding: 0;
}

.driver-popover.magma-tour .driver-popover-progress-text button.is-active {
	background-color: #444ce7;
}

.driver-popover.magma-tour .driver-popover-next-btn {
	background-color: #444ce7;
	border-radius: 100px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 8px 16px;
	border: none;
	text-shadow: none;
}
</style>
