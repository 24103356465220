// NPM
import { ref, computed } from "vue";

export function useConvertHexToRgb() {
  const hexColor = ref<string>("");

  const rgbColor = computed(() => {
    if (hexColor.value) {
      const hexColorSplitted = hexColor.value.match(/\w\w/g) as [
        string,
        string,
        string
      ];

      const [r, g, b] = hexColorSplitted.map((x) => parseInt(x, 16));

      return `${r},${g},${b}`;
    } else {
      return hexColor.value;
    }
  });

  return {
    hexColor,
    rgbColor,
  };
}
