<script setup lang="ts">
// NPM
import { onBeforeMount, reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import {
	createI18nMessage,
	email,
	helpers,
	required,
} from '@vuelidate/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

// Components
import {
	BaseIcon,
	FieldAvatarUploader,
	FieldInput,
	FieldPhoneNumber,
	FieldToggle,
} from '@magma-app/magma-lapilli';

// Stores
import { useUserStore } from '@/stores/user';
import { onMounted } from 'vue';
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: 'global' });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const formData = reactive({
	profilePicture: null,
	firstname: userStore.user?.firstname || '',
	lastname: userStore.user?.lastname || '',
	email: userStore.user?.email || '',
	phoneNumber: userStore.user?.phoneNumber || '',
	textNotifications: userStore.user?.acceptPhoneNotifications || undefined,
	emailNotifications: userStore.user?.acceptEmailNotifications || undefined,
	personalEmail:
		userStore.user?.notificationEmail || userStore.user?.email || '',
	position: userStore.user?.position || '',
});
const formDataSaved = ref(JSON.stringify(formData));
const saved = ref(false);

// Form validation
const v$ = useVuelidate(
	{
		firstname: {
			required,
		},
		lastname: {
			required,
		},
		personalEmail: {
			email,
		},
		phoneNumber: {
			required: withI18nMessage(required, {
				messagePath: () => t('generic.phoneNumber') + t('validation.required'),
			}),
			validPhoneNumber: withI18nMessage(
				(value: string) => {
					return value?.length != 0 ? isValidPhoneNumber(value) : true;
				},
				{
					messagePath: () => t('generic.phoneNumber') + t('validation.valid'),
				}
			),
		},
		profilePicture: {
			validSize: helpers.withMessage(
				`${t('input.rules.picture.size')} 9MB`,
				(value: File) => {
					if (value) {
						return value ? value.size < 9000000 : true;
					} else {
						return true;
					}
				}
			),
		},
	},
	formData
);

// Methods
const onNotificationsChange = (
	value: boolean,
	id: 'textNotifications' | 'emailNotifications'
) => {
	if (id === 'textNotifications' && !value && !formData.emailNotifications) {
		formData.emailNotifications = true;
	} else if (
		id === 'emailNotifications' &&
		!value &&
		!formData.textNotifications
	) {
		formData.textNotifications = true;
	}

	saveProfile();
};

const saveProfile = async () => {
	v$.value.$touch();

	if (
		(await v$.value.$validate()) &&
		formDataSaved.value != JSON.stringify(formData)
	) {
		try {
			await userStore.updateUser({
				firstname: formData.firstname,
				lastname: formData.lastname,
				phoneNumber: formData.phoneNumber,
				notificationEmail: formData.personalEmail,
				acceptPhoneNotifications: formData.textNotifications,
				acceptEmailNotifications: formData.emailNotifications,
				position: formData.position,
			});

			formDataSaved.value = JSON.stringify(formData);
			saved.value = true;

			setTimeout(() => {
				saved.value = false;
			}, 1500);
		} catch (error) {
			console.log(error);
		}
	}
};

const uploadProfilePicture = async (profilePicture: File) => {
	if (await v$.value.profilePicture.$validate()) {
		await userStore.uploadPicture({
			profilePicture,
		});

		saved.value = true;

		if (saved.value) {
			setTimeout(() => {
				saved.value = false;
			}, 1500);
		}
	}
};
</script>

<template>
	<h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
		{{ $t('settingsProfileView.profileHeading') }}
	</h2>

	<FieldAvatarUploader
		id="profilePicture"
		v-model="formData.profilePicture"
		class="mt-5"
		:cta="$t('generic.change')"
		:label="$t('generic.profilePicture')"
		:locale="$i18n.locale"
		:preview-url="userStore.user?.profilePictureUrl || undefined"
		:validation="v$.profilePicture"
		@change="uploadProfilePicture"
	/>

	<FieldInput
		id="firstname"
		v-model="formData.firstname"
		class="mt-5"
		:locale="$i18n.locale"
		:label="$t('generic.firstName')"
		:validation="v$.firstname"
		@blur="saveProfile"
	/>

	<FieldInput
		id="lastname"
		v-model="formData.lastname"
		class="mt-5"
		:locale="$i18n.locale"
		:label="$t('generic.lastName')"
		:validation="v$.lastname"
		@blur="saveProfile"
	/>

	<FieldInput
		id="email"
		v-model="formData.email"
		class="mt-5"
		role="email"
		type="email"
		:locale="$i18n.locale"
		:label="$t('settingsProfileView.accountEmail')"
		disabled
	/>

	<FieldPhoneNumber
		id="phone"
		v-model="formData.phoneNumber"
		class="mt-5"
		:locale="$i18n.locale"
		:label="$t('generic.phoneNumber')"
		:validation="v$.phoneNumber"
		@blur="saveProfile"
	/>

	<FieldInput
		id="text"
		v-model="formData.position"
		class="mt-5"
		role="email"
		type="email"
		:locale="$i18n.locale"
		:label="'Position'"
		@blur="saveProfile"
	/>

	<h2 class="mt-10 text-2xl font-bold leading-7 text-foreground-emphasis">
		{{ $t('settingsProfileView.setNotifications') }}
	</h2>

	<FieldToggle
		id="textNotifications"
		v-model="formData.textNotifications"
		class="mt-5"
		:label="$t('settingsProfileView.textNotifications')"
		:description="$t('settingsProfileView.textNotificationsDescription')"
		@change="onNotificationsChange"
	/>

	<FieldToggle
		id="emailNotifications"
		v-model="formData.emailNotifications"
		class="mt-5"
		:label="$t('settingsProfileView.emailNotifications')"
		:description="$t('settingsProfileView.emailNotificationsDescription')"
		@change="onNotificationsChange"
	/>

	<FieldInput
		id="email"
		v-model="formData.personalEmail"
		class="mt-5"
		role="email"
		type="email"
		:locale="$i18n.locale"
		autocomplete="email"
		@blur="saveProfile"
	/>

	<div
		v-if="saved"
		class="sticky bottom-0 flex w-full justify-end bg-background-default px-2 text-sm text-foreground-default"
	>
		saved
		<BaseIcon class="ml-0.5 mb-1" icon="check" :size="12" color="#5F6C85" />
	</div>
</template>
