<script setup lang="ts">
import { FieldInput } from '@magma-app/magma-lapilli';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps(['modelValue', 'validation', 'disableEmail']);
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

onMounted(() => {
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ t('helperOnboarding.email.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ t('helperOnboarding.email.subtitle') }}
		</h3>
		<FieldInput
			type="email"
			:placeholder="t('helperOnboarding.email.label')"
			@input="emit('update:modelValue', $event.target.value)"
			:model-value="props.modelValue"
			:label="t('helperOnboarding.email.label')"
			:validation="props.validation"
			required
			:disabled="props.disableEmail"
		/>
	</div>
</template>
