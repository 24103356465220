<script setup lang="ts">
// NPM
import { onBeforeMount, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, url } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// Components
import {
  BaseIcon,
  BaseLoader,
  FieldInput,
  FieldPhoneNumber,
} from "@magma-app/magma-lapilli";

// Stores
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const initialized = ref(false);
const saved = ref(false);
const formData = reactive({} as { [key: string]: string });
const formDataSaved = reactive({ ...formData });

// Form validation
const formRules = reactive({} as { [key: string]: any });
const watchFields = ref<string[]>([]);
const v$ = useVuelidate(formRules, formData);

// Lifecycle hooks
onBeforeMount(async () => {
  await userStore.getUserTouchpoints();

  if (userStore.userTouchpoints) {
    userStore.userTouchpoints.forEach((touchpoint) => {
      if (touchpoint.userTouchPointValue) {
        formData[touchpoint.name] = touchpoint.userTouchPointValue;
        formDataSaved[touchpoint.name] = touchpoint.userTouchPointValue;
      }

      if (touchpoint.type === "phoneNumber") {
        formRules[touchpoint.name] = {
          validPhoneNumber: withI18nMessage(
            (value: string) => {
              if (value != "") {
                return isValidPhoneNumber(value);
              } else {
                return true;
              }
            },
            {
              messagePath: () =>
                t("generic.phoneNumber") + t("validation.valid"),
            }
          ),
        };
      } else if (touchpoint.type === "email") {
        formRules[touchpoint.name] = {
          email: withI18nMessage(email, {
            messagePath: () => t("validation.invalidEmail"),
          }),
        };
      } else if (touchpoint.type === "pseudo") {
        formRules[touchpoint.name] = {
          url: withI18nMessage(url, {
            messagePath: () => t("validation.urlValidation"),
          }),
        };
      }

      watchFields.value.push(touchpoint.name);
    });
  }

  // Set up watchers
  if (v$.value) {
    watchFields.value.forEach((fieldName) => {
      watch(
        () => formData[fieldName],
        async () => {
          if (v$.value && v$.value[fieldName]) {
            await v$.value[fieldName].$validate();
          } else {
            console.error(`Validation for ${fieldName} is not defined.`);
          }
        }
      );
    });
  }

  initialized.value = true;
});

// Methods
const saveTouchpoint = async (touchPointId: number, touchpointName: string) => {
  if (v$.value && v$.value[touchpointName]) {
    v$.value[touchpointName].$touch();

    // Make sure the value is not empty and value has changed
    if (
      formData[touchpointName] !== formDataSaved[touchpointName] &&
      (await v$.value[touchpointName].$validate())
    ) {
      try {
        await userStore.updateUserTouchpoint({
          touchPointId,
          value: formData[touchpointName],
        });

        // Update formdata saved to the new value
        formDataSaved[touchpointName] = formData[touchpointName];

        saved.value = true;

        if (saved.value) {
          setTimeout(() => {
            saved.value = false;
          }, 1500);
        }
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    console.error(`Validation for ${touchpointName} is not defined.`);
  }
};
</script>

<template>
  <template v-if="initialized">
    <h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
      {{ $t("settingsTouchpointsView.touchpointsHeading") }}
    </h2>

    <p class="mt-4 text-sm text-foreground-emphasis">
      {{ $t("settingsTouchpointsView.touchpointsDescription") }}
    </p>

    <div
      v-for="touchpoint of userStore.userTouchpoints"
      :key="touchpoint.id"
      class="mt-5 flex items-center justify-between"
    >
      <div class="flex items-center text-sm text-foreground-emphasis">
        <img class="mr-3 h-6 w-6" :src="touchpoint.icon" />
        <span>{{ touchpoint.name }}</span>
      </div>

      <FieldPhoneNumber
        v-if="touchpoint.type === 'phoneNumber'"
        :id="touchpoint.name"
        v-model="formData[touchpoint.name]"
        class="w-56"
        :locale="$i18n.locale"
        placeholder="06 06 06 06 06"
        :validation="v$[touchpoint.name]"
        @blur="saveTouchpoint(touchpoint.id, touchpoint.name)"
      />

      <FieldInput
        v-else
        :id="touchpoint.name"
        v-model="formData[touchpoint.name]"
        class="w-56"
        :role="touchpoint.type === 'email' ? 'email' : 'default'"
        :type="touchpoint.type === 'email' ? 'email' : 'url'"
        :locale="$i18n.locale"
        :placeholder="
          touchpoint.type === 'email'
            ? `email@${$t('generic.example')}.com`
            : `https://www.${$t('generic.example')}.com/${$t(
                'generic.example'
              )}`
        "
        :validation="v$[touchpoint.name]"
        @blur="saveTouchpoint(touchpoint.id, touchpoint.name)"
      />
    </div>

    <div
      v-if="saved"
      class="sticky bottom-0 flex w-full justify-end bg-background-default px-2 text-sm text-foreground-default"
    >
      saved
      <BaseIcon class="ml-0.5 mb-1" icon="check" :size="12" color="#5F6C85" />
    </div>
  </template>

  <BaseLoader v-else class="min-h-screen" />
</template>
