<script setup lang="ts">
// NPM
import { nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// Components
import { BaseAvatar, BaseIcon, BaseLoader } from "@magma-app/magma-lapilli";
import HelpeeSignUp from "@/components/sections/HelpeeSignUp.vue";
import TheFooter from "@/components/shell/TheFooter.vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

// Plugins
const { locale } = useI18n();
const route = useRoute();
const router = useRouter();

// Reactive variables
const initialized = ref(false);
const campaignsView = ref(false);
const containerWidth = ref(0);
const root = ref<HTMLInputElement>();
const widget = ref(false);

// Lifecycle hooks
onMounted(async () => {
  if (
    JSON.stringify(router.options.history.state.back).includes(
      "/helpee-signup/"
    )
  ) {
    campaignsView.value = true;
  }

  if (route?.query.widget) {
    widget.value = true;
  }

  if (route?.params.organizationUuid) {
    const payload = {
      organizationUuid: route?.params.organizationUuid as string,
    };

    if (payload) {
      await authStore.getHelpeeSignUpData(payload);

      if (authStore.helpeeSignUpData) {
        locale.value = authStore.helpeeSignUpData.language;
      }
    }
  }

  initialized.value = true;

  await computeContainerWidth();

  window.addEventListener("resize", computeContainerWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", computeContainerWidth);
});

// Methods
const campaignAvatars = (campaignAvatars: string[]) => {
  let avatars: {
    url: string;
    tooltip?: string;
  }[] = [];

  if (campaignAvatars?.length > 0) {
    avatars = campaignAvatars.map((helper) => ({
      url: helper,
    }));
  } else {
    avatars = [
      {
        url: `/img/avatars/avatar_${Math.floor(
          Math.random() * (3 - 1 + 1) + 1
        )}.png`,
      },
      {
        url: `/img/avatars/avatar_${Math.floor(
          Math.random() * (6 - 4 + 1) + 4
        )}.png`,
      },
      {
        url: `/img/avatars/avatar_${Math.floor(
          Math.random() * (9 - 7 + 1) + 7
        )}.png`,
      },
    ];
  }

  if (avatars?.length > 4) {
    return avatars.slice(0, 4);
  } else {
    return avatars;
  }
};

const changeView = async () => {
  campaignsView.value = true;

  await computeContainerWidth();

  if (!authStore.helpeePostSignUpData?.needPickUpCampaign) {
    router.push({
      name: "helpee-signup",
      params: {
        campaignUuid: authStore.helpeePostSignUpData?.campaignUuid,
      },
    });
  }
};

const computeContainerWidth = async () => {
  await nextTick();

  const container = root.value?.querySelector(".js-container") as HTMLElement;

  if (container) {
    containerWidth.value = container.offsetWidth || 0;
  }
};

const selectCampaign = (campaignUuid: string) => {
  // Track select campaign event
  AnalyticsHelper.trackEvent("user_choose_campaign");

  router.push({
    name: "helpee-signup",
    params: {
      campaignUuid: campaignUuid,
    },
  });
};
</script>

<template>
  <div ref="root" class="flex flex-col">
    <div
      v-if="initialized"
      class="scrollbar-hide flex-1 bg-background-default"
      :class="[
        widget
          ? 'relativ shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)]'
          : 'mx-auto w-full md:relative md:mt-20 md:h-[596px] md:w-[654px] md:rounded-[15px] md:shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)]',
        ,
        !widget && campaignsView
          ? 'overflow-y-scroll md:max-h-[596px]'
          : !widget
            ? 'max-h-[596px]'
            : undefined,
        {
          'h-full': !campaignsView,
        },
      ]"
    >
      <HelpeeSignUp v-if="!campaignsView" @success="changeView" />

      <div
        v-if="campaignsView"
        class="js-container"
        :class="[
          widget
            ? containerWidth < 600
              ? 'px-4 pb-16 pt-6'
              : 'px-10 pb-16 pt-6'
            : 'px-4 pb-16 pt-6 md:px-10 md:pb-6',
        ]"
      >
        <div
          v-if="
            authStore.helpeePostSignUpData?.needPickUpCampaign &&
            authStore.helpeePostSignUpData?.campaigns?.length != 0
          "
          class="mb-2 flex"
        >
          <BaseIcon
            color="#5F6C85"
            icon="chevron-left"
            @click="campaignsView = false"
          />

          <span
            class="ml-2 block pr-1 text-mgm-txt-xs font-medium text-foreground-emphasis"
            >{{ $t("generic.step") + " 1" }}</span
          >
          <span class="block text-mgm-txt-xs font-medium text-foreground-subtle"
            >/ 3</span
          >
        </div>

        <h2 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
          {{
            authStore.helpeePostSignUpData?.needPickUpCampaign &&
            authStore.helpeePostSignUpData?.campaigns?.length != 0
              ? $t("helpeeMultiCampaignSignUpView.organization")
              : $t("helpeeMultiCampaignSignUpView.headingEmpty")
          }}
        </h2>

        <div
          v-if="
            authStore.helpeePostSignUpData?.needPickUpCampaign &&
            authStore.helpeePostSignUpData?.campaigns?.length != 0
          "
          class="mt-4 grid gap-4 md:grid-cols-2"
          :class="{ 'grid-cols-2': containerWidth > 600 }"
        >
          <div
            v-for="campaign in authStore.helpeePostSignUpData?.campaigns"
            :key="campaign.uuid"
            class="w-full rounded-[5px] border border-border-subtle p-4 shadow-[0px_1px_2px_0px_#1C1F270D]"
            @click="selectCampaign(campaign.uuid)"
          >
            <div class="mb-4 flex items-center justify-between">
              <h3
                class="mr-4 truncate text-mgm-txt-md font-medium text-foreground-emphasis"
              >
                {{ campaign.title }}
              </h3>

              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33334 6H10.6667M10.6667 6L6.00001 1.33333M10.6667 6L6.00001 10.6667"
                  stroke="#5F6C85"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <BaseAvatar
              :group-avatars-urls="
                campaignAvatars(campaign.helpersProfilePicture)
              "
              type="group"
              size="sm"
            />

            <p class="mt-2 text-mgm-txt-xs font-normal text-foreground-default">
              {{
                campaign.helperNames?.length < 1
                  ? $t("helpeeMultiCampaignSignUpView.descriptionEmpty")
                  : campaign.helperNames?.join(", ") +
                    $t("helpeeMultiCampaignSignUpView.description")
              }}
            </p>
          </div>
        </div>
      </div>

      <TheFooter
        class="absolute bottom-0 left-0 right-0"
        :class="{ 'md:static': !widget && campaignsView }"
      />
    </div>

    <BaseLoader v-else class="min-h-screen" />
  </div>
</template>
