<script setup lang="ts">
// NPM
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

// Components
import { BaseAvatar, BaseIcon } from "@magma-app/magma-lapilli";

// Stores
import { useCommonStore } from "@/stores/common";
const commonStore = useCommonStore();

// Props
const props = defineProps({
  link: {
    type: String,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

// Data
const channels = [
  {
    icon: "/img/logos/mail.svg",
    name: "E-mail",
  },
  {
    icon: "/img/logos/facebook.svg",
    name: "Facebook",
  },
  {
    icon: "/img/logos/linkedin.svg",
    name: "LinkedIn",
  },
  {
    icon: "/img/logos/x.svg",
    name: "X",
  },
  {
    icon: "/img/logos/whatsapp.svg",
    name: "WhatsApp",
  },
];

// Reactive variables
const root = ref<HTMLInputElement>();

// Event listeners
const onCloseShareModal = async () => {
  commonStore.closeShareModal();
};

const onShareLink = (channel: string) => {
  let url = "";

  switch (channel) {
    case "E-mail":
      url = `mailto:?subject=Check this out&body=${encodeURIComponent(
        props.link
      )}`;
      break;

    case "Facebook":
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        props.link
      )}`;
      break;

    case "LinkedIn":
      url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        props.link
      )}`;
      break;

    case "X":
      url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        props.link
      )}`;
      break;

    case "WhatsApp":
      url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        props.link
      )}`;
      break;

    default:
      alert("Sharing via this platform is not supported");
      return;
  }

  window.open(url, "_blank");
};
</script>

<template>
  <TransitionRoot as="template" :show="open" data-cy="share">
    <Dialog
      :initial-focus="root"
      as="div"
      class="relative z-10"
      @close="onCloseShareModal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div ref="root" class="flex min-h-full items-center justify-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="mx-4 max-w-sm transform overflow-hidden rounded-[15px] bg-background-default px-4 pb-2 pt-4 shadow-xl transition-all md:mx-auto lg:w-80"
            >
              <div class="mb-5 flex justify-between px-2">
                <span
                  class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
                >
                  {{ $t("generic.shareModal") }}
                </span>

                <BaseIcon
                  icon="close"
                  color="#5F6C85"
                  hover-state
                  @click="onCloseShareModal"
                />
              </div>

              <button
                v-for="(channel, channelIndex) in channels"
                :key="channelIndex"
                class="mt-1 flex w-full cursor-pointer items-center p-2 hover:bg-background-subtle-hover"
                @click="onShareLink(channel.name)"
              >
                <BaseAvatar
                  :src="channel.icon"
                  class="mr-2"
                  size="xs"
                  shape="squared"
                />

                <span
                  class="text-mgm-txt-sm font-medium text-foreground-emphasis"
                >
                  {{ channel.name }}
                </span>
              </button>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
