<script setup lang="ts">
import { animate, spring } from 'motion';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

onMounted(() => {
	animate(
		'.block-start',
		{
			opacity: [0, 1],
			y: ['50px', 0],
		},
		{
			duration: 6,
			easing: spring(),
		}
	);
	animate(
		'.block-start img',
		{
			scale: [0, 1],
		},
		{
			duration: 6,
			easing: spring(),
			delay: 0.2,
		}
	);
	animate(
		'.block-start .line1',
		{
			scaleX: [0, 1],
		},
		{
			duration: 6,
			easing: spring(),
			delay: 0.3,
		}
	);
	animate(
		'.block-start .line2',
		{
			scaleX: [0, 1],
		},
		{
			duration: 6,
			easing: spring(),
			delay: 0.4,
		}
	);
});
</script>

<template>
	<div
		class="flex flex-col h-full flex-1 md:px-16 gap-0 md:gap-4 -mt-8 md:mt-0"
	>
		<div
			class="w-full aspect-square bg-[url('/img/illustration_helper_onboarding_background.svg')] bg-contain bg-no-repeat bg-center relative flex items-center justify-center"
		>
			<div
				class="block-start w-52 h-40 bg-white border border-border-subtle rounded-lg shadow-lg items-center justify-center flex flex-col"
			>
				<img src="/img/avatar_upload.png" class="mb-2" />
				<div
					class="w-20 h-3 rounded-full bg-gradient-to-r from-[#E2E8F0] to-[#F1F5F9] mb-2 line1"
				></div>
				<div
					class="w-32 h-3 rounded-full bg-gradient-to-r from-[#E2E8F0] to-[#F1F5F9] line2"
				></div>
			</div>
		</div>

		<h1
			class="text-mgm-dspl-xs md:text-mgm-dspl-sm text-foreground-emphasis text-center mb-10 md:mb-0"
		>
			{{ t('helperOnboarding.start.title') }}
		</h1>
	</div>
</template>
