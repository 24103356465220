<script setup lang="ts">
// NPM
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMq } from 'vue3-mq';

// Components
import { BaseBadge, BaseButton, BaseIcon } from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';
import type { IRelationMoment } from '@/types/IRelationMoment';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();

// Plugins
const mq = useMq();
const route = useRoute();
const { t } = useI18n({ useScope: 'global' });

// Props
const props = defineProps({
	relationMoment: {
		type: Object as PropType<IRelationMoment>,
		required: true,
	},
});

// Reactive variables
const displayText = ref(false);

// Computed
const relationMomentIcon = computed(() => {
	if (props.relationMoment.status === 'notStarted') {
		if (
			props.relationMoment.helpee?.needHelpeeValidation === false &&
			route?.name === 'helpee-relation-overview'
		) {
			return {
				name: 'clock',
				color: '#94A3B8',
				bgColor: '#F8FAFC',
			};
		} else {
			return {
				name: 'calendar',
				color: '#94A3B8',
				bgColor: '#F8FAFC',
			};
		}
	} else if (['newMoment', 'ongoing'].includes(props.relationMoment.status)) {
		return {
			name: 'clipboard',
			color: '#444CE7',
			bgColor: '#EEF4FF',
		};
	} else if (
		props.relationMoment.status === 'waitingHelperValidation' &&
		route?.name === 'helpee-relation-overview'
	) {
		return {
			name: 'clock',
			color: '#444CE7',
			bgColor: '#EEF4FF',
		};
	} else if (
		[
			'waitingValidation',
			'waitingHelpeeValidation',
			'waitingAdminValidation',
		].includes(props.relationMoment.status) &&
		route?.name === 'helper-relation-overview'
	) {
		return {
			name: 'clock',
			color: '#B25504',
			bgColor: '#FFFAEB',
		};
	} else if (props.relationMoment.status === 'skipped') {
		return {
			name: 'arrow-circle-broken-right',
			color: '#94A3B8',
			bgColor: '#F8FAFC',
		};
	} else if (
		['validated', 'validatedByAdmin'].includes(props.relationMoment.status)
	) {
		return {
			name: 'check-circle',
			color: '#03874C',
			bgColor: '#ECFDF3',
		};
	} else {
		return {
			name: 'calendar',
			color: '#94A3B8',
			bgColor: '#F8FAFC',
		};
	}
});

const relationMomentStatus = computed(() => {
	const { color, key } = getStatusDetails(props.relationMoment.status);

	return {
		color,
		text: key,
	};
});

// Event emitters
const emit = defineEmits(['click', 'display']);

// Event listeners
const onActionClick = (event: Event) => {
	emit('click', event);
};

const onDisplayDetails = (event: Event) => {
	emit('display', event);
};

// Event Listeners
const onContactAdmin = () => {
	commonStore.setAdminModal({
		relationMomentId: props.relationMoment.relationMomentId,
		open: true,
	});

	if (mq.smMinus) {
		document.body.classList.add('overflow-hidden');
	}

	if (route?.name === 'helper-relation-overview') {
		AnalyticsHelper.trackEvent('ask_support', {
			event_properties: 'relation_timeline',
			user_properties: 'helper',
		});
	}
};

const onMarkLastMomentAsDone = () => {
	commonStore.setManageRelationModal({
		open: true,
		type: 'helper',
		role: 'done-with-feedback',
		feedbackType: 'full',
		relationMomentId: props.relationMoment.relationMomentId,
		title: t('generic.doneModalTitle'),
		description: `${t('generic.feedbackModalDescription')}${relationsStore
			.relation?.helpee?.firstname}.`,
		helpeeName: relationsStore.relation?.relation?.helpee?.firstname,
	});

	AnalyticsHelper.trackEvent('validate_moment', {
		event_properties: 'timeline',
		user_properties: 'helper',
	});
};

const onDisplayText = () => {
	displayText.value = !displayText.value;
};

const onSendMessage = () => {
	commonStore.setTouchpointsModal({
		moment: props.relationMoment,
		name:
			relationsStore.relation?.relation?.helpee?.firstname ||
			relationsStore.relation?.relation?.helper?.firstname,
		open: true,
		touchpoints:
			relationsStore.relation?.relation?.helpee?.touchPoints ||
			relationsStore.relation?.relation?.helper?.touchPoints,
	});

	if (route?.name === 'helper-relation-overview') {
		AnalyticsHelper.trackEvent('send_message', {
			event_properties: 'timeline',
			user_properties: 'helper',
		});
	} else if (route?.name === 'helpee-relation-overview') {
		AnalyticsHelper.trackEvent('send_message', {
			event_properties: 'timeline',
			user_properties: 'helpee',
		});
	}
};

// Methods
const getStatusDetails = (status: string) => {
	const statusMap: {
		[key: string]: {
			color: string;
			key: string;
		};
	} = {
		waitingAdminValidation: {
			color: 'yellow',
			key: t('baseRelationMoment.status.waitingAdminValidation'),
		},

		waitingValidation: {
			color: 'yellow',
			key: t('baseRelationMoment.status.waitingHelpeeValidation'),
		},

		waitingHelpeeValidation: {
			color: 'yellow',
			key: t('baseRelationMoment.status.waitingHelpeeValidation'),
		},

		skipped: {
			color: 'grey',
			key: t('baseRelationMoment.monitoring.relationDetails.skippedByAdmin'),
		},

		validated: {
			color: 'green',
			key: t('baseRelationMoment.monitoring.relationDetails.validated'),
		},

		default: {
			color: 'blue',
			key: t('baseRelationMoment.monitoring.relationDetails.validatedByAdmin'),
		},
	};

	// Special case handling
	if (status === 'newMoment' && !props.relationMoment.helpee?.helpeeRating) {
		return { color: 'blue', key: t('baseRelationMoment.status.newMoment') };
	} else if (
		status !== 'waitingValidation' &&
		status !== 'waitingHelpeeValidation' &&
		status !== 'waitingAdminValidation' &&
		status !== 'validated' &&
		props.relationMoment.helpee?.isValidatedByHelpee &&
		props.relationMoment.helper?.needHelperValidation &&
		relationsStore.relation?.relation?.helpee?.firstname
	) {
		return {
			color: 'green',
			text: `${t('baseRelationMoment.status.validatedBy')}${
				relationsStore.relation?.relation?.helpee?.firstname
					.charAt(0)
					.toUpperCase() +
				relationsStore.relation?.relation?.helpee?.firstname.slice(1)
			}`,
		};
	}
	// End of special case handling
	else {
		return statusMap[status] || statusMap.default;
	}
};
</script>

<template>
	<div class="flex pr-4 md:pr-8">
		<div class="relative mr-4 flex flex-col items-center">
			<div
				:class="{
					'top-8': relationMoment.isFirst,
					'top-0': !relationMoment.isFirst,
					'h-6': relationMoment.isLast,
					'bottom-0': !relationMoment.isLast,
				}"
				class="absolute w-px rounded-b-sm bg-border-subtle"
			></div>

			<BaseIcon
				:class="[
					'relative z-10',
					{
						'mt-6': relationMoment.points || relationMoment.daysLeft,
						'mt-1.5': !relationMoment.points && !relationMoment.daysLeft,
					},
				]"
				:icon="relationMomentIcon.name"
				:color="relationMomentIcon.color"
				:bg-color="relationMomentIcon.bgColor"
			/>
		</div>

		<div
			data-cy="helper-relation-moment"
			class="w-full pb-10"
			:class="{ 'max-w-md': $route?.name === 'helpee-relation-overview' }"
		>
			<div class="mb-2 flex items-center justify-between">
				<p
					v-if="$route?.name === 'helper-relation-overview'"
					:class="[
						relationsStore.relation?.isReported ||
						[
							'notStarted',
							'validated',
							'validatedByAdmin',
							'cancelled',
							'skipped',
						].includes(relationMoment.status)
							? 'text-foreground-subtle'
							: 'text-foreground-default',
					]"
					class="flex items-center text-mgm-txt-xs font-normal"
				>
					<span v-if="relationMoment.points">
						{{ `${relationMoment.points}pts` }}
					</span>

					<span
						v-if="
							relationMoment.daysLeft &&
							!['waitingAdminValidation', 'waitingHelpeeValidation'].includes(
								relationMoment.status
							)
						"
						class="flex items-center"
					>
						<svg
							class="mx-2"
							width="5"
							height="5"
							viewBox="0 0 5 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2.375 4.39773C2.03125 4.39773 1.71733 4.31392 1.43324 4.14631C1.14915 3.97585 0.921875 3.74858 0.75142 3.46449C0.583807 3.1804 0.5 2.86648 0.5 2.52273C0.5 2.17614 0.583807 1.86222 0.75142 1.58097C0.921875 1.29687 1.14915 1.07102 1.43324 0.903409C1.71733 0.732954 2.03125 0.647727 2.375 0.647727C2.72159 0.647727 3.03551 0.732954 3.31676 0.903409C3.60085 1.07102 3.8267 1.29687 3.99432 1.58097C4.16477 1.86222 4.25 2.17614 4.25 2.52273C4.25 2.86648 4.16477 3.1804 3.99432 3.46449C3.8267 3.74858 3.60085 3.97585 3.31676 4.14631C3.03551 4.31392 2.72159 4.39773 2.375 4.39773Z"
								fill="#5F6C85"
							/>
						</svg>

						<span v-if="relationMoment.daysLeft"
							>{{
								relationMoment.daysLeft +
								(relationMoment.daysLeft > 1
									? $t('generic.daysLeft')
									: $t('generic.dayLeft'))
							}}
						</span>
					</span>
				</p>

				<BaseBadge
					v-if="
						!relationsStore.relation?.isReported &&
						([
							'newMoment',
							'waitingValidation',
							'waitingHelpeeValidation',
							'waitingAdminValidation',
							'skipped',
							'validated',
							'validatedByAdmin',
						].includes(relationMoment.status) ||
							(relationMoment.helpee?.helpeeRating &&
								![
									'waitingValidation',
									'waitingHelpeeValidation',
									'waitingAdminValidation',
									'validated',
								].includes(relationMoment.status) &&
								relationMoment.helper?.needHelperValidation &&
								relationsStore.relation?.relation?.helpee?.firstname)) &&
						$route?.name === 'helper-relation-overview'
					"
					class="ml-2"
					size="xs"
					:color="relationMomentStatus.color"
					:text="relationMomentStatus.text"
				/>
			</div>

			<p
				:class="[
					[
						'notStarted',
						'validated',
						'validatedByAdmin',
						'cancelled',
						'skipped',
					].includes(relationMoment.status)
						? 'text-foreground-default'
						: 'text-foreground-emphasis',
				]"
				class="text-mgm-txt-sm font-medium"
			>
				{{ relationMoment.name }}
			</p>

			<BaseBadge
				v-if="
					!relationsStore.relation?.isReported &&
					relationMoment.status != 'validated' &&
					relationMoment.helpee?.needHelpeeValidation === false &&
					relationMoment.helpee?.isValidated === false &&
					$route?.name === 'helpee-relation-overview'
				"
				class="mt-2"
				color="grey"
				:text="$t('baseRelationMoment.status.noValidation')"
			/>

			<p
				v-if="
					$route?.name === 'helper-relation-overview' &&
					relationMoment.description
				"
				class="mt-1 text-mgm-txt-sm font-normal"
				:class="{
					'text-foreground-default':
						!relationsStore.relation?.isReported &&
						relationMoment.isCurrentMoment &&
						relationMoment.status !== 'validatedByAdmin',
					'text-foreground-subtle':
						relationsStore.relation?.isReported ||
						!relationMoment.isCurrentMoment ||
						relationMoment.status === 'validatedByAdmin',
				}"
			>
				{{
					`${relationMoment.description
						.replace(/<\/?[^>]+(>|$)/g, '')
						.slice(0, 116)}...`
				}}
				<span
					data-cy="btn-more"
					class="ml-1 cursor-pointer text-foreground-brand-default"
					@click="onDisplayDetails"
				>
					{{ $t('generic.readMore') }}
				</span>
			</p>

			<div
				v-if="
					relationMoment.helper?.helperRating ||
					relationMoment.helpee?.helpeeRating
				"
				class="mt-2 flex items-center rounded-[5px] bg-background-subtle py-1.5 pl-2.5 pr-2 text-mgm-txt-sm text-foreground-default"
			>
				<p
					class="max-w-[200px] pr-4 md:max-w-sm"
					:class="{
						'truncate md:overflow-auto md:whitespace-normal': !displayText,
					}"
				>
					<span class="text-foreground-brand-default">
						{{
							($route?.name === 'helpee-relation-overview'
								? relationsStore.relation?.relation?.helper?.firstname
								: relationsStore.relation?.relation?.helpee?.firstname) + ': '
						}}
					</span>

					<span>
						{{
							(relationMoment.helper?.helperRating ||
								relationMoment.helpee?.helpeeRating) == 1
								? '😒'
								: '🤩'
						}}
					</span>

					<span
						v-if="
							relationMoment.helper?.helperComment ||
							relationMoment.helpee?.helpeeComment
						"
					>
						{{
							', ' +
							($route?.name === 'helpee-relation-overview'
								? relationMoment.helper?.helperComment
								: relationMoment.helpee?.helpeeComment)
						}}
					</span>
				</p>

				<svg
					class="md:hidden"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					@click="onDisplayText"
				>
					<path
						d="M4.66666 10L7.99999 13.3334L11.3333 10M4.66666 6.00002L7.99999 2.66669L11.3333 6.00002"
						stroke="#5F6C85"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</div>

			<div
				v-if="
					!relationsStore.relation?.isReported &&
					![
						'notStarted',
						'validated',
						'cancelled',
						'skipped',
						'validatedByAdmin',
					].includes(relationMoment.status)
				"
				class="md:flex"
			>
				<BaseButton
					v-if="
						relationMoment.isCurrentMoment &&
						!relationMoment.isLast &&
						!relationMoment.helpee?.helpeeRating
					"
					data-cy="btn-message"
					class="mt-4 w-full md:mr-2"
					:text="$t('generic.sendMessage')"
					size="md"
					state="scdr"
					@click="onSendMessage"
				/>

				<BaseButton
					v-if="
						relationMoment.helpee?.needHelpeeValidation ||
						(relationMoment.helper?.needHelperValidation &&
							!relationMoment.isLast)
					"
					class="mt-4 w-full"
					:text="$t('generic.validate')"
					size="md"
					data-cy="btn-validate-time"
					@click="onActionClick"
				/>

				<BaseButton
					v-if="
						relationMoment.helper?.needHelperValidation && relationMoment.isLast
					"
					class="mt-4 w-full"
					:text="$t('generic.validate')"
					size="md"
					@click="onMarkLastMomentAsDone"
				/>

				<BaseButton
					v-if="relationMoment.helper?.canContactAdmin"
					:text="$t('generic.adminContact')"
					class="mt-4 w-full"
					size="md"
					state="scdr"
					@click="onContactAdmin"
				/>
			</div>
		</div>
	</div>
</template>
