<script setup lang="ts">
// NPM
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { createI18nMessage, email, required, url } from '@vuelidate/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';

// Components
import {
	BaseAvatar,
	BaseBadge,
	BaseButton,
	BaseIcon,
	BaseLoader,
	FieldCheckbox,
	FieldInput,
	FieldPhoneNumber,
	FieldSelect,
} from '@magma-app/magma-lapilli';
import TheFooter from '@/components/shell/TheFooter.vue';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Composables & Stores
import { useConvertHexToRgb } from '@/composables/useConvertHexToRgb';
import { useAuthStore } from '@/stores/auth';
const { hexColor, rgbColor } = useConvertHexToRgb();
const authStore = useAuthStore();

// Plugins
const route = useRoute();
const { t, locale } = useI18n({ useScope: 'global' });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const initialized = ref(false);
//// Email already exists
const disableBtn = ref(false);
const duplicateHelpeeMsg = ref<string | undefined>(undefined);
//// Views
const introduction = ref(true);
const campaigns = ref(false);
const form = ref(false);
const redirection = ref(false);
//// Form
const formData = reactive({
	selectedCampaign: null as null | string,
	signUp: {
		firstname: '',
		lastname: '',
		email: '',
		phoneNumber: '',
		criteria: null as null | string,
		chosenTouchpoints: [] as number[],
		touchpoints: {
			line: '',
			phone: '',
			whatsapp: '',
			calendly: '',
			discord: '',
			linkedin: '',
			weChat: '',
			youtube: '',
			tiktok: '',
			telegram: '',
			snapchat: '',
			'X (Twitter)': '',
			messenger: '',
			instagram: '',
			facebook: '',
		} as { [key: string]: string },
		consent: undefined,
	},
});

// Form validation
const formRules = {
	phone: 'phoneNumber',
	line: 'phoneNumber',
	whatsapp: 'phoneNumber',
	calendly: 'pseudo',
	discord: 'pseudo',
	linkedin: 'pseudo',
	weChat: 'pseudo',
	youtube: 'pseudo',
	tiktok: 'pseudo',
	telegram: 'pseudo',
	snapchat: 'pseudo',
	'X (Twitter)': 'pseudo',
	messenger: 'pseudo',
	instagram: 'pseudo',
	facebook: 'pseudo',
} as { [key: string]: string };

const finalRules = Object.keys(formRules).reduce((acc: any, key) => {
	if (formRules[key] === 'phoneNumber') {
		acc[key] = {
			validPhoneNumber: withI18nMessage(
				(value: string) => {
					if (value != '') {
						return isValidPhoneNumber(value);
					} else {
						return true;
					}
				},
				{
					messagePath: () => t('generic.phoneNumber') + t('validation.valid'),
				}
			),
		};
	} else if (formRules[key] === 'pseudo') {
		acc[key] = {
			url: withI18nMessage(url, {
				messagePath: () => t('validation.urlValidation'),
			}),
		};
	}

	return acc;
}, {});

const v$ = useVuelidate(
	{
		selectedCampaign: {
			required: withI18nMessage(required, {
				messagePath: () => t('validation.value'),
			}),
		},
		signUp: {
			firstname: {
				required: withI18nMessage(required, {
					messagePath: () => t('validation.value'),
				}),
			},
			lastname: {
				required: withI18nMessage(required, {
					messagePath: () => t('validation.value'),
				}),
			},
			email: {
				email: withI18nMessage(email, {
					messagePath: () => t('validation.invalidEmail'),
				}),
				required: withI18nMessage(required, {
					messagePath: () => t('validation.value'),
				}),
			},
			phoneNumber: {
				required: withI18nMessage(required, {
					messagePath: () => t('validation.value'),
				}),
				validPhoneNumber: withI18nMessage(
					(value: string) => isValidPhoneNumber(value),
					{
						messagePath: () => t('generic.phoneNumber') + t('validation.valid'),
					}
				),
			},
			criteria: {
				required: withI18nMessage(required, {
					messagePath: () => t('validation.value'),
				}),
			},
			touchpoints: {
				mail: {
					email: withI18nMessage(email, {
						messagePath: () => t('validation.invalidEmail'),
					}),
				},
				...finalRules,
			},
			consent: {
				required: withI18nMessage((value: boolean) => value === true, {
					messagePath: () => t('validation.value'),
				}),
			},
		},
	},
	formData
);

// Computed
const campaignsOptions = computed(() => {
	if (authStore.referredSharingSignUpData?.campaigns) {
		return authStore.referredSharingSignUpData?.campaigns.map(
			(campaignOption: { name: string; uuid: string }) => ({
				label: campaignOption.name,
				value: campaignOption.uuid,
			})
		);
	} else {
		return [];
	}
});

const currentCampaign = computed(() => {
	if (
		authStore.referredSharingSignUpData?.needPickUpCampaign &&
		formData.selectedCampaign
	) {
		return authStore.referredSharingSignUpData.campaigns.find(
			(currentCampaign) => currentCampaign.uuid === formData.selectedCampaign
		);
	} else if (!authStore.referredSharingSignUpData?.needPickUpCampaign) {
		return authStore.referredSharingSignUpData?.campaigns[0];
	} else {
		return undefined;
	}
});

const criteriaOptions = computed(() => {
	if (currentCampaign.value?.strongCriteria.options) {
		return currentCampaign.value.strongCriteria.options.map(
			(criteriaOption: { key: string; value: string }) => ({
				label: criteriaOption.value,
				value: criteriaOption.key,
			})
		);
	} else {
		return [];
	}
});

const desiredTouchpoints = computed(() => {
	return availableTouchpoints.value.filter((touchpoint) => {
		return formData.signUp.chosenTouchpoints.includes(touchpoint.value);
	});
});

const availableTouchpoints = computed(() => {
	return (
		currentCampaign.value?.touchPoints?.map(
			(availableTouchpoints: {
				icon: string;
				name: string;
				id: number;
				type: string;
			}) => ({
				label: availableTouchpoints.name,
				icon: availableTouchpoints.icon,
				value: availableTouchpoints.id,
				type: availableTouchpoints.type,
			})
		) || []
	);
});

const watchFields = computed(() => {
	return (
		currentCampaign.value?.touchPoints?.map((touchPoint) => touchPoint.name) ||
		[]
	);
});

// Lifecycle hooks
onMounted(async () => {
	if (route?.params.shareId) {
		await authStore.getReferredSharingSignUpData(
			route?.params.shareId as string
		);
	}

	if (authStore.referredSharingSignUpData?.organization.languages) {
		locale.value =
			authStore.referredSharingSignUpData?.organization.languages[0];
	}

	if (authStore.referredSharingSignUpData?.organization.primaryColor) {
		hexColor.value =
			authStore.referredSharingSignUpData.organization.primaryColor;
	}

	initialized.value = true;
});

onUnmounted(async () => {
	formData.selectedCampaign = null;
	v$.value.selectedCampaign.$reset();
	formData.signUp.criteria = null;
	v$.value.signUp.criteria.$reset();
	formData.signUp.chosenTouchpoints = [];
	v$.value.signUp.criteria.$reset();
});

// Watchers
watch(
	() => formData.signUp.firstname,
	async () => await v$.value.signUp.firstname.$validate()
);

watch(
	() => formData.signUp.lastname,
	async () => await v$.value.signUp.lastname.$validate()
);

watch(
	() => formData.signUp.email,
	async () => {
		await v$.value.signUp.email.$validate();

		if (disableBtn.value) {
			duplicateHelpeeMsg.value = undefined;
			disableBtn.value = false;
		}
	}
);

watch(
	() => formData.signUp.phoneNumber,
	async () => await v$.value.signUp.phoneNumber.$validate()
);

for (const field of watchFields.value) {
	watch(
		() => formData.signUp.touchpoints[field],
		async () => await v$.value.signUp.touchpoints[field].$validate()
	);
}

// Event listeners
const onCloseForm = () => {
	introduction.value = true;
	campaigns.value = false;
	form.value = false;

	formData.selectedCampaign = null;
	v$.value.selectedCampaign.$reset();
	formData.signUp.criteria = null;
	v$.value.signUp.criteria.$reset();
	formData.signUp.chosenTouchpoints = [];
	v$.value.signUp.criteria.$reset();
};

const onGetIntro = () => {
	introduction.value = false;

	if (authStore.referredSharingSignUpData?.needPickUpCampaign) {
		campaigns.value = true;
	} else {
		form.value = true;
	}

	// Tracking asking for an intro
	AnalyticsHelper.trackEvent('ask_introduction', {
		user_properties: 'helpee',
	});
};

const onHelpeeSignup = async () => {
	try {
		v$.value.signUp.$touch();

		if (
			(await v$.value.signUp.$validate()) &&
			authStore.referredSharingSignUpData?.helper.id &&
			currentCampaign.value
		) {
			// Format criteria and touchpoints
			const objectKey =
				typeof formData.signUp.criteria === 'object'
					? 'answerKeys'
					: 'answerKey';

			const helpeeTouchPoints = Object.keys(formData.signUp.touchpoints)
				.map((touchPointLabel) => {
					const touchPointId = desiredTouchpoints.value.find(
						(touchpoint) => touchpoint.label === touchPointLabel
					)?.value;

					const touchPointValue = formData.signUp.touchpoints[touchPointLabel];

					if (touchPointId && touchPointValue) {
						return {
							touchPointId: `${touchPointId}`,
							value: touchPointValue,
						};
					} else {
						return null;
					}
				})
				.filter((touchPoint) => touchPoint != null) as {
				touchPointId: string;
				value: string;
			}[];

			const payload = {
				campaignUuid: currentCampaign.value.uuid as string,
				helperId: authStore.referredSharingSignUpData?.helper.id,
				shareId: route?.params.shareId as string,
				firstname: formData.signUp.firstname,
				lastname: formData.signUp.lastname,
				email: formData.signUp.email,
				phoneNumber: formData.signUp.phoneNumber,
				strongUserCriteria: {
					criteriaId: currentCampaign.value.strongCriteria.id,
					[objectKey]: formData.signUp.criteria,
				},
				helpeeTouchPoints,
			};

			disableBtn.value = true;

			await authStore.sendReferredSharingSignUpData(payload);

			disableBtn.value = false;
			campaigns.value = false;
			form.value = false;
			redirection.value = true;

			// Tracking sending intro request
			AnalyticsHelper.trackEvent('send_intro_request', {
				user_properties: 'helpee',
			});

			setTimeout(() => {
				if (authStore.referredSharingRelationUrl) {
					location.href = authStore.referredSharingRelationUrl;
				}
			}, 5000);
		}
	} catch (error: any) {
		if (error.response.data.message.includes('user already exist')) {
			disableBtn.value = true;
			duplicateHelpeeMsg.value = t('generic.duplicateHelpee');
		} else {
			console.error(error);
		}
	}
};

const onSelectCampaign = async () => {
	form.value = true;

	formData.signUp.criteria = null;
	v$.value.signUp.criteria.$reset();
	formData.signUp.chosenTouchpoints = [];
	v$.value.signUp.criteria.$reset();
};
</script>

<template>
	<div
		class="h-full bg-background-subtle-hover px-4 py-6 shadow-[0px_4px_8px_-2px_rgba(28,31,39,0.01),0px_2px_4px_-2px_rgba(28,31,39,0.06)] sm:px-8 sm:py-10"
	>
		<template v-if="initialized">
			<div
				class="mx-auto flex w-full max-w-xl rounded-2xl bg-background-default p-5 md:px-10"
			>
				<BaseAvatar
					v-if="authStore.referredSharingSignUpData?.organization.logoUrl"
					:src="authStore.referredSharingSignUpData.organization.logoUrl"
					:alt="`${$t('generic.profilePicture')} ${
						authStore.referredSharingSignUpData.organization.name
					}`"
					:fallback="
						authStore.referredSharingSignUpData.organization.name.charAt(0)
					"
					size="md"
				/>

				<h1 class="ml-4">
					<span
						class="block text-mgm-txt-sm font-normal text-foreground-default"
					>
						{{ $t('helpeeSharingView.certified') }}
					</span>

					<span
						class="block text-mgm-txt-md font-medium text-foreground-emphasis"
					>
						{{ authStore.referredSharingSignUpData?.organization.name }}
					</span>
				</h1>
			</div>

			<div
				class="relative mx-auto my-4 w-full max-w-xl rounded-2xl bg-background-default pb-5 shadow-[0px_4px_8px_-2px_rgba(28,31,39,0.01),0px_2px_4px_-2px_rgba(28,31,39,0.06)] md:pb-10"
			>
				<div v-if="introduction" class="px-5 pt-12 md:px-10">
					<div
						class="absolute left-0 right-0 top-0 h-[101px] rounded-t-2xl"
						:style="{
							background: rgbColor
								? `linear-gradient(180deg, rgba(${rgbColor}, 0.2) 0%, rgba(224, 234, 255, 0) 79.7%)`
								: `linear-gradient(180deg, #e0eaff 0%, rgba(224, 234, 255, 0) 79.7%)`,
						}"
					></div>

					<BaseAvatar
						v-if="authStore.referredSharingSignUpData?.helper"
						:src="authStore.referredSharingSignUpData.helper.profilePictureUrl"
						:alt="`${$t('generic.profilePicture')} ${
							authStore.referredSharingSignUpData.helper.firstname
						} ${authStore.referredSharingSignUpData.helper.lastname}`"
						:fallback="
							authStore.referredSharingSignUpData.helper.firstname.charAt(0) +
							authStore.referredSharingSignUpData.helper.lastname.charAt(0)
						"
						size="xl"
					/>

					<h2
						class="mt-5 text-mgm-txt-xl font-semibold text-foreground-emphasis"
					>
						{{
							`${authStore.referredSharingSignUpData?.helper.firstname} ${authStore.referredSharingSignUpData?.helper.lastname}`
						}}
					</h2>

					<p class="mt-1 text-mgm-txt-sm font-normal text-foreground-default">
						{{
							`${$t('helpeeSharingView.description.one')} ${
								authStore.referredSharingSignUpData?.organization.name
							} ${$t('helpeeSharingView.description.two')}`
						}}
					</p>

					<BaseButton
						class="mt-5 w-full"
						:custom-color="
							authStore.referredSharingSignUpData?.organization.primaryColor
						"
						:custom-rgb-color="rgbColor"
						:text="`${$t('helpeeSharingView.intro')} ${
							authStore.referredSharingSignUpData?.helper.firstname
						}`"
						@click="onGetIntro"
					/>
				</div>

				<div v-else-if="campaigns || form" class="pt-4">
					<div
						class="relative flex border-b border-border-subtle px-5 pb-4 md:px-10"
					>
						<BaseAvatar
							v-if="authStore.referredSharingSignUpData?.helper"
							:src="
								authStore.referredSharingSignUpData.helper.profilePictureUrl
							"
							:alt="`${$t('generic.profilePicture')} ${
								authStore.referredSharingSignUpData.helper.firstname
							} ${authStore.referredSharingSignUpData.helper.lastname}`"
							:fallback="
								authStore.referredSharingSignUpData.helper.firstname.charAt(0) +
								authStore.referredSharingSignUpData.helper.lastname.charAt(0)
							"
							size="sm"
						/>

						<h2
							class="ml-3 text-mgm-txt-sm font-medium text-foreground-emphasis"
						>
							{{
								`${authStore.referredSharingSignUpData?.helper.firstname} ${authStore.referredSharingSignUpData?.helper.lastname}`
							}}
						</h2>

						<button
							class="absolute right-5 top-0.5 cursor-pointer md:right-10"
							@click="onCloseForm"
						>
							<BaseIcon color="#5F6C85" icon="close" />
						</button>
					</div>

					<div class="px-5 pt-5 md:px-10">
						<h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
							{{ $t('helpeeSharingView.intro') }}
						</h3>

						<form @submit.prevent="onHelpeeSignup">
							<FieldSelect
								v-if="campaigns"
								id="campaigns"
								v-model="formData.selectedCampaign"
								class="mt-4"
								:custom-color="
									authStore.referredSharingSignUpData?.organization.primaryColor
								"
								:custom-rgb-color="rgbColor"
								:label="$t('helpeeSharingView.campaign')"
								:options="campaignsOptions"
								:placeholder="$t('generic.placeholder.singleSelect')"
								:validation="v$.selectedCampaign"
								required
								@change="onSelectCampaign"
							/>

							<template v-if="form">
								<FieldInput
									id="firstname"
									v-model="formData.signUp.firstname"
									class="mt-4"
									:locale="$i18n.locale"
									:label="$t('generic.firstNameLabel')"
									placeholder="John"
									required
									:validation="v$.signUp.firstname"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
								/>

								<FieldInput
									id="lastname"
									v-model="formData.signUp.lastname"
									class="mt-4"
									:locale="$i18n.locale"
									:label="$t('generic.lastNameLabel')"
									placeholder="Doe"
									required
									:validation="v$.signUp.lastname"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
								/>

								<FieldInput
									id="email"
									v-model="formData.signUp.email"
									class="mt-4"
									role="email"
									type="email"
									:locale="$i18n.locale"
									label="Email"
									:placeholder="`${$t('generic.example')}@email.com`"
									autocomplete="email"
									required
									:validation="v$.signUp.email"
									:error-message="duplicateHelpeeMsg"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
									icon="mail"
								/>

								<FieldPhoneNumber
									id="phone"
									v-model="formData.signUp.phoneNumber"
									class="mt-4"
									:locale="$i18n.locale"
									:label="$t('generic.phone')"
									required
									:validation="v$.signUp.phoneNumber"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
								/>

								<FieldSelect
									v-if="currentCampaign?.strongCriteria.options"
									:id="currentCampaign?.strongCriteria.id"
									v-model="formData.signUp.criteria"
									class="mt-4"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
									:label="currentCampaign?.strongCriteria.labelHelpee"
									:multiple="
										currentCampaign?.strongCriteria.type === 'multiSelect'
									"
									:options="criteriaOptions"
									:placeholder="
										currentCampaign?.strongCriteria.type === 'multiSelect'
											? $t('generic.placeholder.multiSelect')
											: $t('generic.placeholder.singleSelect')
									"
									:validation="v$.signUp.criteria"
									required
								/>

								<FieldSelect
									id="touchpoints"
									v-model="formData.signUp.chosenTouchpoints"
									class="mt-4"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
									:label="$t('helpeeSharingView.contact')"
									:options="availableTouchpoints"
									:placeholder="$t('generic.placeholder.multiSelect')"
									multiple
								/>

								<template
									v-for="desiredTouchpoint of desiredTouchpoints"
									:key="desiredTouchpoint.value"
								>
									<FieldPhoneNumber
										v-if="desiredTouchpoint.type === 'phoneNumber'"
										:id="desiredTouchpoint.value"
										v-model="
											formData.signUp.touchpoints[desiredTouchpoint.label]
										"
										class="mt-4"
										:locale="$i18n.locale"
										placeholder="06 06 06 06 06"
										:validation="v$.signUp.touchpoints[desiredTouchpoint.label]"
										:custom-color="
											authStore.referredSharingSignUpData?.organization
												.primaryColor
										"
										:custom-rgb-color="rgbColor"
									/>

									<FieldInput
										v-else
										:id="desiredTouchpoint.value"
										v-model="
											formData.signUp.touchpoints[desiredTouchpoint.label]
										"
										class="mt-4"
										:role="
											desiredTouchpoint.type === 'email' ? 'email' : 'default'
										"
										:type="desiredTouchpoint.type === 'email' ? 'email' : 'url'"
										:locale="$i18n.locale"
										:placeholder="
											desiredTouchpoint.type === 'email'
												? `${$t('generic.example')}@email.com`
												: `https://www.${$t('generic.example')}.com/${$t(
														'generic.example'
													)}`
										"
										:touchpoint="desiredTouchpoint.icon"
										:validation="v$.signUp.touchpoints[desiredTouchpoint.label]"
										:custom-color="
											authStore.referredSharingSignUpData?.organization
												.primaryColor
										"
										:custom-rgb-color="rgbColor"
										:custom-icon="desiredTouchpoint.icon"
									/>
								</template>

								<FieldCheckbox
									id="consent"
									v-model="formData.signUp.consent"
									class="mt-8"
									:custom-color="
										authStore.referredSharingSignUpData?.organization
											.primaryColor
									"
									:custom-rgb-color="rgbColor"
									:validation="v$.signUp.consent"
									required
								>
									<span class="text-mgm-txt-sm">
										{{ $t('generic.agree') }}

										<a
											class="font-medium text-foreground-brand-default"
											href="https://www.magma.app/legals/helpers-terms-of-use"
											target="_blank"
										>
											{{ $t('generic.termsAndPrivacy.part2') }}
										</a>

										{{ $t('generic.termsAndPrivacy.part3') }}

										<a
											class="font-medium text-foreground-brand-default"
											href="https://www.magma.app/legals/privacy-policy"
											target="_blank"
										>
											{{ $t('generic.termsAndPrivacy.part4') }}
										</a>
									</span>
								</FieldCheckbox>
							</template>

							<BaseButton
								class="mt-8 w-full"
								:custom-color="
									authStore.referredSharingSignUpData?.organization.primaryColor
								"
								:custom-rgb-color="rgbColor"
								:disabled="v$.signUp.$invalid || v$.signUp.$error || disableBtn"
								:text="$t('helpeeSharingView.send')"
								size="md"
								type="submit"
							/>
						</form>
					</div>
				</div>

				<div v-else-if="redirection" class="px-5 pt-10 md:px-10">
					<svg
						class="mx-auto"
						width="56"
						height="56"
						viewBox="0 0 56 56"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
						<path
							d="M36 22L25 33L20 28"
							stroke="#03874C"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<rect
							x="4"
							y="4"
							width="48"
							height="48"
							rx="24"
							stroke="#ECFDF3"
							stroke-width="8"
						/>
					</svg>

					<h2
						class="mx-auto mt-4 text-center text-mgm-txt-lg font-medium text-foreground-emphasis"
					>
						{{
							`${$t('generic.thanks')} ${formData.signUp.firstname}${
								$i18n.locale === 'fr' ? ' ' : ''
							}!`
						}}
					</h2>

					<p
						class="mx-auto mt-2 text-center text-mgm-txt-sm font-normal text-foreground-default"
					>
						{{
							`${$t('helpeeSharingView.redirection')} ${
								authStore.referredSharingSignUpData?.organization.name
							}.`
						}}
					</p>

					<div class="mt-[42px] flex items-center pb-1">
						<BaseAvatar
							v-if="authStore.referredSharingSignUpData?.helper"
							class="h-8 w-8"
							:src="
								authStore.referredSharingSignUpData.helper.profilePictureUrl
							"
							:alt="`${$t('generic.profilePicture')} ${
								authStore.referredSharingSignUpData.helper.firstname
							} ${authStore.referredSharingSignUpData.helper.lastname}`"
							:fallback="
								authStore.referredSharingSignUpData.helper.firstname.charAt(0) +
								authStore.referredSharingSignUpData.helper.lastname.charAt(0)
							"
						/>

						<p class="ml-2">
							<span
								class="block text-mgm-txt-sm font-medium text-foreground-emphasis"
							>
								{{
									`${authStore.referredSharingSignUpData?.helper.firstname} ${authStore.referredSharingSignUpData?.helper.lastname}`
								}}
							</span>

							<span
								class="block text-mgm-txt-xs font-normal text-foreground-default"
							>
								{{
									`Helper ${authStore.referredSharingSignUpData?.organization.name}`
								}}
							</span>
						</p>
					</div>
				</div>
			</div>

			<div
				v-if="introduction"
				class="mx-auto w-full max-w-xl rounded-2xl bg-background-default px-5 py-5 shadow-[0px_4px_8px_-2px_rgba(28,31,39,0.01),0px_2px_4px_-2px_rgba(28,31,39,0.06)] md:px-10 md:py-10"
			>
				<BaseBadge
					:style="{
						backgroundColor: `rgba(${rgbColor}, 0.05)`,
						color:
							authStore.referredSharingSignUpData?.organization.primaryColor,
					}"
					:text="$t('helpeeSharingView.touchpoints')"
					size="md"
				/>

				<div class="mt-[18px] flex items-center">
					<BaseAvatar
						v-for="(touchpoint, touchpointIndex) in authStore
							.referredSharingSignUpData?.helper.touchPoints"
						:key="touchpointIndex"
						class="mr-4 h-8 w-8 cursor-pointer"
						:src="touchpoint.icon"
						:alt="`${$t('generic.profilePicture')} ${touchpoint.name}`"
						:fallback="touchpoint.name.charAt(0)"
						shape="squared"
						@click="onGetIntro"
					/>
				</div>
			</div>

			<TheFooter />
		</template>

		<BaseLoader v-else class="min-h-screen" />
	</div>
</template>
