<script setup lang="ts">
// NPM
import { RouterLink } from "vue-router";

// Components
import { BaseIcon } from "@magma-app/magma-lapilli";

// Props
defineProps({
  icon: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  exactActiveClass: {
    type: Boolean,
    default: false,
  },
  sidebar: {
    type: Boolean,
    default: false,
  },
  to: {
    type: Object,
    required: true,
  },
});

// Event emitters
const emit = defineEmits(["click"]);

// Event listeners
const onClick = () => {
  emit("click");
};
</script>

<template>
  <div @click="onClick">
    <RouterLink
      v-if="exactActiveClass"
      class="group flex items-center font-medium"
      :class="[
        sidebar
          ? 'rounded-full hover:bg-background-default 2xl:pl-2'
          : 'px-4 py-3 text-mgm-txt-sm text-foreground-emphasis hover:bg-background-subtle',
      ]"
      :to="to"
      :active-class="
        sidebar
          ? 'bg-background-default rounded-full 2xl:rounded-[40px]'
          : 'bg-background-subtle'
      "
    >
      <BaseIcon
        v-if="icon"
        :class="[sidebar ? 'h-10 w-10' : 'mr-2']"
        :icon="icon"
      />

      <span class="truncate" :class="{ 'hidden 2xl:inline-block': sidebar }">
        {{ name }}
      </span>
    </RouterLink>

    <RouterLink
      v-else
      class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-foreground-emphasis hover:bg-background-subtle-hover hover:text-foreground-emphasis"
      :to="to"
      exact-active-class="bg-background-subtle-hover rounded-md text-foreground-brand-emphasis"
    >
      <BaseIcon v-if="icon" class="mr-2" :icon="icon" />

      <span class="truncate">
        {{ name }}
      </span>
    </RouterLink>
  </div>
</template>
