<script setup lang="ts">
// NPM
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

// Components
import {
  BaseAvatar,
  BaseBadge,
  BaseButton,
  BaseIcon,
} from "@magma-app/magma-lapilli";

// Types
import type { PropType } from "vue";
import type { IChallenge } from "@/types/IChallenge";
import type { IReward } from "@/types/IReward";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useChallengesStore } from "@/stores/challenges";
import { useCommonStore } from "@/stores/common";
import { useUserStore } from "@/stores/user";
const challengesStore = useChallengesStore();
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: "global" });

// Props
const props = defineProps({
  challenge: {
    type: Boolean,
    required: false,
  },
  completed: {
    type: Boolean,
    required: false,
  },
  item: {
    type: Object as PropType<{
      description: string;
      id?: number | undefined;
      name: string;
      pictureUrl: string;
      points: number;
      retrievalInstruction?: string;
      status?: string;
      steps?: number;
    }>,
    required: true,
  },
  purchased: {
    type: Boolean,
    required: false,
  },
});

// Reactive variables
const isSaving = ref(false);

// Computed
const completedChallengeStatus = computed(() => {
  if (props.item.status === "waitingValidation") {
    return { color: "yellow", text: t("generic.validation.pending") };
  } else if (props.item.status === "refusedByAdmin") {
    return { color: "red", text: t("generic.validation.declined") };
  } else {
    return { color: "green", text: t("generic.validation.completed") };
  }
});

const rewardRetrievalSteps = computed(() => {
  return [
    {
      details: t("generic.requestedDetails"),
      isFirst: true,
      step: t("generic.requested"),
      status: {
        icon: "check",
        color: "#03874C",
        bgColor: "#ECFDF3",
      },
    },
    {
      details: t("generic.retrieve") + " " + props.item.retrievalInstruction,
      isCurrent: true,
      step: t("generic.awaiting"),
      status: {
        icon: props.item.status === "distributed" ? "check" : "clock",
        color: props.item.status === "distributed" ? "#03874C" : "#444CE7",
        bgColor: props.item.status === "distributed" ? "#ECFDF3" : "#EEF4FF",
      },
    },
    {
      details: t("generic.received"),
      isLast: true,
      step: t("generic.distributed"),
      status: {
        icon: props.item.status === "distributed" ? "check" : "clock",
        color: props.item.status === "distributed" ? "#03874C" : "#94A3B8",
        bgColor: props.item.status === "distributed" ? "#ECFDF3" : "#F8FAFC",
      },
    },
  ];
});

// Event emitters
const emit = defineEmits(["close"]);

// Event listeners
const onClose = () => {
  emit("close");
};

const onContactAdmin = async () => {
  window.open(
    `mailto:${userStore.user?.organization?.notificationEmail}`,
    "_blank"
  );

  if (props.purchased) {
    AnalyticsHelper.trackEvent("ask_support", {
      event_properties: "reward_requested",
      user_properties: "helper",
    });
  } else if (props.challenge && props.completed) {
    AnalyticsHelper.trackEvent("ask_support", {
      event_properties: "challenge",
      user_properties: "helper",
    });
  }
};

const onPurchaseReward = (reward: IReward) => {
  commonStore.setManageChallengesRewardsModal({
    open: true,
    item: reward,
    role: "reward-purchase",
    title: t("baseRewardChallengeDetails.title"),
  });

  AnalyticsHelper.trackEvent("buy_reward", {
    user_properties: "helper",
  });
};

const onStartingChallenge = async (challenge: IChallenge) => {
  isSaving.value = true;

  if (challenge.status === "notSignedUp" && challenge.challengeId) {
    await challengesStore.startChallenge(challenge.challengeId);

    AnalyticsHelper.trackEvent("challenge_doing", {
      user_properties: "helper",
    });
  }

  if (challenge.ctaHref) {
    if (challenge.ctaCategory === "email") {
      window.open(`mailto:${challenge.ctaHref}`, "_blank");
    } else {
      window.open(challenge.ctaHref, "_blank");
    }
  }

  isSaving.value = false;
};

const onValidatingChallenge = async (challenge: IChallenge) => {
  isSaving.value = true;

  if (challenge.userChallengeId) {
    await challengesStore.accomplishChallenge(challenge.userChallengeId);

    AnalyticsHelper.trackEvent("challenge_done", {
      user_properties: "helper",
    });
  }

  commonStore.setManageChallengesRewardsModal({
    open: true,
    item: challenge,
    role: "challenge-completed",
    title: t("generic.completedChallenge"),
  });

  isSaving.value = false;
};
</script>

<template>
  <div class="flex h-full w-full flex-col justify-between">
    <div>
      <div
        class="flex cursor-pointer content-center"
        :class="[purchased ? 'ml-4' : 'mb-4']"
        @click="onClose"
      >
        <BaseIcon color="#5F6C85" icon="chevron-left" />

        <span
          class="ml-1 text-mgm-txt-sm font-normal text-foreground-default"
          >{{
            challenge
              ? completed
                ? $t("generic.completedChallenges")
                : $t("generic.allChallenges")
              : purchased
                ? $t("generic.allRewards")
                : $t("generic.myRewards")
          }}</span
        >
      </div>

      <template v-if="purchased">
        <div class="mb-2 border-b border-border-subtle p-4 md:mb-4">
          <div class="mb-4 flex justify-between">
            <BaseAvatar
              :src="
                item.pictureUrl === null
                  ? '/img/reward_lg.png'
                  : item.pictureUrl
              "
              size="xl"
              shape="rounded"
            />

            <div class="ml-4 flex-1 truncate">
              <h3
                class="truncate text-mgm-txt-lg font-medium text-foreground-emphasis"
              >
                {{ item.name }}
              </h3>

              <BaseBadge
                v-if="item.points"
                class="mt-2"
                :text="item.points"
                size="md"
                color="grey"
                icon-type="icon"
                icon-name="star"
              />
            </div>
          </div>

          <p
            class="scrollbar-hide mt-4 max-h-28 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
            v-html="item.description"
          />
        </div>

        <div
          v-for="(
            rewardRetrievalStep, rewardRetrievalStepIndex
          ) in rewardRetrievalSteps"
          :key="rewardRetrievalStepIndex"
          class="flex px-4 md:px-8"
        >
          <div class="relative mr-4 flex flex-col items-center">
            <div
              :class="{
                'top-8': rewardRetrievalStep.isFirst,
                'top-0': !rewardRetrievalStep.isFirst,
                'h-6': rewardRetrievalStep.isLast,
                'bottom-0': !rewardRetrievalStep.isLast,
              }"
              class="absolute w-px rounded-b-sm bg-border-subtle"
            ></div>

            <BaseIcon
              class="relative z-10 mt-0.5"
              :icon="rewardRetrievalStep.status.icon"
              :color="rewardRetrievalStep.status.color"
              :bg-color="rewardRetrievalStep.status.bgColor"
            />
          </div>

          <div class="pb-10">
            <p
              :class="[
                item.status != 'purchased'
                  ? 'text-foreground-default'
                  : rewardRetrievalStep.isFirst
                    ? 'text-foreground-default'
                    : rewardRetrievalStep.isCurrent
                      ? 'text-foreground-emphasis'
                      : 'text-foreground-subtle',
              ]"
              class="mt-1 text-mgm-txt-sm"
            >
              {{ rewardRetrievalStep.step }}
            </p>

            <p
              :class="[
                item.status != 'purchased'
                  ? 'text-foreground-default'
                  : rewardRetrievalStep.isFirst || rewardRetrievalStep.isCurrent
                    ? 'text-foreground-default'
                    : 'text-foreground-subtle',
              ]"
              class="mt-1 text-mgm-txt-sm"
            >
              {{ rewardRetrievalStep.details }}
            </p>

            <BaseButton
              v-if="
                rewardRetrievalStep.isCurrent && item.status === 'purchased'
              "
              :text="$t('generic.adminContact')"
              class="mt-2"
              size="md"
              state="scdr"
              @click="onContactAdmin"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div v-if="challenge" class="relative">
          <img
            class="mb-8 h-[25vh] w-full rounded-[5px] border border-transparent object-cover"
            src="/img/challenge_lg.png"
          />
          <span
            class="absolute inset-0 flex items-center justify-center text-mgm-txt-sm font-medium text-foreground-brand-default"
            >{{ item.points }}</span
          >
        </div>

        <img
          v-else
          class="mb-8 h-[30vh] w-full rounded-[5px] border border-transparent object-cover"
          :src="
            item.pictureUrl === null ? '/img/reward_lg.png' : item.pictureUrl
          "
        />

        <div class="flex items-start justify-between">
          <h3
            class="mr-4 text-mgm-txt-lg font-medium text-foreground-emphasis"
            :class="{ 'max-w-[80%]': !challenge }"
          >
            {{ item.name }}
          </h3>

          <BaseBadge
            v-if="!challenge && item.points"
            :text="item.points"
            size="md"
            icon-type="icon"
            icon-name="star"
          />
        </div>

        <BaseBadge
          v-if="challenge && completed"
          class="mt-2"
          :color="completedChallengeStatus.color"
          :text="completedChallengeStatus.text"
          size="md"
        ></BaseBadge>

        <div
          v-if="!challenge"
          class="mt-4 flex items-start rounded-[5px] bg-background-subtle px-4 py-2"
        >
          <BaseIcon class="mr-2" icon="info" />

          <div class="bg-background-subtle text-mgm-txt-sm">
            <h4 class="font-medium text-foreground-emphasis">
              {{ $t("generic.retrieve") }}
            </h4>

            <p
              class="mt-1 font-normal text-foreground-default"
              v-html="item.retrievalInstruction"
            ></p>
          </div>
        </div>

        <p
          class="mt-4 text-mgm-txt-sm font-normal text-foreground-default"
          v-html="item.description"
        ></p>
      </template>
    </div>

    <div
      v-if="!purchased || challenge"
      class="sticky bottom-0 bg-background-default py-6"
    >
      <template v-if="challenge">
        <BaseButton
          v-if="completed && item.status === 'waitingValidation'"
          :text="$t('generic.adminContact')"
          class="mt-2 w-full"
          size="md"
          state="scdr"
          @click="onContactAdmin"
        />

        <template v-else-if="!completed">
          <BaseButton
            class="mb-2 w-full"
            :disabled="item.status === 'ongoing' || isSaving"
            :text="$t('generic.cta.enroll')"
            @click="onStartingChallenge(item)"
          />

          <BaseButton
            class="w-full"
            :disabled="item.status === 'notSignedUp' || isSaving"
            :text="$t('generic.done')"
            @click="onValidatingChallenge(item)"
          />
        </template>
      </template>

      <BaseButton
        v-else-if="userStore.user?.balance"
        class="w-full"
        :disabled="item.points > userStore.user.balance"
        :text="$t('generic.buy')"
        @click="onPurchaseReward(item)"
      />
    </div>
  </div>
</template>
