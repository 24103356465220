// NPM
import { defineStore } from "pinia";

// Services
import Api from "@/services/api";

// Types
import type { AxiosResponse } from "axios";
import type { RelationData } from "@/services/api.constants";
import type { IRelation } from "@/types/IRelation";
import type { IRelationsHelper } from "@/types/IRelationsHelper";

export const useRelationsStore = defineStore("relations", {
  state: () => {
    return {
      relations: {
        hasMultipleCampaigns: false,
        finished: [] as IRelation[],
        ongoing: [] as IRelation[],
        toProcess: [] as IRelation[],
      } as IRelationsHelper,
      relation: null as IRelation | null,
    };
  },

  actions: {
    async contactAdmin(payload: {
      file: File | undefined;
      message: string;
      relationMomentId: number;
    }) {
      await Api.userDashboard().relations.helper.contactAdmin(payload);
    },

    async getRelation(payload: {
      relationId?: number;
      type: "helpee" | "helper";
    }) {
      // Fetch relation
      let response: null | RelationData = null;

      if (payload.type === "helpee") {
        response = await Api.userDashboard().relations.helpee.getRelation(
          payload.relationId
        );
      } else if (payload.type === "helper" && payload.relationId) {
        response = await Api.userDashboard().relations.helper.getRelation(
          payload.relationId
        );
      }

      if (response) {
        this.relation = response.data;
      }
    },

    async getRelations() {
      const response =
        await Api.userDashboard().relations.helper.getRelations();

      this.relations.hasMultipleCampaigns = response.data.hasMultipleCampaigns;
      this.relations.finished = response.data.finished;
      this.relations.ongoing = response.data.onGoing;
      this.relations.toProcess = response.data.toProcess;
    },

    async markRelationMomentAs(payload: {
      comment?: string;
      rating?: 1 | 2 | null;
      relationMomentId: number;
      role: "done" | "ongoing" | "read";
      type?: "helpee" | "helper";
    }) {
      if (payload.role === "done") {
        if (payload.type === "helpee") {
          await Api.userDashboard().relations.helpee.markAsDone({
            comment: payload.comment,
            rating: payload.rating,
            relationMomentId: payload.relationMomentId,
          });
        } else if (payload.type === "helper") {
          await Api.userDashboard().relations.helper.markAsDone({
            comment: payload.comment,
            rating: payload.rating,
            relationMomentId: payload.relationMomentId,
          });
        }
      } else if (payload.role === "ongoing") {
        if (payload.type === "helpee") {
          await Api.userDashboard().relations.helpee.markAsongoing({
            relationMomentId: payload.relationMomentId,
          });
        } else if (payload.type === "helper") {
          await Api.userDashboard().relations.helper.markAsongoing({
            relationMomentId: payload.relationMomentId,
          });
        }
      } else if (payload.role === "read") {
        await Api.userDashboard().relations.helper.markAsRead({
          relationMomentId: payload.relationMomentId,
        });
      } else {
        throw new Error("Invalid type");
      }
    },

    async report(payload: {
      askRematch?: boolean | null;
      contactedAt?: Date | null;
      comment: string;
      reason: "ghosting" | "no-interest" | "other" | string;
      relationId: number;
      reportScreenshot?: File | null;
      type: "helpee" | "helper" | string;
    }) {
      let response: AxiosResponse | null = null;

      if (payload.type === "helpee") {
        const query: {
          report: {
            askRematch?: boolean | null;
            contactedAt?: Date | null;
            comment: string;
            reason: "ghosting" | "no-interest" | "other" | string;
            relationId: number;
          };
          reportScreenshot?: File | undefined;
        } = {
          report: {
            askRematch: payload.askRematch,
            comment: payload.comment,
            reason: payload.reason,
            relationId: payload.relationId,
          },
        };

        if (payload.reportScreenshot) {
          query.reportScreenshot = payload.reportScreenshot;
        }

        if (payload.contactedAt) {
          query.report.contactedAt = payload.contactedAt;
        }

        response = await Api.userDashboard().relations.helpee.reportHelper(
          query
        );

        this.relation = response.data;
      } else if (payload.type === "helper") {
        const query: {
          report: {
            contactedAt?: Date | null;
            comment: string;
            reason: "ghosting" | "no-interest" | "other" | string;
            relationId: number;
          };
          reportScreenshot?: File | undefined;
        } = {
          report: {
            comment: payload.comment,
            reason: payload.reason,
            relationId: payload.relationId,
          },
        };

        if (payload.reportScreenshot) {
          query.reportScreenshot = payload.reportScreenshot;
        }

        if (payload.contactedAt) {
          query.report.contactedAt = payload.contactedAt;
        }

        response = await Api.userDashboard().relations.helper.reportHelpee(
          query
        );

        this.relations.finished = response.data.finished;
        this.relations.ongoing = response.data.onGoing;
        this.relations.toProcess = response.data.toProcess;
      }
    },

    async referHelpee(payload: {
      firstname: string;
      lastname: string;
      email: string;
      campaignId: number;
      phoneNumber?: string;
    }) {
      await Api.userDashboard().relations.helper.referHelpee(payload);
    },
  },
});
