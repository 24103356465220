<script setup lang="ts">
import { hexToRgb } from '@/utils/color';
import {
	BaseIcon,
	FieldInput,
	BaseButton,
	FieldAvatarUploader,
} from '@magma-app/magma-lapilli';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useReviewsStore } from '@/stores/reviews';

interface Props {
	logoUrl: string;
	customColor?: string;
	comment: string;
	rating: number;
	data?: {
		firstName?: string;
		lastName?: string;
		email?: string;
		position?: string;
		photoUrl?: string;
		rating: number;
	};
}

const route = useRoute();
const uuid = route.params.uuid as string;
const token = localStorage.getItem('jwtToken');

const reviewsStore = useReviewsStore();

const emit = defineEmits(['submit', 'back']);
const props = defineProps<Props>();

const d = reactive({
	firstName: props.data?.firstName || '',
	lastName: props.data?.lastName || '',
	email: props.data?.email || '',
	position: props.data?.position || '',
	photoUrl: props.data?.photoUrl ? props.data.photoUrl : null,
	photoFile: null,
});

const handleSubmit = async () => {
	if (token && reviewsStore.reviews?.isHelper) {
		const infos = await reviewsStore.submitConnectedReview({
			comment: props.comment,
			rating: props.rating,
			uuid,
		});

		emit('submit', { d, infos });
	} else {
		const infos = await reviewsStore.submitPublicReview({
			uuid,
			comment: props.comment,
			rating: props.rating,
			firstname: d.firstName,
			lastname: d.lastName,
			email: d.email,
			position: d.position,
			profilePicture: d.photoFile ? d.photoFile : null,
			profilePictureUrl: d.photoUrl,
		});

		emit('submit', { d, infos });
	}
};
</script>

<template>
	<div class="w-full sm:w-auto">
		<div class="mb-4 hidden sm:block">
			<button
				@click="$emit('back')"
				class="text-foreground-default text-mgm-txt-sm font-medium flex items-center gap-1"
			>
				<BaseIcon icon="chevron-left" color="#5F6C85" />
				{{ $t('generic.back') }}
			</button>
		</div>
		<div
			class="sm:rounded-lg sm:border sm:border-border-subtle p-10 sm:shadow-lg bg-white w-full sm:w-[480px] flex flex-col justify-between h-dvh sm:h-auto"
		>
			<div>
				<img
					:src="logoUrl"
					alt="Learnico"
					class="w-10 h-10 rounded-full mb-4"
				/>
				<h3 class="text-mgm-txt-lg text-foreground-emphasis mb-3 font-medium">
					{{ $t('reviews.infos.title') }}
				</h3>
				<form>
					<FieldInput
						v-model="d.firstName"
						:label="$t('reviews.infos.firstname')"
						required
						placeholder="John"
						class="mb-3"
					/>
					<FieldInput
						v-model="d.lastName"
						:label="$t('reviews.infos.lastname')"
						required
						placeholder="Smith"
						class="mb-3"
					/>
					<FieldInput
						v-model="d.email"
						type="email"
						icon="mail"
						:label="$t('reviews.infos.email')"
						placeholder="john.smith@domain.com"
						required
						class="mb-3"
					/>
					<FieldInput
						v-model="d.position"
						:label="$t('reviews.infos.position')"
						required
						placeholder="Marketing at LinkedIn"
						class="mb-3"
					/>
					<FieldAvatarUploader
						id="profilePicture"
						v-model="d.photoFile"
						class="mt-4"
						:cta="$t('generic.change')"
						:label="$t('generic.profilePicture')"
						:locale="$i18n.locale"
						:preview-url="d.photoUrl ? d.photoUrl : undefined"
					/>
				</form>
			</div>
			<div>
				<BaseButton
					class="w-full mt-5"
					size="lg"
					:custom-color="customColor"
					:custom-rgb-color="hexToRgb(customColor as string)"
					:disabled="
						!d.firstName ||
						d.firstName === '' ||
						!d.lastName ||
						d.lastName === '' ||
						!d.email ||
						d.email === '' ||
						!d.position ||
						d.position === ''
					"
					@click="handleSubmit"
					>{{ $t('reviews.infos.submit') }}</BaseButton
				>
				<p class="text-mgm-txt-xs text-foreground-default text-center mt-4">
					{{ $t('reviews.infos.disclaimer') }}
				</p>
			</div>
		</div>
	</div>
</template>
