// NPM
import { ref } from "vue";
import { useI18n } from "vue-i18n";

// Stores
import { useUserStore } from "@/stores/user";

export function useNavigationItems() {
  // Stores
  const userStore = useUserStore();

  // Plugins
  const { t } = useI18n({ useScope: "global" });

  // Data
  const navigationItems = ref([
    {
      icon: "users",
      name: "Relations",
      to: {
        name: "relations-empty-state",
      },
      enabled: true,
    },
    {
      icon: "star",
      name: "Challenges",
      to: {
        name: "challenges",
      },
      enabled: userStore.user?.organization?.challengeEnabled,
    },
    {
      icon: "gift",
      name: t("generic.rewards"),
      to: {
        name: "rewards",
      },
      enabled: userStore.user?.organization?.rewardEnabled,
    },
    {
      icon: "link",
      name: t("generic.sharingPage"),
      to: {
        name: "sharing",
      },
      enabled: userStore.sharingPageEnabled,
    },
    {
      icon: "toggle",
      name: t("generic.helperStatus"),
      to: {
        name: "helper-status",
      },
      enabled: true,
    },
  ]);

  return {
    navigationItems,
  };
}
