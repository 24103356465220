<script setup lang="ts">
// NPM
import { nextTick, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';

// Components
import {
	BaseButton,
	BaseIcon,
	FieldRating,
	FieldTextarea,
} from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();

// Props
const props = defineProps({
	open: {
		type: Boolean,
		default: false,
	},
	role: {
		type: String as PropType<
			'done-with-feedback' | 'done-without-feedback' | 'in-contact' | 'referral'
		>,
		default: null,
	},
	feedbackType: {
		type: String as PropType<'rating' | 'comment' | 'full' | undefined>,
		default: undefined,
	},
	type: {
		type: String as PropType<'helpee' | 'helper'>,
		default: 'helper',
	},
	icon: {
		type: Boolean,
		default: false,
	},
	title: {
		type: String,
		default: null,
	},
	description: {
		type: String,
		default: null,
	},
	formText: {
		type: Object as PropType<{
			rating: { label: string; negative: string };
			comment: { label: string; placeholder: string };
		}>,
		default: null,
	},
	relationMomentId: {
		type: Number,
		default: null,
	},
	closeCta: {
		type: String,
		default: null,
	},
});

// Reactive variables
const root = ref<HTMLInputElement>();
const validatingMoment = ref(false);

const formData = reactive({
	comment: '',
	rating: null,
});

// Form validation
const v$ = useVuelidate(
	{
		rating: {
			requiredIf: requiredIf(() =>
				['full', 'rating'].includes(props.feedbackType || '')
			),
		},
		comment: {
			requiredIf: requiredIf(() =>
				['full', 'comment'].includes(props.feedbackType || '')
			),
		},
	},
	formData
);

// Methods
const onCloseModal = async () => {
	commonStore.closeManageRelationModal();

	if (props.role === 'referral') {
		router.push({ name: 'relations-empty-state' });
	}
};

const onMarkAsDoneWithFeedback = async () => {
	const moment = relationsStore.relation?.relation?.relationMoments.find(
		(moment) => {
			return moment.relationMomentId === props.relationMomentId;
		}
	);

	if (moment && moment[props.type]?.isValidated === false) {
		try {
			v$.value.$touch();

			if (await v$.value.$validate()) {
				validatingMoment.value = true;

				const payload = {
					comment: formData.comment,
					rating: formData.rating,
					relationMomentId: props.relationMomentId,
				};

				if (props.type === 'helpee') {
					await relationsStore.markRelationMomentAs({
						...payload,
						role: 'done',
						type: 'helpee',
					});

					await relationsStore.getRelation({
						relationId: parseInt(route?.params.relationId as string),
						type: 'helpee',
					});
				} else if (props.type === 'helper') {
					await relationsStore.markRelationMomentAs({
						...payload,
						role: 'done',
						type: 'helper',
					});

					await relationsStore.getRelation({
						relationId: parseInt(route?.params.relationId as string),
						type: 'helper',
					});

					await userStore.getUser(true, false, true, true);
				}

				AnalyticsHelper.trackEvent(
					`validated_by_${
						route?.name === 'helper-relation-overview' ? 'helper' : 'helpee'
					}`,
					{
						email_validation:
							route?.query.helper === 'done' || route?.query.helpee === 'done'
								? true
								: false,
						dashboard_validation:
							route?.query.helper === 'done' || route?.query.helpee === 'done'
								? false
								: true,
						user_id: route?.params.relationId as string,
					}
				);

				commonStore.closeManageRelationModal();

				await nextTick();

				v$.value.$reset();
				formData.rating = null;
				formData.comment = '';
			}
		} catch (error) {
			console.error(error);
		}
	}

	validatingMoment.value = false;
};

const onMarkAsDoneWithoutFeedback = async () => {
	validatingMoment.value = true;

	const moment = relationsStore.relation?.relation?.relationMoments.find(
		(moment) => {
			return moment.relationMomentId === props.relationMomentId;
		}
	);

	if (moment && moment[props.type]?.isValidated === false) {
		await relationsStore.markRelationMomentAs({
			relationMomentId: moment.relationMomentId,
			role: 'done',
			type: route?.name === 'helper-relation-overview' ? 'helper' : 'helpee',
		});

		await relationsStore.getRelation({
			relationId: parseInt(route?.params.relationId as string),
			type: route?.name === 'helper-relation-overview' ? 'helper' : 'helpee',
		});

		if (route?.name === 'helper-relation-overview') {
			await userStore.getUser(true, false, true, true);
		}

		AnalyticsHelper.trackEvent(
			`validated_by_${
				route?.name === 'helper-relation-overview' ? 'helper' : 'helpee'
			}`,
			{
				email_validation:
					route?.query.helper === 'done' || route?.query.helpee === 'done'
						? true
						: false,
				dashboard_validation:
					route?.query.helper === 'done' || route?.query.helpee === 'done'
						? false
						: true,
				user_id: route?.params.relationId as string,
			}
		);
	}

	commonStore.closeManageRelationModal();

	validatingMoment.value = false;
};
</script>

<template>
	<TransitionRoot as="template" :show="open" data-cy="modal">
		<Dialog
			:initial-focus="root"
			as="div"
			class="relative z-10"
			@close="onCloseModal"
		>
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
				/>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div ref="root" class="flex min-h-full items-center justify-center">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							class="mx-4 max-w-sm transform overflow-hidden rounded-[15px] bg-background-default p-6 shadow-xl transition-all md:mx-auto"
						>
							<BaseIcon
								v-if="icon"
								icon="star"
								:size="24"
								color="#444CE7"
								bg-color="#E0EAFF"
								class="border-background-brand-default border-8 w-fit mb-4"
							/>

							<BaseIcon
								class="absolute right-0 top-10 cursor-pointer sm:right-6 sm:top-6"
								color="#5F6C85"
								icon="close"
								@click="onCloseModal"
							/>

							<h3
								class="mb-2 text-mgm-txt-lg font-semibold text-foreground-emphasis"
							>
								{{ title }}
							</h3>

							<p
								class="scrollbar-hide max-h-44 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
								v-html="description"
							></p>

							<template v-if="role === 'done-with-feedback'">
								<FieldRating
									v-if="feedbackType === 'rating' || feedbackType === 'full'"
									id="ratings"
									v-model="formData.rating"
									class="mt-4"
									:label="
										formText?.rating?.label
											? formText?.rating?.label
											: $t('manageRelationModal.feedbackModalRating.label')
									"
									:ratings="[
										{
											emoji: '😒',
											label: formText?.rating?.negative
												? formText?.rating?.negative
												: $t('manageRelationModal.feedbackModalRating.bad'),
											value: 1,
										},
										{
											emoji: '🤩',
											label: $t('manageRelationModal.feedbackModalRating.good'),
											value: 2,
										},
									]"
									:validation="v$.rating"
									required
								/>

								<FieldTextarea
									v-if="feedbackType === 'comment' || feedbackType === 'full'"
									v-model="formData.comment"
									class="mt-4"
									:label="
										formText?.comment?.label
											? formText?.comment?.label
											: $t('manageRelationModal.feedbackModalComment.label')
									"
									:placeholder="
										formText?.comment?.placeholder
											? formText?.comment?.placeholder
											: $t(
													'manageRelationModal.feedbackModalComment.placeholder'
												)
									"
									:rows="3"
									:validation="v$.comment"
									data-cy="comment"
									required
								/>
							</template>

							<BaseButton
								v-if="role === 'done-with-feedback'"
								class="mt-6 w-full"
								:disabled="
									feedbackType === 'rating'
										? !formData.rating
										: feedbackType === 'comment'
											? !formData.comment
											: feedbackType === 'full'
												? !formData.rating ||
													!formData.comment ||
													validatingMoment
												: false
								"
								:text="$t('generic.validate')"
								data-cy="btn-validate-moment"
								@click="onMarkAsDoneWithFeedback"
							/>

							<BaseButton
								v-if="role === 'done-without-feedback'"
								class="mt-6 w-full"
								:disabled="validatingMoment"
								:text="$t('generic.validate')"
								data-cy="btn-validate-moment"
								@click="onMarkAsDoneWithoutFeedback"
							/>

							<BaseButton
								v-if="['referral'].includes(role) || closeCta"
								class="mt-6 w-full"
								state="prmr"
								:text="
									role === 'referral'
										? $t('manageRelationModal.back')
										: closeCta
											? closeCta
											: $t('generic.doneModalCta')
								"
								data-cy="btn-close-modal"
								@click="onCloseModal"
							/>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
