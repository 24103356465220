<script setup lang="ts">
// NPM
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Components
import { BaseAvatar, BaseBadge, BaseIcon } from '@magma-app/magma-lapilli';
import BaseRelationMoment from '@/components/bases/BaseRelationMoment.vue';

// Types
import type { IRelationMoment } from '@/types/IRelationMoment';
import type { ITouchPoint } from '@/types/ITouchPoint';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Composables & Stores
import { useDeterminePunctuation } from '@/composables/useDeterminePunctuation';
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
const { determinePunctuation } = useDeterminePunctuation();
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { locale, t } = useI18n({ useScope: 'global' });

// Reactive variables
const initialized = ref(false);

// Watchers
watchEffect(async () => {
	initialized.value = false;

	await relationsStore.getRelation({
		relationId: route?.params.relationId
			? parseInt(route?.params.relationId as string)
			: undefined,
		type: 'helpee',
	});

	await userStore.getCampaignInfo(
		parseInt(route?.params?.relationId as string)
	);

	if (relationsStore.relation?.language) {
		locale.value = relationsStore.relation.language;
	} else if (userStore.user?.language) {
		locale.value = userStore.user.language;
	}

	if (
		route?.query.helpee === 'contact' &&
		!relationsStore.relation?.hasBeenInContact
	) {
		router.push({
			name: 'start-relation-helpee',
			params: { relationId: route.params.relationId },
		});
	}

	if (route?.query.helpee === 'ongoing') {
		const moment = relationsStore.relation?.relation?.relationMoments.find(
			(moment) => {
				return (
					moment.relationMomentId === parseInt(route?.query.moment as string)
				);
			}
		);

		if (
			moment &&
			moment.helpee?.isOngoing === false &&
			(moment.helper?.isValidated === false || !moment.helper?.isValidated)
		) {
			await relationsStore.markRelationMomentAs({
				relationMomentId: parseInt(route?.query.moment as string),
				role: 'ongoing',
				type: 'helpee',
			});

			await relationsStore.getRelation({
				relationId: parseInt(route?.params.relationId as string),
				type: 'helpee',
			});
		}

		commonStore.setManageRelationModal({
			closeCta: t('generic.getIt'),
			description:
				t('helpeeRelationOverview.ongoingModal.description.partOne') +
				relationsStore.relation?.relation?.helper?.firstname +
				t('helpeeRelationOverview.ongoingModal.description.partTwo'),
			open: true,
			role: 'in-contact',
			title: t('generic.thanksUpdate'),
		});
	}

	if (route?.query.helpee === 'done') {
		const moment = relationsStore.relation?.relation?.relationMoments.find(
			(moment) => {
				return (
					moment.relationMomentId === parseInt(route?.query.moment as string)
				);
			}
		);

		if (moment && moment.helpee?.isValidated === false) {
			commonStore.setManageRelationModal({
				description: `${
					moment.helpee?.needHelpeeRating || moment.helpee?.needHelpeeComment
						? t('generic.feedbackModalDescription')
						: t('helpeeRelationOverview.noFeedbackModalDescription')
				}${relationsStore.relation?.relation?.helper?.firstname}.`,
				feedbackType:
					moment.helpee?.needHelpeeRating && moment.helpee?.needHelpeeComment
						? 'full'
						: moment.helpee?.needHelpeeRating
							? 'rating'
							: moment.helpee?.needHelpeeComment
								? 'comment'
								: undefined,
				open: true,
				title: t('generic.doneModalTitle'),
				relationMomentId: parseInt(route?.query.moment as string),
				helpeeName: relationsStore.relation?.relation?.helper?.firstname,
				role:
					moment.helpee?.needHelpeeRating || moment.helpee?.needHelpeeComment
						? 'done-with-feedback'
						: 'done-without-feedback',
				type: 'helpee',
			});
		}
	}

	initialized.value = true;
});

// Event listeners
const onHelpeeValidation = (relationMoment: IRelationMoment) => {
	commonStore.setManageRelationModal({
		description: `${
			relationMoment.helpee?.needHelpeeRating ||
			relationMoment.helpee?.needHelpeeComment
				? t('generic.feedbackModalDescription')
				: t('helpeeRelationOverview.noFeedbackModalDescription')
		}${relationsStore.relation?.relation?.helper?.firstname}.`,
		feedbackType:
			relationMoment.helpee?.needHelpeeRating &&
			relationMoment.helpee?.needHelpeeComment
				? 'full'
				: relationMoment.helpee?.needHelpeeRating
					? 'rating'
					: relationMoment.helpee?.needHelpeeComment
						? 'comment'
						: undefined,
		open: true,
		title: t('generic.doneModalTitle'),
		relationMomentId: relationMoment.relationMomentId,
		helpeeName: relationsStore.relation?.relation?.helper?.firstname,
		role:
			relationMoment.helpee?.needHelpeeRating ||
			relationMoment.helpee?.needHelpeeComment
				? 'done-with-feedback'
				: 'done-without-feedback',
		type: 'helpee',
	});

	AnalyticsHelper.trackEvent('validate_moment', {
		event_properties: 'timeline',
		user_properties: 'helpee',
	});
};

const onReport = () => {
	commonStore.setReportModal({
		open: true,
		role: 'report',
		type: 'helpee',
		title: t('generic.reportTitle'),
		description: t('generic.reportDescription'),
		helperName: relationsStore.relation?.relation?.helper?.firstname,
	});
};

const onTouchPointClick = (touchPoint: ITouchPoint) => {
	if (!relationsStore.relation?.isReported) {
		if (['line', 'phone'].includes(touchPoint.name)) {
			commonStore.setCallTextModal({
				helpeeName: relationsStore.relation?.relation?.helpee?.firstname,
				helperName: relationsStore.relation?.relation?.helper?.firstname,
				open: true,
				touchpoint: touchPoint.value,
			});
		}

		if (touchPoint.name === 'whatsapp') {
			const phoneNumber = touchPoint.value.replace(/\+/g, '');
			window.open(
				`https://api.whatsapp.com/send?phone=${phoneNumber}`,
				'_blank'
			);
		}

		if (touchPoint.name === 'mail') {
			window.open(`mailto:${touchPoint.value}`, '_blank');
		}

		if (touchPoint.type === 'pseudo') {
			window.open(touchPoint.value, '_blank');
		}

		AnalyticsHelper.trackEvent('send_message', {
			event_properties: 'touchpoints',
			user_properties: 'helpee',
		});
	}
};
</script>

<template>
	<div
		:class="[
			'flex h-full w-full flex-1 flex-col',
			{
				'bg-background-subtle lg:pl-3 lg:pt-3':
					!relationsStore.relation?.isReported,
			},
		]"
		data-cy="helpee-relation"
	>
		<header
			v-if="relationsStore.relation?.isReported"
			class="hidden sm:flex sm:items-center"
		>
			<div class="flex items-center px-20 py-5">
				<BaseAvatar
					:src="userStore.campaignInfo?.logoUrl"
					:alt="'logo ' + userStore.campaignInfo?.organizationName"
					:fallback="userStore.campaignInfo?.organizationName?.charAt(0)"
				/>

				<h1
					class="max-w-[300px truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
				>
					{{ userStore.campaignInfo?.organizationName }}
				</h1>
			</div>
		</header>

		<div
			:class="[
				relationsStore.relation?.isReported
					? 'min-h-screen bg-background-subtle-hover sm:pt-10'
					: 'flex flex-1 flex-col bg-background-default lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:border-b-transparent lg:border-r-transparent lg:pt-8',
			]"
		>
			<div
				v-if="initialized"
				:class="[
					'min-h-[95vh] w-full bg-background-default sm:rounded-2xl sm:shadow-[0px_4px_6px_-2px_rgba(28,31,39,0.03),0px_12px_16px_-4px_rgba(28,31,39,0.08)]',
					{
						'mx-auto max-w-[560px] sm:h-fit sm:min-h-fit':
							relationsStore.relation?.isReported,
					},
				]"
			>
				<template v-if="!relationsStore.relation?.isReported">
					<div class="mb-2 border-b border-border-subtle p-4 md:px-16">
						<div class="flex items-center justify-between">
							<span
								class="text-mgm-txt-lg font-medium text-foreground-emphasis"
							>
								{{ relationsStore.relation?.relation?.helper?.firstname }}
								{{ relationsStore.relation?.relation?.helper?.lastname }}
							</span>

							<BaseIcon
								:size="24"
								color="#5F6C85"
								icon="flag"
								hover-state
								@click="onReport"
							/>
						</div>

						<div class="mb-2 mt-3 flex justify-between">
							<BaseAvatar
								:src="
									relationsStore.relation?.relation?.helper?.profilePictureUrl
								"
								:fallback="
									(
										relationsStore.relation?.relation?.helper?.firstname || ''
									).charAt(0) +
									(
										relationsStore.relation?.relation?.helper?.lastname || ''
									).charAt(0)
								"
								size="xl"
							/>

							<div
								v-if="
									relationsStore.relation?.relation?.helper?.criteria?.length
								"
								class="ml-4 flex-1 text-mgm-txt-sm font-normal text-foreground-default"
							>
								<p
									v-for="criterium of relationsStore.relation?.relation?.helper
										.criteria"
									:key="criterium.value"
									class="mb-1 last:mb-0"
								>
									{{
										`${determinePunctuation(
											criterium.labelHelper || criterium.labelHelpee || '',
											':',
											['?']
										)}`
									}}
									<span class="font-medium">{{ criterium.value }}</span>
								</p>
							</div>
						</div>

						<div
							v-if="
								relationsStore.relation?.relation?.helper?.touchPoints?.length
							"
							class="flex flex-wrap"
						>
							<BaseBadge
								v-for="touchPoint of relationsStore.relation?.relation?.helper
									?.touchPoints"
								:key="touchPoint.name"
								:text="touchPoint.name"
								:class="[
									'mr-2 mt-2 last:mr-0',
									relationsStore.relation?.isReported
										? 'cursor-default'
										: 'cursor-pointer',
								]"
								size="lg"
								color="grey"
								icon-type="custom"
								@click="onTouchPointClick(touchPoint)"
							>
								<template #icon>
									<BaseAvatar
										:src="touchPoint.icon"
										size="xs"
										shape="squared"
									/>
								</template>
							</BaseBadge>
						</div>
					</div>

					<div class="px-4 pb-6 pt-4 md:mb-8 md:mt-6 md:px-6">
						<BaseRelationMoment
							v-for="relationMoment of relationsStore.relation?.relation
								?.relationMoments"
							:key="relationMoment.relationMomentId"
							:relation-moment="relationMoment"
							data-cy="helpee-relation-moment"
							@click="onHelpeeValidation(relationMoment)"
						/>
					</div>
				</template>

				<template v-else-if="relationsStore.relation?.isReported">
					<header class="flex items-center p-4 sm:hidden">
						<div class="flex items-center">
							<BaseAvatar
								:src="userStore.campaignInfo?.logoUrl"
								:alt="'logo ' + userStore.campaignInfo?.organizationName"
								:fallback="userStore.campaignInfo?.organizationName?.charAt(0)"
							/>

							<h1
								class="max-w-[300px truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
							>
								{{ userStore.campaignInfo?.organizationName }}
							</h1>
						</div>
					</header>

					<main class="px-4 py-6">
						<BaseIcon
							class="block max-w-fit border-8 border-background-success-default"
							:size="24"
							bg-color="#D1FADF"
							color="#03874C"
							icon="check"
						/>

						<h2
							class="tetx-foreground-emphasis mt-4 text-mgm-txt-lg font-semibold"
						>
							{{
								`${$t('helpeeRelationOverview.helpee.title.partOne')} ${
									relationsStore.relation?.report?.firstname
								} ${$t('helpeeRelationOverview.helpee.title.partTwo')}`
							}}
						</h2>

						<p class="mt-2 text-mgm-txt-sm font-normal text-foreground-default">
							{{
								`${
									relationsStore.relation?.report?.reason === 'ghosting'
										? `${$t(
												'helpeeRelationOverview.helpee.description.partOne'
											)} ${relationsStore.relation?.report?.firstname}.`
										: relationsStore.relation?.report?.reason === 'no-interest'
											? $t(
													'helpeeRelationOverview.helpee.description.notInterested'
												)
											: `${$t(
													'helpeeRelationOverview.reportRedirection.title'
												)}.`
								} ${$t('helpeeRelationOverview.helpee.description.partTwo')}`
							}}
						</p>

						<p
							v-if="relationsStore.relation?.report?.askRematch"
							class="mt-4 text-mgm-txt-sm font-normal text-foreground-default"
						>
							{{ $t('helpeeRelationOverview.helpee.description.partThree') }}
						</p>

						<p class="mt-4 text-mgm-txt-sm font-normal text-foreground-default">
							{{ $t('helpeeRelationOverview.helpee.description.partFour') }}
						</p>

						<ul
							class="mt-4 list-none rounded-[5px] bg-background-subtle p-4 text-mgm-txt-sm font-normal text-foreground-emphasis"
						>
							<li>
								<h3 class="text-foreground-default">
									{{ $t('helpeeRelationOverview.report.helpee.reason.label') }}
								</h3>
								<p>
									{{
										relationsStore.relation?.report?.reason === 'ghosting'
											? `${relationsStore.relation?.report?.firstname} ${$t(
													'helpeeRelationOverview.reportReasons.ghosting.title'
												)}`
											: relationsStore.relation?.report?.reason ===
												  'no-interest'
												? $t('generic.notInterested')
												: $t('generic.other')
									}}
								</p>
							</li>

							<li
								v-if="relationsStore.relation?.report?.contactedAt"
								class="mt-4"
							>
								<h3 class="text-foreground-default">
									{{
										`${$t(
											'helpeeRelationOverview.reportForm.ghosting.when.label'
										)} ${relationsStore.relation?.report?.firstname}${
											$i18n.locale === 'fr' ? ' ' : ''
										}?`
									}}
								</h3>
								<p>
									{{
										new Date(
											relationsStore.relation?.report?.contactedAt
										)?.toLocaleDateString($i18n.locale, {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})
									}}
								</p>
							</li>

							<li class="mt-4">
								<h3 class="text-foreground-default">
									{{
										$t(
											'helpeeRelationOverview.reportForm.ghosting.message.label'
										)
									}}
								</h3>
								<p>{{ relationsStore.relation?.report?.comment }}</p>
							</li>

							<li
								v-if="relationsStore.relation?.report?.askRematch"
								class="mt-4"
							>
								<h3 class="text-foreground-default">
									{{ $t('generic.rematch.label') }}
								</h3>
								<p>
									{{
										$t(
											`generic.rematch.${
												relationsStore.relation?.report?.askRematch
													? 'yes'
													: 'no'
											}`
										)
									}}
								</p>
							</li>
						</ul>
					</main>
				</template>
			</div>
		</div>
	</div>
</template>
