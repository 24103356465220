// NPM
import { useI18n } from "vue-i18n";

export function useDeterminePunctuation() {
  // Plugins
  const { locale } = useI18n({ useScope: "global" });

  const determinePunctuation = (
    text: string,
    defaultPunctuation: string,
    protectedPunctuation: string[]
  ): string => {
    // if the text ends with any of the protected punctuations or the default punctuation,
    // return the text without modification.
    if (
      protectedPunctuation.some((punc) => text.endsWith(punc)) ||
      text.endsWith(defaultPunctuation)
    ) {
      return text;
    }

    // if the text doesn't end with any of the protected punctuations or the default punctuation,
    // add the default punctuation to the end.
    return `${text}${locale.value === "fr" ? " " : ""}${defaultPunctuation}`;
  };

  return {
    determinePunctuation,
  };
}
