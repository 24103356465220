<script setup lang="ts">
// NPM
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

// Components
import { BaseAvatar, BaseButton, BaseLoader } from "@magma-app/magma-lapilli";
import TheSidebar from "@/components/shell/TheSidebar.vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useCommonStore } from "@/stores/common";
import { useUserStore } from "@/stores/user";
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: "global" });

// Reactive variables
const initialized = ref(false);

// Lifecycle hooks
onBeforeMount(async () => {
  await userStore.getSharingLink();

  initialized.value = true;
});

// Event listeners
const onCopyLink = (link: string) => {
  navigator.clipboard.writeText(link).catch((err) => {
    console.log("Something went wrong", err);
  });

  commonStore.setAlert({
    open: true,
    type: "success",
    title: t("helperSharingView.copied"),
    description: t("helperSharingView.paste"),
  });

  // Tracking copy link
  AnalyticsHelper.trackEvent("sp_copy_link", {
    user_properties: "helper",
  });
};

const onScanQrCode = (link: string) => {
  commonStore.setQrCodeModal({
    link: link,
    open: true,
  });

  // Tracking QR Code scan
  AnalyticsHelper.trackEvent("sp_qr_code", {
    user_properties: "helper",
  });
};

const onShareLink = (link: string) => {
  if (navigator.share) {
    navigator
      .share({
        title: document.title,
        url: link,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    commonStore.setShareModal({
      link: link,
      open: true,
    });
  }

  // Tracking share link to social media
  AnalyticsHelper.trackEvent("sp_share_social", {
    user_properties: "helper",
  });
};
</script>

<template>
  <div class="relative flex bg-background-subtle">
    <TheSidebar />

    <div
      class="w-full flex-1 bg-background-default pb-14 pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:pt-8 2xl:ml-64"
    >
      <div
        v-if="initialized"
        class="mx-auto mb-4 h-full px-4 md:px-8 lg:mb-8 lg:w-[704px]"
      >
        <h2 class="text-mgm-dspl-xs font-medium text-foreground-emphasis">
          {{ $t("generic.sharingPage") }}
        </h2>

        <p class="mt-2 text-mgm-txt-sm font-normal text-foreground-default">
          {{ $t("helperSharingView.shareLink") }}
        </p>

        <div
          class="mt-4 box-border w-full rounded-[15px] border border-border-subtle p-5 lg:p-10"
        >
          <div class="flex">
            <BaseAvatar
              v-if="userStore.user"
              :src="userStore.user.profilePictureUrl"
              :alt="`${$t('generic.profilePicture')} ${
                userStore.user.firstname
              } ${userStore.user.lastname}`"
              :fallback="
                userStore.user.firstname.charAt(0) +
                userStore.user.lastname.charAt(0)
              "
              size="lg"
            />

            <p class="ml-6">
              <span
                class="block text-mgm-txt-md font-medium text-foreground-emphasis"
              >
                {{ `${userStore.user?.firstname} ${userStore.user?.lastname}` }}
              </span>

              <span
                class="block text-mgm-txt-sm font-normal text-foreground-default"
              >
                {{ `Helper ${userStore?.user?.organization?.name}` }}
              </span>
            </p>
          </div>

          <div
            v-if="userStore.sharingLink"
            class="mt-6 rounded-[5px] bg-background-subtle py-2 px-3 text-mgm-txt-sm font-normal text-foreground-emphasis"
            @click="onCopyLink(userStore.sharingLink)"
          >
            {{ userStore.sharingLink }}
          </div>

          <template v-if="userStore.sharingLink">
            <BaseButton
              class="mt-2 w-full"
              :text="$t('helperSharingView.link')"
              icon="link"
              @click="onCopyLink(userStore.sharingLink)"
            />

            <div class="lg:flex">
              <BaseButton
                class="mt-4 w-full lg:mr-2"
                :text="$t('helperSharingView.getQr')"
                icon="qr-code"
                state="scdr"
                @click="onScanQrCode(userStore.sharingLink)"
              />

              <BaseButton
                class="mt-4 w-full lg:ml-2"
                :text="$t('generic.share')"
                icon="share"
                state="scdr"
                @click="onShareLink(userStore.sharingLink)"
              />
            </div>
          </template>
        </div>
      </div>

      <BaseLoader v-else class="min-h-screen" />
    </div>
  </div>
</template>
