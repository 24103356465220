<script setup lang="ts">
// NPM
import { RouterLink } from "vue-router";
import Cookie from "js-cookie";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
} from "@headlessui/vue";

// Components
import { BaseAvatar, BaseButton, BaseIcon } from "@magma-app/magma-lapilli";
import TheNavigationItem from "@/components/shell/TheNavigationItem.vue";

// Composables & Stores
import { useNavigationItems } from "@/composables/useNavigationItems";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
const { navigationItems } = useNavigationItems();
const authStore = useAuthStore();
const userStore = useUserStore();

// Methods
const onLogout = () => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("auth");
  Cookie.remove("jwtToken");
};

const onToggleMobileMenu = () => {
  document.body.classList.toggle("overflow-hidden");
};
</script>

<template>
  <Disclosure
    v-slot="{ close, open }"
    as="nav"
    class="relative bg-background-default"
  >
    <div
      class="mx-auto px-4 md:px-10 lg:px-20"
      :class="{
        'border-b border-b-border-subtle': [
          'helpee-campaigns',
          'helpee-signup',
          'helpee-report-feedback-view',
          'referred',
        ].includes($route?.name as string),
      }"
    >
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <component
              :is="
                [
                  'helpee-campaigns',
                  'helpee-signup',
                  'helpee-report-feedback-view',
                  'referred',
                ].includes($route?.name as string)
                  ? 'div'
                  : 'router-link'
              "
              class="flex items-center"
              :to="{
                name: 'relations-empty-state',
              }"
            >
              <BaseAvatar
                :src="
                  ['helpee-campaigns', 'helpee-signup'].includes(
                    $route?.name as string
                  )
                    ? authStore.helpeeSignUpData?.logoUrl
                    : $route?.name === 'helpee-report-feedback-view'
                      ? userStore.campaignInfo?.logoUrl
                      : $route?.name === 'referred'
                        ? authStore.referredSignUpData?.organization.logoUrl
                        : userStore.user?.organization?.logoUrl
                "
                :alt="
                  'logo ' +
                  (['helpee-campaigns', 'helpee-signup'].includes(
                    $route?.name as string
                  )
                    ? authStore.helpeeSignUpData?.name
                    : $route?.name === 'helpee-report-feedback-view'
                      ? userStore.campaignInfo?.organizationName
                      : $route?.name === 'referred'
                        ? authStore.referredSignUpData?.organization.name
                        : userStore.user?.organization?.name)
                "
                :fallback="
                  ['helpee-campaigns', 'helpee-signup'].includes(
                    $route?.name as string
                  ) && authStore.helpeeSignUpData?.name
                    ? authStore.helpeeSignUpData?.name?.charAt(0)
                    : $route?.name === 'helpee-report-feedback-view'
                      ? userStore.campaignInfo?.organizationName?.charAt(0)
                      : $route?.name === 'referred'
                        ? authStore.referredSignUpData?.organization.name?.charAt(
                            0
                          )
                        : userStore.user?.organization?.name?.charAt(0)
                "
              />

              <component
                :is="
                  [
                    'helpee-campaigns',
                    'helpee-signup',
                    'helpee-report-feedback-view',
                  ].includes($route?.name as string)
                    ? 'h1'
                    : 'span'
                "
                :class="[
                  'truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]',
                  [
                    'helpee-campaigns',
                    'helpee-signup',
                    'helpee-report-feedback-view',
                  ].includes($route?.name as string)
                    ? 'max-w-[300px]'
                    : 'max-w-[150px]',
                ]"
              >
                {{
                  [
                    "helpee-campaigns",
                    "helpee-signup",
                    "helpee-report-feedback-view",
                  ].includes($route?.name as string)
                    ? authStore.helpeeSignUpData?.name
                    : $route?.name === "referred"
                      ? authStore.referredSignUpData?.organization.name
                      : userStore.user?.organization?.name
                }}
              </component>
            </component>
          </div>
        </div>

        <div
          v-if="
            ![
              'helpee-campaigns',
              'helpee-signup',
              'helpee-report-feedback-view',
              'referred',
            ].includes($route?.name as string)
          "
          class="flex items-center"
        >
          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-10">
            <div>
              <component
                :is="
                  [
                    'helpee-campaigns',
                    'helpee-signup',
                    'helpee-report-feedback-view',
                    'referred',
                  ].includes($route?.name as string)
                    ? 'div'
                    : 'router-link'
                "
                class="mr-6 flex items-center"
                :to="{
                  name: 'settings-profile',
                }"
              >
                <span class="sr-only">Open user menu</span>

                <BaseAvatar
                  v-if="
                    userStore.user && $route?.name != 'helpee-relation-overview'
                  "
                  :src="userStore.user.profilePictureUrl"
                  :alt="`${$t('generic.profilePicture')} ${
                    userStore.user.firstname
                  } ${userStore.user.lastname}`"
                  :fallback="
                    userStore.user.firstname.charAt(0) +
                    userStore.user.lastname.charAt(0)
                  "
                />
              </component>
            </div>
          </Menu>
          <!-- Mobile menu button -->
          <DisclosureButton
            v-if="$route?.name != 'helpee-relation-overview'"
            class="inline-flex items-center justify-center rounded-md text-foreground-emphasis"
            @click="onToggleMobileMenu"
          >
            <span class="sr-only">Open main menu</span>

            <BaseIcon
              v-if="!open"
              :size="24"
              aria-hidden="true"
              color="#5F6C85"
              icon="menu"
            />

            <BaseIcon
              v-else
              :size="24"
              aria-hidden="true"
              color="#5F6C85"
              icon="close"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel
      v-if="
        ![
          'helpee-campaigns',
          'helpee-signup',
          'helpee-report-feedback-view',
          'referred',
        ].includes($route?.name as string)
      "
      class="absolute left-0 right-0 top-16 z-20 min-h-screen bg-background-default"
    >
      <div class="pt-4">
        <RouterLink
          class="flex items-center px-4"
          :to="{
            name: 'settings-profile',
          }"
          @click="close"
        >
          <BaseAvatar
            v-if="userStore.user && $route?.name != 'helpee-relation-overview'"
            :src="userStore.user.profilePictureUrl"
            :alt="`${$t('generic.profilePicture')} ${
              userStore.user.firstname
            } ${userStore.user.lastname}`"
            :fallback="
              userStore.user.firstname.charAt(0) +
              userStore.user.lastname.charAt(0)
            "
          />

          <div class="ml-3 text-base font-medium text-foreground-emphasis">
            {{ userStore.user?.firstname + " " + userStore.user?.lastname }}
          </div>
        </RouterLink>

        <div class="mt-5 border-t border-border-subtle pt-1">
          <TheNavigationItem
            v-for="navigationItem in navigationItems"
            :key="navigationItem.name"
            class="mt-1"
            :class="{ hidden: !navigationItem.enabled }"
            :icon="navigationItem.icon"
            :name="navigationItem.name"
            :to="navigationItem.to"
            exact-active-class
            @click="close"
          />

          <div class="mt-2 border-t border-border-subtle px-4 pt-2">
            <div
              v-if="userStore.referFormEnabled"
              class="w-full rounded-[5px] bg-background-subtle-hover px-4 py-5"
            >
              <BaseAvatar
                class="mx-auto"
                :src="`/img/avatars/avatar_${
                  Math.floor(Math.random() * 9) + 1
                }.png`"
                size="lg"
              />

              <p
                class="mt-4 text-center text-mgm-txt-sm font-normal text-foreground-default"
              >
                {{
                  `${$t("theNavbar.interested")} ${
                    [
                      "helpee-campaigns",
                      "helpee-signup",
                      "helpee-report-feedback-view",
                    ].includes($route?.name as string)
                      ? authStore.helpeeSignUpData?.name
                      : $route?.name === "referred"
                        ? authStore.referredSignUpData?.organization.name
                        : userStore.user?.organization?.name
                  } ?`
                }}
              </p>

              <RouterLink
                :to="{
                  name: 'referral',
                }"
                @click="close"
              >
                <BaseButton
                  class="mt-4 w-full"
                  :text="$t('generic.referCta')"
                />
              </RouterLink>
            </div>
          </div>

          <TheNavigationItem
            class="mt-2 capitalize"
            :name="$t('settings')"
            :to="{ name: 'settings-profile' }"
            icon="settings"
            exact-active-class
            @click="close"
          />

          <TheNavigationItem
            class="mt-1"
            :name="$t('generic.logout')"
            :to="{ name: 'login' }"
            icon="log-out"
            exact-active-class
            @click="onLogout"
          />
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
