<script setup lang="ts">
// NPM
import { computed } from "vue";

// Components
import { BaseAvatar, BaseBadge } from "@magma-app/magma-lapilli";

// Types
import type { PropType } from "vue";
import type { IRelation } from "@/types/IRelation";

// Stores
import { useRelationsStore } from "@/stores/relations";
import { useUserStore } from "@/stores/user";
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Props
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  relation: {
    type: Object as PropType<IRelation>,
    required: true,
  },
});

// Event Emitters
const emit = defineEmits(["click"]);

// Computed
const hasMultipleCampaigns = computed(
  () => relationsStore.relations.hasMultipleCampaigns
);

const relatedCampaign = computed(() =>
  userStore.campaigns.find(
    (campaign) => campaign.id === props.relation.campaignId
  )
);

// Event Listeners
const onClick = () => {
  emit("click", props.relation);
};
</script>

<template>
  <div
    class="flex w-full cursor-pointer border-t border-border-subtle px-4 py-[18px] md:px-8"
    :class="{
      'bg-mgm-prmr-bg': active,
      'items-center': !hasMultipleCampaigns,
    }"
    @click="onClick"
  >
    <BaseAvatar
      :fallback="relation.firstname.charAt(0) + relation.lastname.charAt(0)"
      :src="relation.profilePictureUrl"
      size="md"
    />

    <div class="flex-1 truncate pl-3">
      <div class="flex items-center justify-between">
        <div
          :class="[
            'flex items-center truncate',
            relation.daysLeft ? 'w-[70%]' : 'w-full',
          ]"
        >
          <div
            class="w-[calc(100%-16px)] items-center truncate text-mgm-txt-md font-medium text-foreground-emphasis"
          >
            {{ relation.firstname }} {{ relation.lastname }}
          </div>

          <div
            v-if="!relation.isRead"
            class="ml-2 h-2 w-2 rounded-full bg-background-brand-emphasis"
          ></div>
        </div>

        <div
          v-if="relation.daysLeft"
          class="ml-2 text-mgm-txt-sm font-normal text-foreground-subtle"
        >
          {{
            relation.daysLeft +
            (relation.daysLeft > 1
              ? $t("generic.daysLeft")
              : $t("generic.dayLeft"))
          }}
        </div>
      </div>

      <p class="text-mgm-txt-sm font-normal text-foreground-default">
        {{ relation.momentName }}
      </p>

      <BaseBadge
        v-if="hasMultipleCampaigns && relatedCampaign"
        class="mt-3 max-w-full"
        :text="relatedCampaign?.title || ''"
        color="grey"
      />
    </div>
  </div>
</template>
