<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { BaseButton, Stars } from '@magma-app/magma-lapilli';

interface Props {
	logoUrl: string;
	customColor?: string;
	points: number;
	data: {
		firstName?: string;
		lastName?: string;
		email?: string;
		position?: string;
		photoUrl?: string;
		rating: number;
		comment: string;
		isHelper: boolean;
	};
}

const authStore = useAuthStore();

const props = defineProps<Props>();
const emit = defineEmits(['login']);

const handleLogin = () => {
	authStore.signIn(props.data?.email || '');
	emit('login');
};
</script>

<template>
	<div
		class="sm:rounded-lg sm:border sm:border-border-subtle p-10 sm:shadow-lg bg-white w-full sm:w-[480px]"
	>
		<img :src="logoUrl" alt="Learnico" class="w-10 h-10 rounded-full mb-4" />
		<h3 class="text-mgm-txt-lg text-foreground-emphasis font-medium">
			{{ $t('reviews.resume.title') }}
		</h3>
		<p class="text-foreground-default text-mgm-txt-sm mb-4">
			{{ $t('reviews.resume.subtitle') }}
		</p>
		<div class="p-4 rounded-lg shadow">
			<div class="flex items-center gap-2 mb-3">
				<img class="w-10 h-10 rounded-full" :src="data.photoUrl" />
				<div>
					<h3 class="text-mgm-txt-sm font-medium text-foreground-emphasis">
						{{ data.firstName }} {{ data.lastName }}
					</h3>
					<p class="text-sm text-foreground-default">
						{{ data.position }}
					</p>
				</div>
			</div>
			<Stars :rating="data.rating" class="w-fit" />
			<p class="text-mgm-text-sm text-foreground-emphasis mt-2">
				{{ data.comment }}
			</p>
		</div>

		<p
			v-if="!data.isHelper"
			class="text-center mt-10 text-mgm-txt-sm text-foreground-default"
		>
			{{ $t('reviews.resume.leave') }}
		</p>

		<hr v-if="data.isHelper" class="border-t border-border-subtle my-8" />

		<div v-if="data.isHelper" class="flex items-center gap-2">
			<img src="/img/reward_square.png" alt="Points" />
			<div>
				<h4 class="text-mgm-txt-sm font-medium text-foreground-emphasis">
					{{ $t('reviews.resume.helper.title.start') }}{{ points
					}}{{ $t('reviews.resume.helper.title.end') }}
				</h4>
				<p class="text-foreground-default text-mgm-txt-sm">
					{{ $t('reviews.resume.helper.text') }}
				</p>
			</div>
		</div>

		<BaseButton
			v-if="data.isHelper"
			class="w-full mt-5"
			size="lg"
			:custom-color="customColor"
			:custom-rgb-color="'32, 164, 90'"
			:disabled="false"
			@click="handleLogin"
			>{{ $t('reviews.resume.helper.login') }}</BaseButton
		>
	</div>
</template>
