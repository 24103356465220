<script setup lang="ts">
// NPM
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// Components
import { BaseBadge, BaseLoader, FieldTabs } from "@magma-app/magma-lapilli";
import TheSidebar from "@/components/shell/TheSidebar.vue";

// Stores
import { useChallengesStore } from "@/stores/challenges";
import { useUserStore } from "@/stores/user";
const challengesStore = useChallengesStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: "global" });

// Data
const tabs = [
  {
    label: t("generic.allChallenges"),
    value: "allChallenges",
  },
  {
    label: t("generic.completedChallenges"),
    value: "completedChallenges",
  },
];

// Reactive variables
const currentTab = ref<"allChallenges" | "completedChallenges">(
  route?.name === "challenges" ? "allChallenges" : "completedChallenges"
);
const initialized = ref(false);

// Lifecycle hooks
onBeforeMount(async () => {
  await challengesStore.getChallenges();

  initialized.value = true;
});
</script>

<template>
  <div class="relative flex bg-background-subtle">
    <TheSidebar />

    <div
      class="flex w-full flex-1 flex-col bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:pt-8 2xl:ml-64"
      :class="{
        'pb-14':
          !challengesStore.displayChallengeDetails &&
          !challengesStore.displayCompletedChallengeDetails,
      }"
    >
      <div
        class="mb-4 flex items-center justify-between px-4 md:px-8 lg:mb-8"
        :class="[
          challengesStore.displayChallengeDetails ||
          challengesStore.displayCompletedChallengeDetails
            ? 'hidden lg:flex'
            : 'flex',
        ]"
      >
        <h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
          Challenges
        </h2>

        <BaseBadge
          :text="userStore.user?.balance ? userStore.user?.balance : 0"
          size="md"
          icon-type="icon"
          icon-name="star"
        />
      </div>

      <FieldTabs
        v-model="currentTab"
        class="mx-4 mb-3 flex justify-start md:mx-8 lg:mb-6"
        :class="[
          challengesStore.displayChallengeDetails ||
          challengesStore.displayCompletedChallengeDetails
            ? 'hidden lg:flex'
            : 'flex',
        ]"
        :tabs="tabs"
        @update:model-value="
          currentTab === 'completedChallenges'
            ? router.push({ name: 'completed-challenges' })
            : router.push({ name: 'challenges' })
        "
      />

      <routerView v-if="initialized" class="flex-1" />

      <BaseLoader v-else class="min-h-screen" />
    </div>
  </div>
</template>
