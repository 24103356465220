// NPM
import { defineStore } from 'pinia';

// Services
import Api from '@/services/api';

// Types
import type {
	ReviewsGetReviewStatusResponseType,
	ReviewsCreateReviewRequestType,
	ReviewsCreatePublicReviewRequestType,
} from '@magma-app/magma-types/review';

export const useReviewsStore = defineStore('reviews', {
	state: () => {
		return {
			reviews: null as ReviewsGetReviewStatusResponseType | null,
		};
	},

	actions: {
		async getReviewStatus(uuid: string, connected: boolean) {
			const response = await Api.userDashboard().reviews.getReviewStatus(
				uuid,
				connected
			);

			this.reviews = response.data;
		},

		async submitConnectedReview(data: ReviewsCreateReviewRequestType) {
			const response =
				await Api.userDashboard().reviews.submitConnectedReview(data);

			return response.data;
		},

		async submitPublicReview(
			data: ReviewsCreatePublicReviewRequestType & {
				profilePicture: File | null;
			}
		) {
			const response =
				await Api.userDashboard().reviews.submitPublicReview(data);

			return response.data;
		},
	},
});
