<script setup lang="ts">
// NPM
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';

// Components
import { BaseAvatar, BaseButton } from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';
import type { IRelationMoment } from '@/types/IRelationMoment';
import type { ITouchPoint } from '@/types/ITouchPoint';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();

// Plugins
const route = useRoute();

// Props
const props = defineProps({
	moment: {
		type: Object as PropType<IRelationMoment>,
		default: null,
	},
	name: {
		type: String,
		default: null,
	},
	open: {
		type: Boolean,
		default: false,
	},
	touchpoints: {
		type: Array as PropType<ITouchPoint[]>,
		default: () => [],
	},
});

// Reactive variables
const root = ref<HTMLInputElement>();

// Event Listeners
const onTouchpointClick = async (touchpoint: ITouchPoint) => {
	if (['line', 'phone'].includes(touchpoint.name)) {
		commonStore.setCallTextModal({
			helpeeName: props.name,
			helperName: props.name,
			open: true,
			touchpoint: touchpoint.value,
		});
	}

	if (touchpoint.name === 'whatsapp') {
		const phoneNumber = touchpoint.value.replace(/\+/g, '');
		window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
	}

	if (touchpoint.name === 'mail') {
		window.open(`mailto:${touchpoint.value}`, '_blank');
	}

	if (touchpoint.type === 'pseudo') {
		window.open(touchpoint.value, '_blank');
	}

	if (
		(props.moment.helper &&
			props.moment.helper.isOngoing === false &&
			props.moment.helper.isValidated === false) ||
		(props.moment.helpee &&
			props.moment.helpee.isOngoing === false &&
			props.moment.helpee.isValidated === false)
	) {
		await relationsStore.markRelationMomentAs({
			relationMomentId: props.moment.relationMomentId,
			role: 'ongoing',
			type:
				route?.name === 'helper-relation-overview' ||
				route?.name === 'start-relation-helper'
					? 'helper'
					: 'helpee',
		});

		await relationsStore.getRelation({
			relationId: parseInt(route?.params.relationId as string),
			type:
				route?.name === 'helper-relation-overview' ||
				route?.name === 'start-relation-helper'
					? 'helper'
					: 'helpee',
		});
	}

	commonStore.closeTouchpointsModal();
};
</script>

<template>
	<TransitionRoot as="template" :show="open">
		<Dialog
			:initial-focus="root"
			as="div"
			class="relative z-10"
			@close="commonStore.closeTouchpointsModal()"
		>
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
				/>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div
					ref="root"
					class="flex min-h-full items-end justify-center md:items-center"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							data-cy="contact-touchpoints"
							class="mx-4 mb-8 transform overflow-hidden rounded-[15px] bg-background-default shadow-xl transition-all md:mx-auto md:mb-0"
						>
							<div
								class="border-b border-border-subtle p-2.5 text-center text-mgm-txt-sm font-normal capitalize text-foreground-default"
							>
								{{ `${$t('generic.contact')} ${name}` }}
							</div>

							<div class="flex flex-col">
								<div
									v-for="touchpoint in touchpoints"
									:key="touchpoint.name"
									:data-cy="touchpoint.name + '-touchpoint'"
									class="flex w-80 flex-1 cursor-pointer items-center border-b border-border-subtle px-4 py-2 hover:bg-background-subtle-hover"
									@click="onTouchpointClick(touchpoint)"
								>
									<BaseAvatar
										:src="touchpoint.icon"
										class="mr-3"
										size="sm"
										shape="squared"
									/>

									<div class="max-w-[80%]">
										<span
											class="block truncate text-mgm-txt-sm font-medium capitalize text-foreground-emphasis"
											>{{ touchpoint.name }}</span
										>
										<span
											class="block truncate text-mgm-txt-xs font-normal text-foreground-default"
											>{{ touchpoint.value }}</span
										>
									</div>
								</div>

								<BaseButton
									data-cy="close-touchpoints"
									class="mx-auto my-1"
									state="trtr"
									:text="$t('generic.cancel')"
									danger
									@click="commonStore.closeTouchpointsModal()"
								/>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
