<script setup lang="ts">
import { animate, spring } from 'motion';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

type Props = {
	images: string[];
};

const indexImgSize = [
	'w-10',
	'w-14',
	'w-12',
	'w-10',
	'w-14',
	'w-12',
	'w-10',
	'w-14',
];

// transform indexImgPostion to array of object
const indexImgAnimation = [
	{ top: ['50%', '5%'], left: ['50%', '20%'], opacity: [0, 0, 1] },
	{ top: ['50%', '5%'], right: ['50%', '15%'], opacity: [0, 0, 1] },
	{ top: ['50%', '19%'], left: ['50%', '34%'], opacity: [0, 0, 1] },
	{ top: ['50%', '33%'], right: ['50%', '8%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '40%'], left: ['50%', '5%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '34%'], right: ['50%', '35%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '22%'], left: ['50%', '22%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '14%'], right: ['50%', '18%'], opacity: [0, 0, 1] },
];

const props = defineProps<Props>();

const { t } = useI18n();

onMounted(() => {
	props.images.forEach((_, index) => {
		const imgEl = document.querySelector(
			`.onboarding-user-photo:nth-child(${index + 1})`
		) as HTMLElement;
		animate(imgEl, indexImgAnimation[index], {
			duration: 6,
			delay: 0.1 * index,
			easing: spring(),
		});
	});
});
</script>

<template>
	<div
		class="flex flex-col h-full flex-1 md:px-16 gap-0 md:gap-4 -mt-8 md:mt-0"
	>
		<div
			class="w-full aspect-square bg-[url('/img/illustration_helper_onboarding_background.svg')] bg-contain bg-no-repeat bg-center relative"
		>
			<img
				v-for="(img, index) in images"
				:src="img"
				:class="`${indexImgSize[index]} onboarding-user-photo h-auto rounded-full shadow-md border border-white absolute transition-all duration-200 -translate-y-1/2`"
			/>
		</div>

		<h1
			class="text-mgm-dspl-xs md:text-mgm-dspl-sm text-foreground-emphasis text-center mb-10 md:mb-0"
		>
			{{ t('helperOnboarding.welcome.title') }}
		</h1>
	</div>
</template>
