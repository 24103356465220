<script setup lang="ts">
// NPM
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

// Plugins
const { t } = useI18n({ useScope: "global" });
const route = useRoute();

// Computed
const navigation = computed(() => {
  return [
    {
      name: t("generic.powered"),
      href: "https://www.magma.app/",
      hide: ![
        "helpee-campaigns",
        "helpee-signup",
        "helpee-report-feedback-view",
        "referred",
        "referred-sharing",
      ].includes(route?.name as string),
      id: "magma",
    },
    {
      name: t("theFooter.terms"),
      href: "https://www.magma.app/legals/helpers-terms-of-use",
      hide: [
        "helpee-campaigns",
        "helpee-signup",
        "helpee-report-feedback-view",
        "referred",
        "referred-sharing",
      ].includes(route?.name as string),
    },
    {
      name: t("theFooter.legal"),
      href: "https://www.magma.app/legals/terms",
      hide: [
        "helpee-campaigns",
        "helpee-signup",
        "helpee-report-feedback-view",
        "referred",
        "referred-sharing",
      ].includes(route?.name as string),
    },
    {
      name: t("theFooter.privacy"),
      href: "https://www.magma.app/legals/privacy-policy",
      hide: [
        "helpee-campaigns",
        "helpee-signup",
        "helpee-report-feedback-view",
        "referred",
        "referred-sharing",
      ].includes(route?.name as string),
    },
  ];
});
</script>

<template>
  <footer>
    <div
      class="mx-auto max-w-7xl overflow-hidden px-4 pb-8 sm:px-6 lg:px-8"
      :class="[
        ['helpee-campaigns', 'helpee-signup'].includes(route?.name as string)
          ? 'pt-0'
          : 'pt-8',
      ]"
    >
      <nav
        class="-mx-5 flex flex-col flex-wrap items-center justify-center sm:-my-2 sm:flex-row"
        aria-label="Footer"
      >
        <a
          v-for="item in navigation"
          :key="item.name"
          :class="[
            'px-5 text-mgm-txt-xs font-normal text-foreground-subtle sm:py-2',
            { hidden: item.hide, 'flex items-center ': item.id === 'magma' },
          ]"
          :href="item.href"
          target="_blank"
        >
          {{ item.name }}
          <img
            v-if="item.id === 'magma'"
            class="ml-2"
            src="/img/logo_magma_full_bw.svg"
          />
        </a>
      </nav>
    </div>
  </footer>
</template>
