<script setup lang="ts">
// NPM
import { reactive, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { createI18nMessage, required, requiredIf } from '@vuelidate/validators';
import { useMq } from 'vue3-mq';

// Components
import {
	BaseAvatar,
	BaseButton,
	BaseIcon,
	FieldDatePicker,
	FieldFileUploader,
	FieldLabel,
	FieldRadio,
	FieldTextarea,
} from '@magma-app/magma-lapilli';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
import { onBeforeMount } from 'vue';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { locale, t } = useI18n({ useScope: 'global' });
const withI18nMessage = createI18nMessage({ t });
const mq = useMq();

// Reactive variables
const initialized = ref(false);
const isSending = ref(false);

// Form
const formData = reactive({
	message: '',
	rematch: undefined as string | undefined,
	screenshot: null as File | null,
	selectedDate: null as Date | null,
});

// Form validation
const v$ = useVuelidate(
	{
		message: {
			required: withI18nMessage(required, {
				messagePath: () => t('validation.value'),
			}),
		},
		rematch: {
			requiredIfRef: withI18nMessage(
				requiredIf(
					() =>
						['ghosting', 'other'].includes(route?.params.reason as string) &&
						route?.params.userType === 'helpee'
				),
				{
					messagePath: () => t('validation.value'),
				}
			),
		},
		screenshot: {
			size: withI18nMessage(
				(value: File) => {
					if (value) {
						return value ? value.size <= 50 * 1000 * 1000 : true;
					} else {
						return true;
					}
				},
				{
					messagePath: () => t('validation.fileSize'),
				}
			),
		},
		selectedDate: {
			requiredIfRef: withI18nMessage(
				requiredIf(() => route?.params.reason === 'ghosting'),
				{
					messagePath: () => t('validation.value'),
				}
			),
		},
	},
	formData
);

// Watchers
onBeforeMount(async () => {
	initialized.value = false;

	relationsStore.getRelation({
		relationId: parseInt(route?.params.relationId as string),
		type: route?.params.userType as 'helpee' | 'helper',
	});

	if (userStore.user?.language) {
		locale.value = userStore.user.language;
	} else if (relationsStore.relation?.language) {
		locale.value = relationsStore.relation?.language;
	}

	await userStore.getCampaignInfo(parseInt(route?.params.relationId as string));

	initialized.value = true;
});

// Event listeners
const onReturnToRelation = () => {
	router.push({
		name: `${route?.params.userType as string}-relation-overview`,
		params: {
			relationId: route?.params.relationId,
		},
	});
};

const onSendReport = async () => {
	isSending.value = true;

	v$.value.$touch();

	if (await v$.value.$validate()) {
		v$.value.$reset();

		try {
			const payload = {
				askRematch: null as boolean | null,
				contactedAt: null as Date | null,
				comment: formData.message,
				reason: route?.params.reason as string,
				relationId: parseInt(route?.params.relationId as string),
				reportScreenshot: null as File | null,
				type: route?.params.userType as 'helpee' | 'helper',
			};

			if (formData.rematch !== undefined) {
				payload.askRematch = formData.rematch === 'true';
			}

			if (formData.screenshot) {
				payload.reportScreenshot = formData.screenshot;
			}

			if (formData.selectedDate) {
				payload.contactedAt = formData.selectedDate;
			}

			await relationsStore.report(payload);

			onReturnToRelation();

			if (route?.params.userType === 'helper') {
				commonStore.setReportModal({
					open: true,
					role: 'reported',
					type: 'helper',
					title: t('reportRelationView.ghosting.reported.title'),
					description: t('reportRelationView.ghosting.reported.description'),
				});
			}

			let trackingProperty = '';

			if (route?.params.reason === 'ghosting') {
				trackingProperty = `${
					route?.params.userType as 'helpee' | 'helper'
				}_report_noReply`;
			} else if (route?.params.reason === 'no-interest') {
				trackingProperty = `${
					route?.params.userType as 'helpee' | 'helper'
				}_report_notInterested`;
			} else if (route?.params.reason === 'other') {
				trackingProperty = `${
					route?.params.userType as 'helpee' | 'helper'
				}_report_other`;
			}

			AnalyticsHelper.trackEvent(trackingProperty);
		} catch (error) {
			console.log(error);
		}
	}

	isSending.value = false;
};
</script>

<template>
	<div
		v-if="route?.params.userType === 'helpee'"
		class="hidden sm:flex sm:items-center"
	>
		<div class="flex items-center px-20 py-5">
			<BaseAvatar
				:src="userStore.campaignInfo?.logoUrl"
				:alt="'logo ' + userStore.campaignInfo?.organizationName"
				:fallback="userStore.campaignInfo?.organizationName?.charAt(0)"
			/>

			<h1
				class="max-w-[300px truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
			>
				{{ userStore.campaignInfo?.organizationName }}
			</h1>
		</div>
	</div>

	<div
		:class="[
			'bg-background-subtle-hover',
			route?.params.userType === 'helper'
				? 'sm:flex sm:min-h-screen sm:items-center'
				: 'sm:min-h-[calc(100vh-72px)] sm:pt-10',
		]"
	>
		<div
			v-if="initialized"
			class="mx-auto h-full min-h-screen max-w-[640px] bg-background-default p-6 sm:h-fit sm:min-h-fit sm:rounded-2xl sm:px-10 sm:pb-10 sm:pt-9 sm:shadow-[0px_4px_6px_-2px_rgba(28,31,39,0.03),0px_12px_16px_-4px_rgba(28,31,39,0.08)]"
		>
			<header class="pb-6 sm:pb-0">
				<div class="flex items-center justify-between">
					<BaseIcon
						v-if="route?.params.userType === 'helper'"
						class="sm:hidden"
						:size="24"
						color="#5F6C85"
						icon="chevron-left"
						hover-state
						@click="onReturnToRelation"
					/>

					<div
						v-else-if="route?.params.userType === 'helpee'"
						class="flex items-center sm:hidden"
					>
						<div class="flex items-center">
							<!-- Need: org logo + name -->
							<BaseAvatar
								:src="userStore.campaignInfo?.logoUrl"
								:alt="'logo ' + userStore.campaignInfo?.organizationName"
								:fallback="userStore.campaignInfo?.organizationName?.charAt(0)"
							/>

							<h1
								class="max-w-[300px] truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
							>
								{{ userStore.campaignInfo?.organizationName }}
							</h1>
						</div>
					</div>

					<button class="flex items-center" @click="onReturnToRelation">
						<BaseIcon
							:icon="mq.smPlus ? 'chevron-left' : 'close'"
							:size="mq.smPlus ? 16 : 24"
							color="#5F6C85"
							hover-state
						/>

						<span
							class="ml-1 hidden text-mgm-txt-sm font-medium text-foreground-default sm:block"
							>{{ $t('reportRelationView.ghosting.reported.cta') }}</span
						>
					</button>
				</div>

				<h1
					class="mx-auto mt-6 text-center text-mgm-txt-lg font-medium text-foreground-emphasis"
				>
					{{ $t('reportRelationView.ghosting.title') }}
				</h1>
			</header>

			<FieldDatePicker
				v-if="route?.params.reason === 'ghosting'"
				id="selectedDate"
				v-model="formData.selectedDate"
				class="mt-4"
				:description="$t('reportRelationView.ghosting.when.description')"
				:label="`${$t('reportRelationView.ghosting.when.label')} ${
					route?.params.userType === 'helper'
						? relationsStore.relation?.relation?.helpee?.firstname
						: relationsStore.relation?.relation?.helper?.firstname
				}${$i18n.locale === 'fr' ? ' ' : ''}?`"
				:locale="$i18n.locale"
				:placeholder="$t('reportRelationView.ghosting.when.placeholder')"
				:validation="v$.selectedDate"
				required
			/>

			<FieldTextarea
				id="message"
				v-model="formData.message"
				class="mt-4"
				:label="$t('reportRelationView.ghosting.message.label')"
				:description="
					route?.params.reason === 'ghosting'
						? `${$t(
								'reportRelationView.ghosting.message.description.partOne'
							)} ${
								route?.params.userType === 'helper'
									? relationsStore.relation?.relation?.helpee?.firstname
									: relationsStore.relation?.relation?.helper?.firstname
							} ${$t(
								'reportRelationView.ghosting.message.description.partTwo'
							)}`
						: route?.params.reason === 'no-interest'
							? $t('reportRelationView.notInterested.message.description')
							: $t('reportRelationView.other.message.description')
				"
				:placeholder="`${$t('reportRelationView.description')}...`"
				:rows="mq.smPlus ? 7 : 5"
				:validation="v$.message"
				required
			/>

			<template
				v-if="
					['ghosting', 'other'].includes(route?.params.reason as string) &&
					route?.params.userType === 'helpee'
				"
			>
				<div class="mb-2 mt-4 flex items-start">
					<FieldLabel id="rematch" :label="$t('generic.rematch.label')" />

					<span
						v-if="required"
						class="ml-1 text-mgm-txt-sm font-medium text-foreground-brand-default"
					>
						*
					</span>
				</div>

				<div class="grid gap-2">
					<FieldRadio
						id="rematch"
						v-model="formData.rematch"
						type="simple"
						:options="[
							{ value: 'true', label: $t('generic.rematch.yes') },
							{ value: 'false', label: $t('generic.rematch.no') },
						]"
						required
					/>
				</div>
			</template>

			<FieldLabel
				id="screenshot"
				class="mt-4"
				:label="$t('reportRelationView.ghosting.screenshot.label')"
			/>

			<FieldFileUploader
				id="screenshot"
				v-model:modelValue="formData.screenshot"
				class="mt-2"
				:locale="$i18n.locale"
				:validation="v$.screenshot"
				accept="audio/*, video/*, image/*, .pdf, .doc"
			/>

			<BaseButton
				class="mt-8 w-full"
				:disabled="v$.$invalid || v$.$error || isSending"
				:text="$t('reportRelationView.ghosting.submit')"
				size="md"
				@click="onSendReport"
			/>
		</div>
	</div>
</template>
