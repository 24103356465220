<script setup lang="ts">
// NPM
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMq } from 'vue3-mq';

// Components
import { BaseCard, BaseEmptyState } from '@magma-app/magma-lapilli';
import BaseRewardChallengeDetails from '@/components/bases/BaseRewardChallengeDetails.vue';

// Types
import type { IChallenge } from '@/types/IChallenge';

// Stores
import { useCommonStore } from '@/stores/common';
import { useChallengesStore } from '@/stores/challenges';
const commonStore = useCommonStore();
const challengesStore = useChallengesStore();

// Plugins
const { t } = useI18n({ useScope: 'global' });
const mq = useMq();

// Reactive variables
const completedChallengeDetails = ref<IChallenge | null>(null);

// Event Listeners
const onCloseCompletedChallengeDetails = () => {
	challengesStore.setCompletedChallengeDetails(false);
	completedChallengeDetails.value = null;
};

const onCompletedChallengeClick = (challenge: IChallenge) => {
	if (!mq.lgPlus) {
		challengesStore.setCompletedChallengeDetails(true);
		completedChallengeDetails.value = challenge;
		window.scrollTo(0, 0);
	} else if (mq.lgPlus) {
		commonStore.setManageChallengesRewardsModal({
			description: challenge.description,
			open: true,
			item: challenge,
			role: 'challenge-status',
			title: challenge.name,
		});
	}
};

// Methods
const badgeStatus = (status: string | undefined) => {
	if (status === 'waitingValidation') {
		return {
			color: 'yellow',
			text: t('generic.validation.pending'),
		};
	} else if (status === 'refusedByAdmin') {
		return {
			color: 'red',
			text: t('generic.validation.declined'),
		};
	} else {
		return {
			color: 'green',
			text: t('generic.validation.completed'),
		};
	}
};
</script>

<template>
	<div
		class="block px-4 md:px-8"
		:class="{
			'content-start lg:flex lg:flex-wrap':
				challengesStore.completedChallenges?.length,
			' px-4 md:px-6': !completedChallengeDetails,
		}"
	>
		<template v-if="challengesStore.completedChallenges?.length">
			<template v-if="!completedChallengeDetails">
				<BaseCard
					v-for="(
						challenge, challengeIndex
					) in challengesStore.completedChallenges"
					:key="challengeIndex"
					class="mb-2 lg:mb-6 lg:mr-6"
					:title="challenge.name"
					:img-url="mq.lgPlus ? '/img/challenge_lg.png' : '/img/challenge.png'"
					:points="challenge.points"
					:badge-status="badgeStatus(challenge.status)"
					@click="onCompletedChallengeClick(challenge)"
				/>
			</template>

			<BaseRewardChallengeDetails
				v-else
				:item="completedChallengeDetails"
				challenge
				completed
				@close="onCloseCompletedChallengeDetails"
			/>
		</template>

		<BaseEmptyState
			v-else
			class="flex h-full flex-col items-center justify-center"
			:text="$t('completedChallengesView.empty.completed')"
			type="empty-list"
		/>
	</div>
</template>
