<script setup lang="ts">
import { useHelpeeData } from '@/api/complete-helpee-signup/use-helpee-data';
import { useAuthStore } from '@/stores/auth';
import { useHelperSignupStore } from '@/stores/helper-signup';
import { hexToRgb } from '@/utils/color';
import { FieldInput, BaseIcon, BaseButton } from '@magma-app/magma-lapilli';
import { onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const emit = defineEmits(['back', 'resend', 'submit']);

const { t } = useI18n();
const helperSignUpStore = useHelperSignupStore();
const route = useRoute();
const { data } = useHelpeeData(
	route.params.campaignHelpeeUuid as string | undefined
);
const authStore = useAuthStore();

const timerBeforeResend = ref<number>(30);
const code = ref<string>('');
const error = ref<string | null>(null);

const handleCodeSubmit = async () => {
	const response = (await authStore.validateCode({
		helpeeId: data?.value?.signUpInformation?.helpeeId || 0,
		code: code.value,
	})) as any;

	if (response) {
		emit('submit');
	} else {
		error.value = "Le code n'est pas valide";
	}
};

const handleResendCode = () => {
	emit('resend');
	timerBeforeResend.value = 30;
};

watchEffect(() => {
	if (timerBeforeResend.value > 0) {
		setTimeout(() => {
			timerBeforeResend.value--;
		}, 1000);
	}
});

onMounted(() => {
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="px-5 lg:px-10 py-5 lg:py-7 flex flex-col flex-1 justify-between">
		<div>
			<h2 class="text-mgm-txt-xl font-medium">
				{{ t('helperOnboarding.verification.title') }}
			</h2>
			<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
				{{ t('helperOnboarding.verification.subtitle.text') }}(<button
					class="underline"
					@click="emit('back')"
				>
					{{ t('helperOnboarding.verification.subtitle.link') }}</button
				>)
			</h3>
			<FieldInput
				id="code"
				type="number"
				autocomplete="one-time-code"
				max-length="6"
				min-length="6"
				v-model="code"
				data-cy="code"
				class="mt-4"
				:locale="$i18n.locale"
				:label="$t('helpeeCampaignSignUpView.codeLabel')"
				required
			/>

			<p v-if="error" class="text-mgm-txt-xs text-border-danger-emphasis mt-3">
				{{ error }}
			</p>

			<div class="flex items-center flex-col mt-4">
				<p class="text-foreground-default text-mgm-txt-sm mb-1">
					{{ $t('helpeeCampaignSignUpView.noReceive') }}
				</p>
				<p
					v-if="timerBeforeResend > 0"
					class="text-foreground-default text-mgm-txt-sm mb-1 font-medium"
				>
					{{ $t('helpeeCampaignSignUpView.code.resend') }}
					{{ timerBeforeResend }}
					{{ $t('helpeeCampaignSignUpView.code.seconds') }}
				</p>
				<button
					v-else
					class="text-foreground-brand-default text-mgm-txt-sm flex items-center"
					@click="handleResendCode"
				>
					<BaseIcon
						icon="refresh-cw"
						class="mr-2 -scale-x-100"
						color="rgb(68 76 231)"
					/>
					<span class="underline">{{
						$t('helpeeCampaignSignUpView.resendCode')
					}}</span>
				</button>
			</div>
		</div>
		<BaseButton
			@click="handleCodeSubmit"
			size="lg"
			class="w-full mb-8 mt-3"
			:custom-color="data?.signUpInformation?.primaryColor"
			:custom-rgb-color="hexToRgb(data?.signUpInformation?.primaryColor || '')"
			:disabled="code.length !== 6"
		>
			Continue</BaseButton
		>
	</div>
</template>
