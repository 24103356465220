<script setup lang="ts">
import { useCommonStore } from '@/stores/common';
import type { ITouchPoint } from '@/types/ITouchPoint';
import { BaseButton, BaseBadge, BaseAvatar } from '@magma-app/magma-lapilli';
import { useRelationsStore } from '@/stores/relations';
import { useRoute, useRouter } from 'vue-router';
import { ref, watchEffect } from 'vue';
import { useUserStore } from '@/stores/user';

const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

const route = useRoute();

const isInitialized = ref(false);

const handleTouchPointClick = (touchPoints: ITouchPoint[]) => {
	const moment = relationsStore.relation?.relation?.relationMoments[0];

	if (!moment) return;

	commonStore.setTouchpointsModal({
		moment: moment,
		name: relationsStore.relation?.relation.helper.firstname,
		open: true,
		touchpoints: touchPoints,
	});
};

watchEffect(async () => {
	isInitialized.value = false;

	await relationsStore.getRelation({
		relationId: parseInt(route?.params.relationId as string),
		type: 'helpee',
	});

	isInitialized.value = true;
});
</script>

<template>
	<div
		v-if="isInitialized"
		class="w-full h-[calc(100vh_-_64px)] bg-background-subtle-hover flex justify-center sm:items-center flex-col"
	>
		<div
			class="w-full sm:rounded-xl sm:border border-border-subtle sm:w-[390px] h-dvh sm:h-auto bg-white sm:overflow-hidden sm:shadow-lg flex flex-col relative"
		>
			<header class="w-full h-16 flex items-center justify-between px-5">
				<div class="flex items-center gap-2">
					<img
						:src="userStore.campaignInfo?.logoUrl"
						class="w-8 h-8 rounded-full"
					/>
					<p
						class="text-mgm-txt-sm text-foreground-emphasis font-medium truncate"
					>
						{{ userStore.campaignInfo?.organizationName }}
					</p>
				</div>
				<img
					:src="userStore.user?.profilePictureUrl"
					class="w-8 h-8 rounded-full"
				/>
			</header>
			<div
				v-if="
					relationsStore.relation?.relation.helper.hasCustomPicture &&
					relationsStore.relation?.relation.helper.profilePictureUrlMedium
				"
				class="w-full bg-background-brand-default flex items-center justify-center"
			>
				<img
					:src="
						relationsStore.relation?.relation.helper.profilePictureUrlMedium
					"
					class="w-full object-cover h-80"
				/>
			</div>

			<div
				v-else
				class="w-full h-44 sm:h-40 bg-background-brand-default flex items-center justify-center"
			>
				<img
					:src="relationsStore.relation?.relation.helper.profilePictureUrl"
					class="w-16 h-16 rounded-full border border-border-default shadow-lg -mt-3 sm:mt-0"
				/>
			</div>

			<div
				class="rounded-t-2xl bg-white pt-6 pb-20 px-5 overflow-y-auto z-10 max-h-[53%] flex-1 bottom-0 -mt-3 w-full"
			>
				<h1 class="text-mgm-txt-xl font-medium text-foreground-emphasis mb-3">
					{{ $t('helpeeStartRelation.meet') }}
					{{ relationsStore.relation?.relation.helper.firstname }}
				</h1>
				<section
					v-for="criteria in relationsStore.relation?.relation.helper.criteria"
					class="mb-3"
				>
					<p class="text-mgm-txt-sm text-foreground-default">
						{{ criteria.labelHelper }}
					</p>
					<p class="text-mgm-txt-sm text-foreground-emphasis font-medium">
						{{ criteria.value }}
					</p>
				</section>

				<div
					v-if="relationsStore.relation?.relation.helper?.touchPoints?.length"
					class="mb-6"
				>
					<p class="text-mgm-txt-sm text-foreground-default">
						{{ $t('helpeeStartRelation.touchPoints') }}
					</p>
					<div class="flex flex-wrap">
						<BaseBadge
							v-for="touchpoint in relationsStore.relation?.relation.helper
								.touchPoints"
							:key="touchpoint.name"
							:text="touchpoint.name"
							:class="[
								'mr-2 mt-2 last:mr-0',
								relationsStore.relation?.isReported
									? 'cursor-default'
									: 'cursor-pointer',
							]"
							size="lg"
							color="grey"
							icon-type="custom"
							@click="
								handleTouchPointClick(
									relationsStore.relation?.relation.helper.touchPoints
								)
							"
						>
							<template #icon>
								<BaseAvatar :src="touchpoint.icon" size="xs" shape="squared" />
							</template>
						</BaseBadge>
					</div>
				</div>

				<div
					v-if="relationsStore.relation?.relation.helper?.touchPoints?.length"
					class="pt-3 pb-10 fixed sm:static bottom-0 left-0 w-full bg-white px-4 sm:px-0"
				>
					<BaseButton
						class="w-full mt-2"
						state="prmr"
						@click="
							handleTouchPointClick(
								relationsStore.relation?.relation.helper.touchPoints
							)
						"
					>
						{{ $t('helpeeStartRelation.send') }}
					</BaseButton>
				</div>
			</div>
		</div>
		<div class="hidden sm:block">
			<a
				class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-border-default hover:text-foreground-subtle focus:text-foreground-subtle flex gap-2 items-center"
				href="https://www.magma.app/"
				target="_blank"
			>
				{{ $t('generic.powered') }}
				<img src="/img/logo_magma_full_bw.svg" alt="Magma" />
			</a>
		</div>
	</div>
</template>
