<script setup lang="ts">
// NPM
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMq } from 'vue3-mq';

// Components
import {
	BaseAvatar,
	BaseBadge,
	BaseIcon,
	TextAccordion,
} from '@magma-app/magma-lapilli';
import BaseRelationMoment from '@/components/bases/BaseRelationMoment.vue';
import BaseRelationMomentDetails from '@/components/bases/BaseRelationMomentDetails.vue';

// Types
import type { IRelationMoment } from '@/types/IRelationMoment';
import type { ITouchPoint } from '@/types/ITouchPoint';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Composables & Stores
import { useDeterminePunctuation } from '@/composables/useDeterminePunctuation';
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
const { determinePunctuation } = useDeterminePunctuation();
const relationsStore = useRelationsStore();
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const mq = useMq();

// Reactive variables
const currentMoment = ref<IRelationMoment | null>(null);
const currentWindow = ref<'moments' | 'moment-details'>('moments');
const initialized = ref(false);

// Event listeners
const onTouchPointClick = (touchPoint: ITouchPoint) => {
	if (!relationsStore.relation?.isReported) {
		if (['line', 'phone'].includes(touchPoint.name)) {
			commonStore.setCallTextModal({
				helpeeName: relationsStore.relation?.relation?.helpee?.firstname,
				helperName: relationsStore.relation?.relation?.helper?.firstname,
				open: true,
				touchpoint: touchPoint.value,
			});
		}

		if (touchPoint.name === 'whatsapp') {
			const phoneNumber = touchPoint.value.replace(/\+/g, '');
			window.open(
				`https://api.whatsapp.com/send?phone=${phoneNumber}`,
				'_blank'
			);
		}

		if (touchPoint.name === 'mail') {
			window.open(`mailto:${touchPoint.value}`, '_blank');
		}

		if (touchPoint.type === 'pseudo') {
			window.open(touchPoint.value, '_blank');
		}

		AnalyticsHelper.trackEvent('send_message', {
			event_properties: 'touchpoints',
			user_properties: 'helper',
		});
	}
};

const onGoBackToRelations = () => {
	router.push({
		name: 'relations',
	});
};

// Watchers
watchEffect(async () => {
	currentWindow.value = 'moments';
	initialized.value = false;

	if (route?.params.relationId) {
		await relationsStore.getRelation({
			relationId: parseInt(route?.params.relationId as string),
			type: 'helper',
		});

		await userStore.getCampaignInfo(
			parseInt(route?.params.relationId as string)
		);
	}

	if (
		route?.query.helper === 'contact' &&
		!relationsStore.relation?.hasBeenInContact
	) {
		router.push({
			name: 'start-relation-helper',
			params: { relationId: route.params.relationId },
		});

		return;
	}

	if (route?.query.helper === 'read') {
		const moment = relationsStore.relation?.relation?.relationMoments.find(
			(moment) => {
				return (
					moment.relationMomentId === parseInt(route?.query.moment as string)
				);
			}
		);

		if (moment) {
			currentMoment.value = moment;
			currentWindow.value = 'moment-details';
		}
	}

	if (route?.query.helper === 'ongoing') {
		const moment = relationsStore.relation?.relation?.relationMoments.find(
			(moment) => {
				return (
					moment.relationMomentId === parseInt(route?.query.moment as string)
				);
			}
		);

		if (
			moment &&
			moment.helper?.isOngoing === false &&
			(moment.helper?.isValidated === false || !moment.helper?.isValidated)
		) {
			await relationsStore.markRelationMomentAs({
				relationMomentId: parseInt(route?.query.moment as string),
				role: 'ongoing',
				type: 'helper',
			});

			await relationsStore.getRelation({
				relationId: parseInt(route?.params.relationId as string),
				type: 'helper',
			});
		}

		commonStore.setManageRelationModal({
			closeCta: t('generic.getIt'),
			description:
				t('helperRelationOverview.ongoingModal.description.partOne') +
				relationsStore.relation?.relation?.helpee?.firstname +
				t('helperRelationOverview.ongoingModal.description.partTwo'),
			open: true,
			role: 'in-contact',
			title: t('generic.thanksUpdate'),
		});
	}

	if (route?.query.helper === 'done') {
		commonStore.setManageRelationModal({
			description:
				t('generic.doneModalDescription') +
				relationsStore.relation?.relation?.helpee?.firstname +
				t('generic.doneModalDescriptionBis'),
			open: true,
			title: t('generic.doneModalTitle'),
			relationMomentId: parseInt(route?.query.moment as string),
			role: 'done-without-feedback',
			type: 'helper',
		});
	}

	if (route?.query.helper === 'feedback') {
		commonStore.setManageRelationModal({
			open: true,
			type: 'helper',
			role: 'done-with-feedback',
			feedbackType: 'full',
			relationMomentId: parseInt(route?.query.moment as string),
			title: t('generic.doneModalTitle'),
			description: `${t('generic.feedbackModalDescription')}${relationsStore
				.relation?.helpee?.firstname}.`,
			helpeeName: relationsStore.relation?.relation?.helpee?.firstname,
		});
	}

	if (route?.query.helper === 'admin') {
		commonStore.setAdminModal({
			relationMomentId: parseInt(route?.query.moment as string),
			open: true,
		});

		if (mq.smMinus) {
			document.body.classList.add('overflow-hidden');
		}
	}

	initialized.value = true;
});

// Event Listeners
const onDisplayMomentDetails = (momentDetails: IRelationMoment) => {
	currentMoment.value = momentDetails;
	currentWindow.value = 'moment-details';

	AnalyticsHelper.trackEvent('read_more', {
		user_properties: 'helper',
	});
};

const onHideMomentDetails = () => {
	currentWindow.value = 'moments';
};

const onMarkAsDone = async (relationMoment: IRelationMoment) => {
	let modalText: {
		title: string;
		description: string;
		form: null | {
			rating: {
				label: string;
				negative: string;
			};
			comment: {
				label: string;
				placeholder: string;
			};
		};
	} = {
		title: t('generic.doneModalTitle'),
		description:
			t('generic.doneModalDescription') +
			relationsStore.relation?.relation?.helpee?.firstname +
			t('generic.doneModalDescriptionBis'),
		form: null,
	};

	if (relationMoment.isLast) {
		modalText.description = `${t(
			'generic.feedbackModalDescription'
		)}${relationsStore.relation?.relation?.helpee?.firstname}.`;
	} else if (relationMoment.helper?.needFeedback && !relationMoment.isLast) {
		modalText = {
			title: t('helperRelationOverview.modal.helperNeedFeedback.title'),
			description: `${t(
				'helperRelationOverview.modal.helperNeedFeedback.description.partOne'
			)}${relationsStore.relation?.relation?.helpee?.firstname}${t(
				'helperRelationOverview.modal.helperNeedFeedback.description.partTwo'
			)}`,
			form: {
				rating: {
					label: t('helperRelationOverview.modal.helperNeedFeedback.question'),
					negative: t('generic.doubtful'),
				},
				comment: {
					label: t('generic.comments'),
					placeholder: t(
						'helperRelationOverview.modal.helperNeedFeedback.placeholder'
					),
				},
			},
		};
	}

	commonStore.setManageRelationModal({
		open: true,
		type: 'helper',
		role: relationMoment.helper?.needFeedback
			? 'done-with-feedback'
			: 'done-without-feedback',
		feedbackType: 'full',
		relationMomentId: relationMoment.relationMomentId,
		title: modalText.title,
		description: modalText.description,
		formText: modalText.form,
		icon:
			relationMoment.helper?.needFeedback && !relationMoment.isLast
				? true
				: false,
	});

	AnalyticsHelper.trackEvent('validate_moment', {
		event_properties: 'timeline',
		user_properties: 'helper',
	});
};

const onReport = () => {
	commonStore.setReportModal({
		open: true,
		role: 'report',
		type: 'helper',
		title: t('generic.reportTitle'),
		description: t('generic.reportDescription'),
		helpeeName: relationsStore.relation?.relation?.helpee?.firstname,
	});
};
</script>

<template>
	<div
		v-if="initialized"
		class="flex h-full w-full flex-col"
		data-cy="helper-relation"
	>
		<div
			:class="{
				'border-b border-border-subtle': currentWindow === 'moments',
				'lg:border-b lg:border-border-subtle':
					currentWindow === 'moment-details',
			}"
			class="mb-2 p-4 md:p-8"
		>
			<div class="flex items-center justify-between">
				<svg
					class="lg:hidden"
					width="8"
					height="14"
					viewBox="0 0 8 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					@click="onGoBackToRelations"
				>
					<path
						d="M7 13L1 7L7 1"
						stroke="#5F6C85"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<span
					class="flex-1 text-center text-mgm-txt-lg font-medium text-foreground-emphasis lg:text-left"
				>
					{{ relationsStore.relation?.relation?.helpee?.firstname }}
					{{ relationsStore.relation?.relation?.helpee?.lastname }}
				</span>

				<BaseIcon
					v-if="!relationsStore.relation?.isReported"
					:size="24"
					color="#5F6C85"
					icon="flag"
					hover-state
					@click="onReport"
				/>
			</div>

			<div
				:class="{
					flex: currentWindow === 'moments',
					'hidden lg:flex': currentWindow === 'moment-details',
				}"
				class="mb-2 mt-3 justify-between"
			>
				<BaseAvatar
					:src="relationsStore.relation?.relation?.helpee?.profilePictureUrl"
					:fallback="
						(relationsStore.relation?.relation?.helpee?.firstname || '').charAt(
							0
						) +
						(relationsStore.relation?.relation?.helpee?.lastname || '').charAt(
							0
						)
					"
					size="xl"
				/>

				<div
					v-if="relationsStore.relation?.relation?.helpee?.criteria?.length"
					class="ml-4 flex-1 text-mgm-txt-sm font-normal text-foreground-default"
				>
					<p
						v-for="criterium of relationsStore.relation.relation.helpee
							.criteria"
						:key="criterium.value"
						class="mb-1 last:mb-0"
					>
						{{
							`${determinePunctuation(
								criterium.labelHelper || criterium.labelHelpee || '',
								':',
								['?']
							)}`
						}}
						<span class="font-medium">{{ criterium.value }}</span>
					</p>
				</div>
			</div>

			<div
				v-if="relationsStore.relation?.relation?.helpee?.touchPoints?.length"
				:class="[
					'flex-wrap',
					{
						flex: currentWindow === 'moments',
						'hidden lg:flex': currentWindow === 'moment-details',
					},
				]"
			>
				<BaseBadge
					v-for="touchPoint of relationsStore.relation?.relation?.helpee
						?.touchPoints"
					:key="touchPoint.name"
					:text="touchPoint.name"
					:class="[
						'mr-2 mt-2 last:mr-0',
						relationsStore.relation?.isReported
							? 'cursor-default'
							: 'cursor-pointer',
					]"
					size="lg"
					color="grey"
					icon-type="custom"
					@click="onTouchPointClick(touchPoint)"
				>
					<template #icon>
						<BaseAvatar :src="touchPoint.icon" size="xs" shape="squared" />
					</template>
				</BaseBadge>
			</div>

			<div v-if="relationsStore.relation?.relation.helpee.expectation !== ''">
				<TextAccordion
					class="mt-4 bg-background-subtle rounded p-3"
					:content="
						relationsStore.relation?.relation.helpee.expectation
							? relationsStore.relation?.relation.helpee.expectation
							: ''
					"
					:seeMoreButton="$t('generic.seeMore')"
					:seeLessButton="$t('generic.seeLess')"
					title="Qualification :"
				/>
			</div>
		</div>

		<div
			v-if="currentWindow === 'moments'"
			:class="[
				'px-4 pb-6 pt-4 md:mb-8 md:px-6',
				{ 'md:mt-6': !relationsStore.relation?.isReported },
			]"
		>
			<div
				v-if="
					(relationsStore.relation?.isReported &&
						relationsStore.relation?.report?.reportedAt) ||
					relationsStore.relations.hasMultipleCampaigns
				"
				class="mb-4 flex w-fit items-start rounded-[5px] bg-background-subtle px-3 py-2 lg:w-fit"
			>
				<BaseIcon class="mr-2 mt-px" icon="info" />

				<p class="text-mgm-txt-sm font-medium text-foreground-emphasis">
					{{
						relationsStore.relation?.isReported &&
						relationsStore.relation?.report?.reportedAt
							? `${$t('helperRelationOverview.info.partOne')} ${new Date(
									relationsStore.relation.report.reportedAt
								).toLocaleDateString($i18n.locale, {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
								})}, ${$t('helperRelationOverview.info.partTwo')}`
							: userStore.campaigns.find(
									(campaign) =>
										campaign.id === relationsStore.relation?.campaignId
								)?.title
					}}
				</p>
			</div>

			<BaseRelationMoment
				v-for="relationMoment of relationsStore.relation?.relation
					?.relationMoments"
				:key="relationMoment.relationMomentId"
				:relation-moment="relationMoment"
				@display="onDisplayMomentDetails(relationMoment)"
				@click="onMarkAsDone(relationMoment)"
			/>
		</div>

		<BaseRelationMomentDetails
			v-else-if="currentWindow === 'moment-details' && currentMoment"
			:moment="currentMoment"
			@close="onHideMomentDetails"
		/>
	</div>
</template>
