<script setup lang="ts">
// NPM
import { onBeforeMount, nextTick, reactive, ref } from "vue";
import { cloneDeep } from "lodash";

// Components
import { BaseIcon, BaseLoader, FieldSelect } from "@magma-app/magma-lapilli";

// Stores
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();

// Reactive variables
const initialized = ref(false);
const saved = ref(false);

const formData = reactive({
  criteria: {} as { [key: string]: string | string[] },
});
const formDataSaved = reactive(cloneDeep(formData));

// Lifecycle hooks
onBeforeMount(async () => {
  await userStore.getUserCriteria();

  if (userStore.userCriteria) {
    formData.criteria = userStore.userCriteria?.reduce(
      (accumulator: { [key: string]: string | string[] }, criteria) => {
        if (criteria.type === "singleSelect") {
          accumulator[criteria.id] = criteria.userAnswerKey || "";
        } else {
          accumulator[criteria.id] = criteria.userAnswerKeys || [];
        }
        return accumulator;
      },
      {}
    ) as { [key: string]: string | string[] };
  }

  initialized.value = true;
});

// Methods
const changeCriteria = async (
  criteriaId: number,
  criteriaValue: { [key: string]: string } | string
) => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  // Make sure the value is not empty and value has changed
  if (
    JSON.stringify(formData.criteria) !== JSON.stringify(formDataSaved.criteria)
  ) {
    try {
      const objectKey =
        typeof criteriaValue === "object" ? "answerKeys" : "answerKey";

      if (objectKey != "answerKeys" || criteriaValue?.length != 0) {
        await userStore.updateUserCriteria({
          criteriaId,
          [objectKey]: criteriaValue,
        });

        // Update formDataSaved to the new value
        formDataSaved.criteria[criteriaId] = (
          typeof criteriaValue === "object"
            ? cloneDeep(criteriaValue)
            : criteriaValue
        ) as string | string[];

        saved.value = true;

        setTimeout(() => (saved.value = false), 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const getCriteriaOptions = (id: number) => {
  let campaignsCriteria = undefined;

  if (userStore.userCriteria) {
    campaignsCriteria = userStore.userCriteria.find(
      (criteria) => criteria.id === id
    );
  }

  if (campaignsCriteria?.options) {
    return campaignsCriteria.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};
</script>

<template>
  <template v-if="initialized">
    <h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
      {{ $t("settingsMatchingDetailsView.setMatching") }}
    </h2>

    <p class="mb-5 mt-4 text-sm text-foreground-emphasis">
      {{ $t("settingsMatchingDetailsView.matchingDescription") }}
    </p>

    <FieldSelect
      v-for="campaignsCriteriaItem in userStore.userCriteria"
      :id="campaignsCriteriaItem.id"
      :key="campaignsCriteriaItem.id"
      v-model="formData.criteria[campaignsCriteriaItem.id]"
      class="mb-5"
      :label="campaignsCriteriaItem.label"
      :options="getCriteriaOptions(campaignsCriteriaItem.id)"
      :multiple="campaignsCriteriaItem.type === 'multiSelect'"
      @change="changeCriteria"
    />

    <div
      v-if="saved"
      class="sticky bottom-0 flex w-full justify-end bg-background-default px-2 text-sm text-foreground-default"
    >
      saved
      <BaseIcon class="ml-0.5 mb-1" icon="check" :size="12" color="#5F6C85" />
    </div>
  </template>

  <BaseLoader v-else class="min-h-screen" />
</template>
