<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// Components
import {
  BaseButton,
  BaseIcon,
  FieldCheckbox,
  FieldInput,
  FieldPhoneNumber,
  FieldRadio,
} from "@magma-app/magma-lapilli";
import TheSidebar from "@/components/shell/TheSidebar.vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useCommonStore } from "@/stores/common";
import { useRelationsStore } from "@/stores/relations";
import { useUserStore } from "@/stores/user";
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const currentCampaign = ref<
  { id: number; title: string; referFormEnabled: boolean } | undefined
>(undefined);
const disableBtn = ref(false);
const duplicateHelpeeMsg = ref<string | undefined>(undefined);
const multiCampaigns = ref(false);
const multiCampaignView = ref(false);

const formData = reactive({
  campaign: undefined,
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: "",
  health: undefined,
  consent: undefined,
});

// Form validation
const v$ = useVuelidate(
  {
    firstname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    lastname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    email: {
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    phoneNumber: {
      validPhoneNumber: withI18nMessage(
        (value: string) => {
          if (value != "") {
            return isValidPhoneNumber(value);
          } else {
            return true;
          }
        },
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    },
    consent: {
      required: withI18nMessage((value: boolean) => value === true, {
        messagePath: () => t("validation.value"),
      }),
    },
    health: {
      required: withI18nMessage((value: boolean) => value === true, {
        messagePath: () => t("validation.value"),
      }),
    },
  },
  formData
);

// Computed
const availableCampaigns = computed(() => {
  return (
    userStore.campaigns
      .filter((campaign) => campaign.referFormEnabled)
      .map((campaign) => ({
        value: `${campaign.id}`,
        label: campaign.title,
      })) || []
  );
});

// Watchers
watch(
  () => formData.firstname,
  async () => await v$.value.firstname.$validate()
);

watch(
  () => formData.lastname,
  async () => await v$.value.lastname.$validate()
);

watch(
  () => formData.email,
  async () => {
    await v$.value.email.$validate();

    if (disableBtn.value) {
      duplicateHelpeeMsg.value = undefined;
      disableBtn.value = false;
    }
  }
);

watch(
  () => formData.phoneNumber,
  async () => await v$.value.phoneNumber.$validate()
);

watch(
  () => formData.health,
  async () => await v$.value.health.$validate()
);

watch(
  () => formData.consent,
  async () => await v$.value.consent.$validate()
);

// Lifecycle hooks
onBeforeMount(() => {
  if (userStore.campaigns?.length > 1) {
    const referFormEnabledCampaigns = userStore.campaigns.filter(
      (campaign) => campaign.referFormEnabled
    );

    if (referFormEnabledCampaigns?.length > 1) {
      multiCampaignView.value = true;
      multiCampaigns.value = true;
    } else {
      currentCampaign.value = referFormEnabledCampaigns[0];
    }
  } else {
    currentCampaign.value = userStore.campaigns[0];
  }
});

// Methods
const onPickCampaign = () => {
  currentCampaign.value = userStore.campaigns.find(
    (campaign) => campaign.id == formData.campaign
  );

  multiCampaignView.value = false;
};

const onReferHelpee = async () => {
  v$.value.$touch();

  if ((await v$.value.$validate()) && currentCampaign.value) {
    try {
      disableBtn.value = true;
      const payload = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        campaignId: currentCampaign.value.id,
        phoneNumber: formData.phoneNumber,
      };

      await relationsStore.referHelpee(payload);

      commonStore.setManageRelationModal({
        description: `${t("helperReferralView.next.partOne")} ${
          formData.firstname
        } ${t("helperReferralView.next.partTwo")}`,
        open: true,
        role: "referral",
        title: t("helperReferralView.thanks"),
      });

      AnalyticsHelper.trackEvent("refer_someone", {
        user_properties: "helper",
      });

      disableBtn.value = false;
    } catch (error: any) {
      if (error.response.data.message.includes("Helpee already exists")) {
        disableBtn.value = true;
        duplicateHelpeeMsg.value = t("generic.duplicateHelpee");
      } else {
        console.error(error);
      }
    }
  }
};
</script>

<template>
  <div class="relative flex bg-background-subtle">
    <TheSidebar />

    <div
      class="flex w-full flex-1 flex-col bg-background-default px-4 pb-9 pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:px-8 lg:pt-8 2xl:ml-64"
    >
      <div class="lg:max-w-3xl">
        <div class="flex items-center justify-start">
          <BaseIcon
            v-if="multiCampaigns && !multiCampaignView"
            class="mr-2 cursor-pointer"
            color="#5F6C85"
            icon="chevron-left"
            @click="multiCampaignView = true"
          />

          <h2 class="text-mgm-dspl-xs font-medium text-foreground-emphasis">
            {{ $t("generic.referCta") }}
          </h2>
        </div>

        <template v-if="multiCampaignView">
          <p class="mt-6 text-mgm-txt-sm font-medium text-foreground-emphasis">
            {{ $t("helperReferralView.choose") }}
          </p>

          <div class="mt-2 grid grid-cols-1 gap-2 lg:grid-cols-2">
            <FieldRadio
              id="redirectionType"
              v-model="formData.campaign"
              type="simple"
              :options="availableCampaigns"
              @update:model-value="onPickCampaign"
            />
          </div>
        </template>

        <template v-else>
          <div
            class="mt-2 flex w-full items-start rounded-[5px] bg-background-subtle px-3 py-2 lg:w-fit"
          >
            <BaseIcon class="mr-2 mt-px" icon="info" />

            <p class="text-mgm-txt-sm font-medium text-foreground-emphasis">
              {{ `${$t("helperReferralView.info")} ${currentCampaign?.title}` }}
            </p>
          </div>

          <div class="grid-cols-2 gap-x-6 lg:grid">
            <FieldInput
              id="firstname"
              v-model="formData.firstname"
              class="mt-4"
              :locale="$i18n.locale"
              :label="$t('generic.firstName')"
              placeholder="John"
              required
              :validation="v$.firstname"
            />

            <FieldInput
              id="lastname"
              v-model="formData.lastname"
              class="mt-4"
              :locale="$i18n.locale"
              :label="$t('generic.lastName')"
              placeholder="Doe"
              required
              :validation="v$.lastname"
            />

            <FieldInput
              id="email"
              v-model="formData.email"
              class="mt-4"
              role="email"
              type="email"
              :locale="$i18n.locale"
              :label="$t('generic.email')"
              :placeholder="`${$t('generic.example')}@email.com`"
              autocomplete="email"
              required
              :validation="v$.email"
              :error-message="duplicateHelpeeMsg"
            />

            <FieldPhoneNumber
              id="phone"
              v-model="formData.phoneNumber"
              class="mt-4"
              :locale="$i18n.locale"
              :label="$t('generic.phoneNumber')"
              :validation="v$.phoneNumber"
            />
          </div>

          <FieldCheckbox
            id="health"
            v-model="formData.health"
            class="mt-6"
            :validation="v$.health"
            required
          >
            {{ $t("helperReferralView.health") }}
          </FieldCheckbox>

          <FieldCheckbox
            id="consent"
            v-model="formData.consent"
            class="mt-6"
            :validation="v$.consent"
            required
          >
            {{ $t("helperReferralView.consent") }}
          </FieldCheckbox>

          <BaseButton
            class="mx-auto mt-8 w-full lg:w-auto"
            :disabled="v$.$invalid || v$.$error || disableBtn"
            :text="$t('helperReferralView.invite')"
            @click="onReferHelpee"
          />
        </template>
      </div>
    </div>
  </div>
</template>
