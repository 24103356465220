<script setup lang="ts">
// NPM
import { computed, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';

// Components
import { BaseButton, BaseIcon } from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });

// Props
const props = defineProps({
	description: {
		type: String,
		default: null,
	},
	helpeeName: {
		type: String,
		default: null,
	},
	helperName: {
		type: String,
		default: null,
	},
	open: {
		type: Boolean,
		default: false,
	},
	role: {
		type: String as PropType<'report' | 'reported'>,
		default: 'report',
	},
	title: {
		type: String,
		default: null,
	},
	type: {
		type: String as PropType<'helpee' | 'helper'>,
		default: 'helper',
	},
});

// Reactive variables
const root = ref<HTMLInputElement>();

const formData = reactive({
	askRematch: true,
	reportComment: '',
	reportReason: '',
});

// Form validation
const v$ = useVuelidate(
	{
		reportReason: {
			requiredIf: requiredIf(() => props.role === 'report'),
		},
	},
	formData
);

// Computed
const reportReasons = computed(() => {
	return [
		{
			label: `${
				props.type === 'helper' ? props.helpeeName : props.helperName
			} ${t('reportModal.ghosting.title')}`,
			description: t('reportModal.ghosting.description'),
			value: 'ghosting',
		},
		{
			label:
				props.type === 'helper'
					? `${props.helpeeName} ${t('reportModal.uninterested.title')}`
					: t('generic.notInterested'),
			description:
				props.type === 'helper'
					? t('reportModal.uninterested.description')
					: t('reportModal.uninterested.helpeeDescription'),
			value: 'no-interest',
		},
		{
			label: t('generic.other'),
			description: t('reportModal.other.description'),
			value: 'other',
		},
	];
});

// Watchers
watch(formData, async () => {
	v$.value.reportReason.$touch();
	await v$.value.reportReason.$validate();
});

// Methods
const onCloseModal = () => {
	commonStore.closeReportModal();
};

const onReport = (reason: string) => {
	const relationId =
		props.type === 'helper'
			? route?.params.relationId
			: relationsStore.relation?.relation?.relationMoments?.find(
					(item) => item.isFirst === true
				)?.relationId;

	if (relationId) {
		router.push({
			name: 'report-relation-view',
			params: {
				reason: reason,
				relationId: relationId,
				userType: props.type,
			},
		});
	}

	commonStore.closeReportModal();
};
</script>

<template>
	<TransitionRoot as="template" :show="open" data-cy="modal">
		<Dialog
			:initial-focus="root"
			as="div"
			class="relative z-10"
			@close="onCloseModal"
		>
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
				></div>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div ref="root" class="flex min-h-full items-center justify-center">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							class="mx-4 max-w-sm transform overflow-hidden rounded-[15px] bg-background-default p-6 shadow-xl transition-all md:mx-auto"
						>
							<div class="mb-4 flex items-start justify-between">
								<BaseIcon
									:class="[
										'max-w-fit border-8',
										role === 'report'
											? 'border-background-warning-default'
											: 'border-background-success-default',
									]"
									:bg-color="role === 'report' ? '#FEF0C7' : '#D1FADF'"
									:color="role === 'report' ? '#B25504' : '#03874C'"
									:icon="role === 'report' ? 'flag' : 'check'"
									:size="24"
								/>

								<button
									type="button"
									class="rounded-md bg-background-default text-foreground-subtle hover:text-border-subtle focus:outline-none focus:ring-2 focus:ring-border-default focus:ring-offset-2"
									@click="onCloseModal"
								>
									<BaseIcon icon="close" color="#5F6C85" />
								</button>
							</div>

							<h3
								class="mb-2 text-mgm-txt-lg font-semibold text-foreground-emphasis"
							>
								{{ title }}
							</h3>

							<p
								class="scrollbar-hide max-h-44 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
								v-html="description"
							></p>

							<template v-if="role === 'report'">
								<div
									v-for="(reportReason, reportReasonIndex) in reportReasons"
									:key="reportReasonIndex"
									:class="[
										'flex cursor-pointer items-center justify-between rounded-[5px] border border-border-subtle p-4 text-mgm-txt-sm shadow-[0px_1px_2px_0px_rgba(28,31,39,0.05)]',
										reportReasonIndex === 0 ? 'mt-4' : 'mt-2',
									]"
									@click="onReport(reportReason.value)"
								>
									<div>
										<h4 class="font-medium text-foreground-emphasis">
											{{ reportReason.label }}
										</h4>

										<p class="font-normal text-foreground-default">
											{{ reportReason.description }}
										</p>
									</div>

									<BaseIcon class="ml-2" color="#5F6C85" icon="chevron-right" />
								</div>
							</template>

							<BaseButton
								v-if="role === 'reported'"
								class="mt-6 w-full"
								:text="$t('reportModal.ghosting.reported.cta')"
								data-cy="btn-close-modal"
								state="prmr"
								@click="onCloseModal"
							/>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
