<script setup lang="ts">
// NPM
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';

// Components
import {
	BaseAvatar,
	BaseBadge,
	BaseButton,
	BaseIcon,
} from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';
import type { IChallenge } from '@/types/IChallenge';
import type { IReward } from '@/types/IReward';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Stores
import { useChallengesStore } from '@/stores/challenges';
import { useCommonStore } from '@/stores/common';
import { useRewardsStore } from '@/stores/rewards';
import { useUserStore } from '@/stores/user';
const challengesStore = useChallengesStore();
const commonStore = useCommonStore();
const rewardsStore = useRewardsStore();
const userStore = useUserStore();

// Plugins
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });

// Props
const props = defineProps({
	description: {
		type: String,
		default: null,
	},
	closeCta: {
		type: String,
		default: null,
	},
	open: {
		type: Boolean,
		default: false,
	},
	item: {
		type: Object as PropType<{
			description: string;
			name: string;
			pictureUrl: string;
			points: number;
			retrievalInstruction?: string;
			status?: string;
		}>,
		default: null,
	},
	role: {
		type: String as PropType<
			| 'challenge'
			| 'challenge-awaiting-validation'
			| 'challenge-completed'
			| 'challenge-status'
			| 'reward'
			| 'reward-purchase'
			| 'reward-purchase-successful'
			| 'reward-purchased'
		>,
		default: null,
	},
	title: {
		type: String,
		default: null,
	},
});

// Reactive variables
const root = ref<HTMLInputElement>();
const isSaving = ref(false);

// Computed
const challengeStatus = computed(() => {
	switch (props.item.status) {
		case 'waitingValidation':
			return {
				color: 'yellow',
				text: t('generic.validation.pending'),
			};
		case 'refusedByAdmin':
			return {
				color: 'red',
				text: t('generic.validation.declined'),
			};
		default:
			return {
				color: 'green',
				text: t('generic.validation.completed'),
			};
	}
});

const rewardRetrievalSteps = computed(() => {
	return [
		{
			details: t('generic.requestedDetails'),
			isFirst: true,
			step: t('generic.requested'),
			status: {
				icon: 'check',
				color: '#03874C',
				bgColor: '#ECFDF3',
			},
		},
		{
			details: t('generic.retrieve') + ' ' + props.item.retrievalInstruction,
			isCurrent: true,
			step: t('generic.awaiting'),
			status: {
				icon: props.item.status === 'distributed' ? 'check' : 'clock',
				color: props.item.status === 'distributed' ? '#03874C' : '#444CE7',
				bgColor: props.item.status === 'distributed' ? '#ECFDF3' : '#EEF4FF',
			},
		},
		{
			details: t('generic.received'),
			isLast: true,
			step: t('generic.distributed'),
			status: {
				icon: props.item.status === 'distributed' ? 'check' : 'clock',
				color: props.item.status === 'distributed' ? '#03874C' : '#94A3B8',
				bgColor: props.item.status === 'distributed' ? '#ECFDF3' : '#F8FAFC',
			},
		},
	];
});

// Methods
const onCloseModal = async () => {
	commonStore.closeManageChallengesRewardsModal();

	if (
		['challenge-awaiting-validation', 'challenge-completed'].includes(
			props.role
		)
	) {
		router.push({ name: 'challenges' });

		challengesStore.setChallengeDetails(false);
		challengesStore.setCompletedChallengeDetails(false);
	}
};

const onContactAdmin = async () => {
	window.open(
		`mailto:${userStore.user?.organization?.notificationEmail}`,
		'_blank'
	);

	if (props.role === 'reward-purchased') {
		AnalyticsHelper.trackEvent('ask_support', {
			event_properties: 'reward_requested',
			user_properties: 'helper',
		});
	} else if (
		props.role === 'challenge-status' &&
		(props.item.status === 'waitingValidation' ||
			props.item.status === 'refusedByAdmin')
	) {
		AnalyticsHelper.trackEvent('ask_support', {
			event_properties: 'challenge',
			user_properties: 'helper',
		});
	}
};

const onFinalizePurchaseReward = async (reward: IReward) => {
	isSaving.value = true;
	if (reward.id) {
		await rewardsStore.purchaseReward(reward.id);
	}

	commonStore.setManageChallengesRewardsModal({
		description: t('manageChallengesRewardsModal.purchaseModal.description'),
		open: true,
		role: 'reward-purchase-successful',
		title: t('manageChallengesRewardsModal.purchaseModal.thanks'),
	});
	isSaving.value = false;
};

const onPurchaseReward = (reward: IReward) => {
	commonStore.setManageChallengesRewardsModal({
		open: true,
		role: 'reward-purchase',
		item: reward,
	});

	AnalyticsHelper.trackEvent('buy_reward', {
		user_properties: 'helper',
	});
};

const onStartingChallenge = async (challenge: IChallenge) => {
	isSaving.value = true;
	if (challenge.status === 'notSignedUp' && challenge.challengeId) {
		await challengesStore.startChallenge(challenge.challengeId);

		AnalyticsHelper.trackEvent('challenge_doing', {
			user_properties: 'helper',
		});
	}

	if (challenge.ctaHref) {
		if (challenge.ctaCategory === 'email') {
			window.open(`mailto:${challenge.ctaHref}`, '_blank');
		} else {
			window.open(challenge.ctaHref, '_blank');
		}
	}

	commonStore.closeManageChallengesRewardsModal();
	isSaving.value = false;
};

const onValidatingChallenge = async (challenge: IChallenge) => {
	isSaving.value = true;

	if (challenge.userChallengeId) {
		await challengesStore.accomplishChallenge(challenge.userChallengeId);

		AnalyticsHelper.trackEvent('challenge_done', {
			user_properties: 'helper',
		});
	}

	const completedChallenge = challengesStore.completedChallenges.find(
		(completedChallenge) =>
			completedChallenge.userChallengeId === challenge.userChallengeId
	);

	if (completedChallenge) {
		if (completedChallenge.status === 'waitingValidation') {
			commonStore.setManageChallengesRewardsModal({
				description: t(
					'manageChallengesRewardsModal.completedModal.description'
				),
				open: true,
				item: challenge,
				role: 'challenge-awaiting-validation',
				title: t('generic.validation.pending'),
			});
		} else if (
			completedChallenge.status === 'validatedByAdmin' ||
			completedChallenge.status === 'done'
		) {
			commonStore.setManageChallengesRewardsModal({
				open: true,
				item: challenge,
				role: 'challenge-completed',
				title: t('generic.completedChallenge'),
			});
		}
	}

	isSaving.value = false;
};
</script>

<template>
	<TransitionRoot as="template" :show="open" data-cy="modal">
		<Dialog
			:initial-focus="root"
			as="div"
			class="relative z-10"
			@close="onCloseModal"
		>
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
				/>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div ref="root" class="flex min-h-full items-center justify-center">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<DialogPanel
							class="mx-4 transform overflow-hidden rounded-[15px] bg-background-default shadow-xl transition-all md:mx-auto"
							:class="[
								[
									'challenge',
									'challenge-completed',
									'challenge-status',
									'reward',
									'reward-purchased',
								].includes(role)
									? 'w-[520px]'
									: 'max-w-sm',
								{ 'p-6': role !== 'reward-purchased' },
							]"
						>
							<div
								v-if="
									!['challenge', 'challenge-status', 'reward'].includes(role)
								"
								class="mb-4 flex justify-between"
							>
								<svg
									v-if="role === 'challenge-awaiting-validation'"
									width="56"
									height="56"
									viewBox="0 0 56 56"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										fill="#E0EAFF"
									/>
									<path
										d="M28 22V28L32 30M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
										stroke="#444CE7"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										stroke="#EEF4FF"
										stroke-width="8"
									/>
								</svg>

								<svg
									v-else-if="
										[
											'challenge-completed',
											'reward-purchase-successful',
										].includes(role)
									"
									width="56"
									height="56"
									viewBox="0 0 56 56"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										fill="#D1FADF"
									/>
									<path
										d="M36 22L25 33L20 28"
										stroke="#03874C"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										stroke="#ECFDF3"
										stroke-width="8"
									/>
								</svg>

								<svg
									v-else-if="role === 'reward-purchase'"
									width="56"
									height="56"
									viewBox="0 0 56 56"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										fill="#E0EAFF"
									/>
									<path
										d="M28 18L31.09 24.26L38 25.27L33 30.14L34.18 37.02L28 33.77L21.82 37.02L23 30.14L18 25.27L24.91 24.26L28 18Z"
										stroke="#444CE7"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<rect
										x="4"
										y="4"
										width="48"
										height="48"
										rx="24"
										stroke="#EEF4FF"
										stroke-width="8"
									/>
								</svg>

								<BaseIcon
									v-if="
										![
											'challenge',
											'challenge-status',
											'reward',
											'reward-purchased',
										].includes(role)
									"
									icon="close"
									color="#5F6C85"
									hover-state
									@click="onCloseModal"
								/>
							</div>

							<template v-if="role === 'reward-purchased'">
								<div
									class="mb-2 border-b border-border-subtle px-8 py-4 md:mb-4"
								>
									<div class="mb-4 flex justify-between">
										<BaseAvatar
											:src="
												item.pictureUrl === null
													? '/img/reward_lg.png'
													: item.pictureUrl
											"
											size="xl"
											shape="rounded"
										/>

										<div class="ml-4 flex-1 truncate">
											<h3
												class="truncate text-mgm-txt-lg font-medium text-foreground-emphasis"
											>
												{{ item.name }}
											</h3>

											<BaseBadge
												v-if="item.points"
												class="mt-2"
												size="md"
												color="grey"
												:text="item.points"
												icon-type="icon"
												icon-name="star"
											/>
										</div>

										<BaseIcon
											class="ml-2"
											icon="close"
											color="#5F6C85"
											hover-state
											@click="onCloseModal"
										/>
									</div>

									<p
										class="scrollbar-hide mt-4 max-h-28 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
										v-html="item.description"
									></p>
								</div>

								<div
									v-for="(
										rewardRetrievalStep, rewardRetrievalStepIndex
									) in rewardRetrievalSteps"
									:key="rewardRetrievalStepIndex"
									class="flex px-4 md:px-8"
								>
									<div class="relative mr-4 flex flex-col items-center">
										<div
											:class="{
												'top-8': rewardRetrievalStep.isFirst,
												'top-0': !rewardRetrievalStep.isFirst,
												'h-6': rewardRetrievalStep.isLast,
												'bottom-0': !rewardRetrievalStep.isLast,
											}"
											class="absolute w-px rounded-b-sm bg-border-subtle"
										></div>

										<BaseIcon
											class="relative z-10 mt-0.5"
											:icon="rewardRetrievalStep.status.icon"
											:color="rewardRetrievalStep.status.color"
											:bg-color="rewardRetrievalStep.status.bgColor"
										/>
									</div>

									<div class="pb-10">
										<p
											:class="[
												item.status != 'purchased'
													? 'text-foreground-default'
													: rewardRetrievalStep.isFirst
														? 'text-foreground-default'
														: rewardRetrievalStep.isCurrent
															? 'text-foreground-emphasis'
															: 'text-foreground-subtle',
											]"
											class="mt-1 text-mgm-txt-sm"
										>
											{{ rewardRetrievalStep.step }}
										</p>

										<p
											:class="[
												item.status != 'purchased'
													? 'text-foreground-default'
													: rewardRetrievalStep.isFirst ||
														  rewardRetrievalStep.isCurrent
														? 'text-foreground-default'
														: 'text-foreground-subtle',
											]"
											class="mt-1 text-mgm-txt-sm"
											v-html="rewardRetrievalStep.details"
										></p>

										<BaseButton
											v-if="
												rewardRetrievalStep.isCurrent &&
												item.status === 'purchased' &&
												userStore.user?.organization?.notificationEmail
											"
											:text="$t('generic.adminContact')"
											class="mt-2"
											size="md"
											state="scdr"
											@click="onContactAdmin"
										/>
									</div>
								</div>
							</template>

							<div
								v-if="
									['challenge', 'challenge-status', 'reward'].includes(role)
								"
								class="relative"
							>
								<div
									v-if="['challenge', 'challenge-status'].includes(role)"
									class="relative"
								>
									<img
										class="mb-8 h-[280px] w-full rounded-[5px] border border-transparent object-cover"
										:src="
											item.pictureUrl
												? item.pictureUrl
												: '/img/challenge_lg.png'
										"
									/>
									<span
										v-if="!item.pictureUrl"
										class="absolute inset-0 flex items-center justify-center text-mgm-txt-sm font-medium text-foreground-brand-default"
										>{{ item.points }}</span
									>
								</div>

								<img
									v-else
									class="mb-8 h-[280px] w-full rounded-[5px] border border-transparent object-cover"
									:src="
										item.pictureUrl === null
											? '/img/reward_lg.png'
											: item.pictureUrl
									"
								/>

								<BaseIcon
									class="absolute right-4 top-4"
									bg-color="#E2E8F0"
									color="#5F6C85"
									icon="close"
									hover-state
									@click="onCloseModal"
								/>
							</div>

							<div
								v-if="
									['challenge', 'challenge-status', 'reward'].includes(role)
								"
								class="flex items-start justify-between"
							>
								<h3
									class="mr-4 max-w-[80%] text-mgm-txt-lg font-medium text-foreground-emphasis"
									:class="{
										'max-w-[80%]': !['challenge', 'challenge-status'].includes(
											role
										),
									}"
								>
									{{ title }}
								</h3>

								<BaseBadge
									v-if="!['challenge', 'challenge-status'].includes(role)"
									size="md"
									color="blue"
									:text="item.points"
									icon-type="icon"
									icon-name="star"
								/>
							</div>

							<h3
								v-else
								class="mb-2 text-mgm-txt-lg font-semibold text-foreground-emphasis"
							>
								{{ title }}
							</h3>

							<BaseBadge
								v-if="role === 'challenge-status'"
								class="my-2"
								size="md"
								:color="challengeStatus.color"
								:text="challengeStatus.text"
							/>

							<div
								v-if="role === 'reward'"
								class="my-4 flex items-start rounded-[5px] bg-background-subtle px-4 py-2"
							>
								<BaseIcon class="mr-2" icon="info" />

								<div class="bg-background-subtle text-mgm-txt-sm">
									<h4 class="font-medium text-foreground-emphasis">
										{{ $t('generic.retrieve') }}
									</h4>

									<p
										class="mt-1 font-normal text-foreground-default"
										v-html="item.retrievalInstruction"
									></p>
								</div>
							</div>

							<div
								v-if="
									['challenge-completed', 'reward-purchase'].includes(role) &&
									userStore.user?.organization?.pointEnabled &&
									userStore.user?.balance
								"
								class="mb-6"
							>
								<div
									class="mb-2 flex justify-between text-mgm-txt-sm font-normal text-foreground-default"
								>
									<span>{{
										$t('manageChallengesRewardsModal.purchaseModal.points')
									}}</span>
									<span>{{
										role === 'challenge-completed'
											? userStore.user?.balance - item.points
											: userStore.user?.balance
									}}</span>
								</div>

								<div
									class="mb-2 flex justify-between text-mgm-txt-sm font-normal text-foreground-default"
								>
									<span>{{
										role === 'challenge-completed'
											? $t('manageChallengesRewardsModal.completedModal.won')
											: $t('manageChallengesRewardsModal.purchaseModal.cost')
									}}</span>
									<span>{{ item.points }}</span>
								</div>

								<div
									v-if="userStore.user.balance"
									class="flex justify-between border-t border-border-subtle pt-2 text-mgm-txt-sm font-medium text-foreground-emphasis"
								>
									<span class="mr-8">{{
										role === 'challenge-completed'
											? $t(
													'manageChallengesRewardsModal.completedModal.balance'
												)
											: $t('manageChallengesRewardsModal.purchaseModal.balance')
									}}</span>
									<span>{{
										role === 'challenge-completed'
											? userStore.user.balance
											: userStore.user.balance - item.points
									}}</span>
								</div>
							</div>

							<p
								class="scrollbar-hide max-h-44 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
								v-html="description"
							></p>

							<template v-if="role === 'challenge'">
								<BaseButton
									class="mb-2 mt-6 w-full"
									:disabled="item.status === 'ongoing' || isSaving"
									:text="$t('generic.cta.enroll')"
									@click="onStartingChallenge(item)"
								/>

								<BaseButton
									class="w-full"
									:disabled="item.status === 'notSignedUp' || isSaving"
									:text="$t('generic.done')"
									@click="onValidatingChallenge(item)"
								/>
							</template>

							<BaseButton
								v-if="
									role === 'challenge-status' &&
									(item.status === 'waitingValidation' ||
										item.status === 'refusedByAdmin')
								"
								:text="$t('generic.adminContact')"
								class="mt-6 w-full"
								size="md"
								state="scdr"
								@click="onContactAdmin"
							/>

							<BaseButton
								v-if="role === 'reward-purchase'"
								class="mb-2 w-full"
								:disabled="isSaving"
								:text="$t('generic.confirm')"
								@click="onFinalizePurchaseReward(item)"
							/>

							<BaseButton
								v-if="
									[
										'challenge-awaiting-validation',
										'challenge-completed',
										'reward-purchase',
										'reward-purchase-successful',
									].includes(role) || closeCta
								"
								class="w-full"
								:class="{ 'mt-6': role != 'reward-purchase' }"
								:state="role === 'reward-purchase' ? 'scdr' : 'prmr'"
								:text="
									role === 'reward-purchase'
										? $t('generic.cancel')
										: role === 'challenge-completed'
											? $t('manageChallengesRewardsModal.completedModal.thanks')
											: closeCta
												? closeCta
												: $t('generic.doneModalCta')
								"
								data-cy="btn-close-modal"
								@click="onCloseModal"
							/>

							<BaseButton
								v-if="role === 'reward' && userStore.user?.balance"
								class="mt-6 w-full"
								:disabled="item.points > userStore.user?.balance"
								:text="$t('generic.buy')"
								@click="onPurchaseReward(item)"
							/>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
