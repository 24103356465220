<script setup lang="ts">
import { FieldPhoneNumber } from '@magma-app/magma-lapilli';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps(['modelValue', 'validation']);
const emit = defineEmits(['update:modelValue', 'goBack']);

const { t } = useI18n();

onMounted(() => {
	props.validation.$reset();
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ t('helperOnboarding.phone.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ t('helperOnboarding.phone.subtitle') }}
		</h3>
		<FieldPhoneNumber
			id="phone"
			:model-value="props.modelValue"
			@update:model-value="
				(value) => {
					if (value) {
						emit('update:modelValue', value);
					}
				}
			"
			data-cy="phone"
			:description="t('helperOnboarding.phone.description')"
			class="mt-4"
			:locale="$i18n.locale"
			:label="$t('generic.phone')"
			required
			:validation="props.validation"
		/>
	</div>
</template>
