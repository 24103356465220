<script setup lang="ts">
// NPM
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

// Components
import TheNavigationItem from "@/components/shell/TheNavigationItem.vue";

// Data
const navigationItems = [
  {
    name: "generic.profile",
    to: {
      name: "settings-profile",
    },
  },
  {
    name: "Touchpoints",
    to: {
      name: "settings-touchpoints",
    },
  },
  {
    name: "theNavigation.matchingDetails",
    to: {
      name: "settings-matching-details",
    },
  },
];
</script>

<template>
  <aside class="bg-background-subtle p-4 lg:col-span-3">
    <nav class="space-y-1" aria-label="Aside">
      <div class="hidden space-y-1 lg:block">
        <TheNavigationItem
          v-for="navigationItem of navigationItems"
          :key="navigationItem.name"
          :name="$t(navigationItem.name)"
          :to="navigationItem.to"
        />
      </div>

      <Disclosure v-slot="{ open }" as="div" class="block space-y-1 lg:hidden">
        <DisclosureButton
          :class="[
            'bg-background-default text-foreground-brand-emphasis hover:bg-background-subtle-hover hover:text-foreground-emphasis',
            'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-border-default',
          ]"
        >
          <span class="flex-1">Menu</span>

          <svg
            :class="[
              open
                ? 'rotate-90 text-foreground-default'
                : ' -rotate-90 text-border-subtle',
              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-foreground-default',
            ]"
            aria-hidden="true"
            viewBox="0 0 20 20"
          >
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
          </svg>
        </DisclosureButton>

        <DisclosurePanel
          v-slot="{ close }"
          class="space-y-1 rounded-md bg-background-default"
        >
          <TheNavigationItem
            v-for="navigationItem of navigationItems"
            :key="navigationItem.name"
            :name="$t(navigationItem.name)"
            :to="navigationItem.to"
            @click="close()"
          />
        </DisclosurePanel>
      </Disclosure>
    </nav>
  </aside>
</template>
