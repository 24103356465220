// NPM
import { defineStore } from "pinia";

// Types
import type { IReward } from "@/types/IReward";

// Services
import Api from "@/services/api";

// Stores
import { useUserStore } from "@/stores/user";

export const useRewardsStore = defineStore("rewards", {
  state: () => {
    return {
      allRewards: [] as IReward[],
      purchasedRewards: [] as IReward[],
      displayPurchasedRewardDetails: false,
      displayRewardDetails: false,
    };
  },

  actions: {
    setPurchasedRewardDetails(displayPurchasedRewardDetails: boolean) {
      this.displayPurchasedRewardDetails = displayPurchasedRewardDetails;
    },

    setRewardDetails(displayRewardDetails: boolean) {
      this.displayRewardDetails = displayRewardDetails;
    },

    async getRewards() {
      const response = await Api.userDashboard().rewards.getRewards();

      this.allRewards = response.data;
    },

    async getPurchasedRewards() {
      const response = await Api.userDashboard().rewards.getPurchasedRewards();

      this.purchasedRewards = response.data;
    },

    async purchaseReward(rewardId: number) {
      const response = await Api.userDashboard().rewards.purchaseReward(
        rewardId
      );

      const userBalance = response.data.balance;

      const userStore = useUserStore();
      userStore.updateUserBalance(userBalance);
    },
  },
});
