<script setup lang="ts">
import { useHelpeeData } from '@/api/complete-helpee-signup/use-helpee-data';
import type { IUserCriteria } from '@/types/IUserCriteria';
import { hexToRgb } from '@/utils/color';
import {
	FieldSelect,
	BaseButton,
	FieldTextarea,
} from '@magma-app/magma-lapilli';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const emit = defineEmits(['submit']);

const { t } = useI18n();
const route = useRoute();

const criterias = ref<i[]>([]);
const expectation = ref<string>('');

const { data } = useHelpeeData(
	route.params.campaignHelpeeUuid as string | undefined
);

interface i {
	id: number;
	answerKey?: string;
	answerKeys?: string[];
}

const currentCriteriaKey = (crits: i[], currentCriteriaId: number) => {
	const criteria = crits as IUserCriteria[];
	const currentCriteria = criteria.find(
		(crit) => crit.id === currentCriteriaId
	);

	if (currentCriteria) {
		if (currentCriteria.type === 'singleSelect') {
			return currentCriteria.answerKey;
		} else {
			return currentCriteria.answerKeys;
		}
	}
};

const handleSubmit = () => {};
</script>

<template>
	<div class="px-5 lg:px-10 py-5 lg:py-7 flex flex-col flex-1 justify-between">
		<div>
			<h2 class="text-mgm-txt-xl font-medium">
				{{ $t('importProspects.needs.title') }}
			</h2>
			<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
				{{ $t('importProspects.needs.subtitle') }}
			</h3>
			<FieldSelect
				v-for="(crit, index) in data?.signUpInformation?.criteria"
				:key="index"
				:options="
					crit.options.map((o: any) => ({ label: o.value, value: o.key }))
				"
				:placeholder="crit.label"
				:label="crit.label"
				required
				:multiple="crit.type === 'multiSelect'"
				:custom-color="data?.signUpInformation?.primaryColor"
				:custom-rgb-color="
					hexToRgb(data?.signUpInformation?.primaryColor || '')
				"
				@update:model-value="
					(value) => {
						const newCriteria = {
							id: crit.id,
							[crit.type === 'multiSelect' ? 'answerKeys' : 'answerKey']: value,
						};

						criterias = [
							...criterias.filter((c) => c.id !== crit.id),
							newCriteria,
						];
					}
				"
				class="mb-6"
			/>
			<FieldTextarea
				v-model="expectation"
				required
				:label="$t('importProspects.needs.expectationLabel')"
				:placeholder="$t('importProspects.needs.expectationPlaceholder')"
				:custom-color="data?.signUpInformation?.primaryColor"
				:custom-rgb-color="
					hexToRgb(data?.signUpInformation?.primaryColor || '')
				"
			/>
		</div>
		<BaseButton
			@click="$emit('submit', { criterias, expectation })"
			size="lg"
			class="w-full mb-8 mt-10"
			:custom-color="data?.signUpInformation?.primaryColor"
			:custom-rgb-color="hexToRgb(data?.signUpInformation?.primaryColor || '')"
			:disabled="
				criterias.filter((crit) => crit.answerKey || crit.answerKeys).length !==
					data?.signUpInformation?.criteria.length || expectation === ''
			"
		>
			Continue</BaseButton
		>
	</div>
</template>
