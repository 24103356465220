<script setup lang="ts">
// NPM
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, required, requiredIf } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

// Components
import {
  BaseButton,
  BaseIcon,
  FieldAvatarUploader,
  FieldCheckbox,
  FieldLabel,
  FieldPhoneNumber,
  FieldSelect,
} from "@magma-app/magma-lapilli";

// Types
import type { PropType } from "vue";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useCommonStore } from "@/stores/common";
import { useUserStore } from "@/stores/user";
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const { t } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const displayDeactivationForm = ref(false);
const displayReactivationForm = ref(false);
const displaySuccessView = ref(false);
const isLoading = ref(false);

const formData = reactive({
  deactivation: {
    keepUnstartedRelations: null as null | boolean,
    optOutReason: "" as
      | "too-many-relations"
      | "no-more-time"
      | "doesnt-want"
      | "no-more-in-org",
    reactivationIn: null as null | 0 | 1 | 3 | 6,
  },
  reactivation: {
    profilePicture: null,
    phoneNumber: userStore.user?.phoneNumber || "",
    matches: "unlimited" as number | "unlimited",
    weekDaysMatching: true as boolean | undefined,
    weekEndMatching: true as boolean | undefined,

    criteria:
      (userStore.user?.userCriteria?.reduce(
        (accumulator: { [key: string]: string | string[] }, criteria) => {
          accumulator[criteria.criteriaId] =
            criteria.answerKeys || criteria.answerKey || "";

          return accumulator;
        },
        {}
      ) as { [key: string]: string | string[] }) || {},
  },
});

// Form Validation
const v$ = useVuelidate(
  {
    deactivation: {
      keepUnstartedRelations: {
        requiredIfRef: withI18nMessage(
          requiredIf(() => props.deactivateCampaignUnstartedRelations > 0),
          {
            messagePath: () => t("validation.value"),
          }
        ),
      },
      optOutReason: {
        required: withI18nMessage(required, {
          messagePath: () => t("validation.value"),
        }),
      },
      reactivationIn: {
        required: withI18nMessage(required, {
          messagePath: () => t("validation.value"),
        }),
      },
    },
    reactivation: {
      phoneNumber: {
        validPhoneNumber: withI18nMessage(
          (value: string) => {
            return value?.length != 0 ? isValidPhoneNumber(value) : true;
          },
          {
            messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
          }
        ),
      },
      profilePicture: {
        size: withI18nMessage(
          (value: File | null) => {
            if (value instanceof File && typeof value.size === "number") {
              return value.size < 9 * 1000 * 1000;
            }
            return false;
          },
          {
            messagePath: () => t("validation.pictureSize"),
          }
        ),
      },
    },
  },
  formData
);

// Props
const props = defineProps({
  campaignId: {
    type: Number,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
  statusHelperRole: {
    type: String as PropType<
      "deactivate-campaign" | "join-campaign" | "reactivate-campaign"
    >,
    default: "join-campaign",
  },
  deactivateCampaignUnstartedRelations: {
    type: Number,
    default: 0,
  },
});

// Computed
const campaignCriteria = computed(() => {
  return userStore.allCampaigns
    ?.find((campaign) => campaign.id === props.campaignId)
    ?.criteria?.map((criteria) => {
      return {
        criteriaId: criteria.id,
        weight: criteria.weight,
        criteria: {
          id: criteria.id,
          labelHelper: criteria.labelHelper,
          type: criteria.type,
          options: criteria.options,
        },
      };
    });
});

const deactivationFormOptions = computed(() => {
  return {
    keepUnstartedRelations: [
      {
        label: t("slideOverModal.deactivate.keepUnstartedRelations.true"),
        value: true,
      },
      {
        label: t("slideOverModal.deactivate.keepUnstartedRelations.false"),
        value: false,
      },
    ],
    optOutReason: [
      {
        label: t("slideOverModal.deactivate.optOutReason.tooManyRelations"),
        value: "too-many-relations",
      },
      {
        label: t("slideOverModal.deactivate.optOutReason.noMoreTime"),
        value: "no-more-time",
      },
      {
        label: t("slideOverModal.deactivate.optOutReason.doesntWant"),
        value: "doesnt-want",
      },
      {
        label: t("slideOverModal.deactivate.optOutReason.noMoreInOrg"),
        value: "no-more-in-org",
      },
    ],
    reactivationIn: [
      {
        label: t("slideOverModal.deactivate.reactivationIn.next"),
        value: 1,
      },
      {
        label: t("slideOverModal.deactivate.reactivationIn.three"),
        value: 3,
      },
      {
        label: t("slideOverModal.deactivate.reactivationIn.six"),
        value: 6,
      },
      {
        label: t("slideOverModal.deactivate.reactivationIn.never"),
        value: 0,
      },
    ],
  };
});

const hasStrongCriteriaSelected = computed(() => {
  if (campaignCriteria.value) {
    for (const item of campaignCriteria.value) {
      if (
        item.weight === "strong" &&
        (formData.reactivation.criteria[item.criteriaId] === undefined ||
          formData.reactivation.criteria[item.criteriaId]?.length < 1)
      ) {
        // No option has been selected in a 'strong' criteria
        return false;
      } else {
        return true;
      }
    }
  }

  return true;
});

const matchesOptions = computed(() => {
  return [
    {
      label: t("slideOverModal.matchesOptions.one"),
      value: 1,
    },
    {
      label: t("slideOverModal.matchesOptions.five"),
      value: 5,
    },
    {
      label: t("slideOverModal.matchesOptions.unlimited"),
      value: "unlimited",
    },
  ];
});

const modalText = computed(() => {
  if (
    props.statusHelperRole === "deactivate-campaign" &&
    !displaySuccessView.value
  ) {
    return {
      title: t("slideOverModal.deactivate.stop"),
      description: t("slideOverModal.deactivate.result"),
    };
  } else if (
    props.statusHelperRole === "deactivate-campaign" &&
    displaySuccessView
  ) {
    return {
      title: t("slideOverModal.deactivate.anymore"),
      description: t("slideOverModal.deactivate.thanks"),
    };
  } else if (props.statusHelperRole === "join-campaign") {
    return {
      title: t("slideOverModal.join.involved"),
      description: t("slideOverModal.join.thank"),
    };
  } else if (
    props.statusHelperRole === "reactivate-campaign" &&
    !displaySuccessView.value
  ) {
    return {
      title: t("slideOverModal.reactivate.again"),
      description: t("slideOverModal.reactivate.support"),
    };
  } else if (
    props.statusHelperRole === "reactivate-campaign" &&
    displaySuccessView.value
  ) {
    return {
      title: t("slideOverModal.reactivate.back"),
      description: t("slideOverModal.join.thank"),
    };
  } else {
    return {
      title: "",
      description: "",
    };
  }
});

// Watchers
watch(
  () => formData.deactivation,
  async () => await v$.value.deactivation.$validate()
);

watch(
  () => formData.reactivation,
  async () => await v$.value.reactivation.$validate()
);

// Event listeners
const onCloseSlideOverModal = async (closeType?: string) => {
  if (displaySuccessView.value) {
    closeType = "";
  }

  commonStore.closeSlideOverModal(closeType);

  // setTimeout helps to avoid a glitch where the slide over modal is closed before the animation is finished
  setTimeout(() => {
    displayDeactivationForm.value = false;
    displayReactivationForm.value = false;
    displaySuccessView.value = false;
  }, 1000);
};

const onDeactivateCampaign = async () => {
  isLoading.value = true;

  if (await v$.value.deactivation.$validate()) {
    try {
      await userStore.leaveCampaign({
        campaignId: props.campaignId,
        optOutReason: formData.deactivation.optOutReason,
        keepUnstartedRelations: formData.deactivation.keepUnstartedRelations,
        reactivationIn: formData.deactivation.reactivationIn,
      });

      displayDeactivationForm.value = false;
      displaySuccessView.value = true;

      // Tracking event
      let optOutReasonEventName = "";

      if (formData.deactivation.optOutReason === "too-many-relations") {
        optOutReasonEventName = "hs_deactivate_toorelations";
      } else if (formData.deactivation.optOutReason === "no-more-time") {
        optOutReasonEventName = "hs_deactivate_nottime";
      } else if (formData.deactivation.optOutReason === "doesnt-want") {
        optOutReasonEventName = "hs_deactivate_dontwant";
      } else if (formData.deactivation.optOutReason === "no-more-in-org") {
        optOutReasonEventName = "hs_deactivate_quitorga";
      }

      AnalyticsHelper.trackEvent(optOutReasonEventName, {
        user_properties: "helper",
      });

      let reactivationIn = "";

      if (formData.deactivation.reactivationIn === 0) {
        reactivationIn = "hs_deactivate_never";
      } else if (formData.deactivation.reactivationIn === 1) {
        reactivationIn = "hs_deactivate_nextmonth";
      } else if (formData.deactivation.reactivationIn === 3) {
        reactivationIn = "hs_deactivate_threemonths";
      } else if (formData.deactivation.reactivationIn === 6) {
        reactivationIn = "hs_deactivate_sixmonths";
      }

      AnalyticsHelper.trackEvent(reactivationIn, {
        user_properties: "helper",
      });

      AnalyticsHelper.trackEvent("hs_deactivate_confirmation", {
        user_properties: "helper",
      });
    } catch (error) {
      console.log(error);
    }
  }

  isLoading.value = false;
};

const onReactivateCampaign = async () => {
  isLoading.value = true;

  if (await v$.value.reactivation.phoneNumber.$validate()) {
    try {
      await userStore.joinCampaign({
        campaignId: props.campaignId,
      });

      await userStore.updateCampaign({
        campaignId: props.campaignId,
        maxHelpees:
          formData.reactivation.matches === "unlimited"
            ? null
            : formData.reactivation.matches,
        maxHelpeesPeriod:
          formData.reactivation.matches === "unlimited" ? "" : "week",
        matchedOnWeekdays: formData.reactivation.weekDaysMatching,
        matchedOnWeekends: formData.reactivation.weekEndMatching,
      });

      displayReactivationForm.value = false;
      displaySuccessView.value = true;

      AnalyticsHelper.trackEvent("hs_reactivate_confirmation", {
        user_properties: "helper",
      });
    } catch (error) {
      console.log(error);
    }
  }

  isLoading.value = false;
};

const onDisplayDeactivationForm = () => {
  displayDeactivationForm.value = true;
};

const onDisplayReactivationForm = () => {
  displayReactivationForm.value = true;
};

const uploadProfilePicture = async (profilePicture: File) => {
  if (await v$.value.reactivation.profilePicture.$validate()) {
    await userStore.uploadPicture({
      profilePicture,
    });
  }
};

// Methods
const getCriteriaOptions = (criteriaId: number) => {
  const campaignCriteria = userStore.allCampaigns
    ?.find((campaign) => campaign.id === props.campaignId)
    ?.criteria.find((criteria) => criteria.id === criteriaId);

  if (campaignCriteria) {
    return campaignCriteria.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-10"
      @close="onCloseSlideOverModal('cancel')"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-[#0E101466] transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-x-0 bottom-0 flex max-w-full lg:inset-0 lg:items-center lg:justify-center"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-y-0"
              leave-to="translate-y-full opacity-0"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen lg:w-auto lg:max-w-md"
              >
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute right-5 top-5">
                    <BaseIcon
                      icon="close"
                      color="#5F6C85"
                      hover-state
                      @click="onCloseSlideOverModal('cancel')"
                    />
                  </div>
                </TransitionChild>

                <div
                  class="rounded-t-2xl bg-background-default shadow-xl lg:rounded-2xl"
                >
                  <div
                    v-if="!displayReactivationForm"
                    class="backgroundImage flex h-[184px] items-center justify-center rounded-t-2xl"
                  >
                    <svg
                      v-if="statusHelperRole === 'deactivate-campaign'"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#FEE4E2"
                      />
                      <g clip-path="url(#clip0_279_4115)">
                        <path
                          d="M14.2866 14.2868L23.7133 23.7135M25.6666 19.0002C25.6666 22.6821 22.6819 25.6668 19 25.6668C15.3181 25.6668 12.3333 22.6821 12.3333 19.0002C12.3333 15.3183 15.3181 12.3335 19 12.3335C22.6819 12.3335 25.6666 15.3183 25.6666 19.0002Z"
                          stroke="#D92D20"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <rect
                        x="3"
                        y="3"
                        width="32"
                        height="32"
                        rx="16"
                        stroke="#FEE4E2"
                        stroke-opacity="0.4"
                        stroke-width="6"
                      />
                      <defs>
                        <clipPath id="clip0_279_4115">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(11 11)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <svg
                      v-else-if="
                        ['join-campaign', 'reactivate-campaign'].includes(
                          statusHelperRole
                        )
                      "
                      class="mx-auto"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#E0EAFF"
                      />
                      <path
                        d="M24.3334 25V23.6667C24.3334 22.9594 24.0525 22.2811 23.5524 21.781C23.0523 21.281 22.374 21 21.6667 21H16.3334C15.6262 21 14.9479 21.281 14.4478 21.781C13.9477 22.2811 13.6667 22.9594 13.6667 23.6667V25M21.6667 15.6667C21.6667 17.1394 20.4728 18.3333 19.0001 18.3333C17.5273 18.3333 16.3334 17.1394 16.3334 15.6667C16.3334 14.1939 17.5273 13 19.0001 13C20.4728 13 21.6667 14.1939 21.6667 15.6667Z"
                        stroke="#444CE7"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <rect
                        x="3"
                        y="3"
                        width="32"
                        height="32"
                        rx="16"
                        stroke="#E0EAFF"
                        stroke-opacity="0.4"
                        stroke-width="6"
                      />
                    </svg>
                  </div>

                  <div class="mx-auto max-w-md px-4 pb-14 lg:p-6">
                    <template v-if="displayDeactivationForm">
                      <FieldSelect
                        v-if="deactivateCampaignUnstartedRelations > 0"
                        id="keepUnstartedRelations"
                        v-model="formData.deactivation.keepUnstartedRelations"
                        :description="`${$t(
                          'slideOverModal.deactivate.keepUnstartedRelations.description.partOne'
                        )}${deactivateCampaignUnstartedRelations}${
                          deactivateCampaignUnstartedRelations > 1
                            ? $t(
                                'slideOverModal.deactivate.keepUnstartedRelations.description.partTwo.plural'
                              )
                            : $t(
                                'slideOverModal.deactivate.keepUnstartedRelations.description.partTwo.singular'
                              )
                        }`"
                        :label="
                          $t(
                            'slideOverModal.deactivate.keepUnstartedRelations.label'
                          )
                        "
                        :options="
                          deactivationFormOptions.keepUnstartedRelations
                        "
                        :placeholder="$t('generic.placeholder.singleSelect')"
                        required
                      />

                      <FieldSelect
                        id="optOutReason"
                        v-model="formData.deactivation.optOutReason"
                        :class="{
                          'mt-4': deactivateCampaignUnstartedRelations > 0,
                        }"
                        :label="
                          $t('slideOverModal.deactivate.optOutReason.label')
                        "
                        :options="deactivationFormOptions.optOutReason"
                        :placeholder="$t('generic.placeholder.singleSelect')"
                        required
                      />

                      <FieldSelect
                        id="reactivationIn"
                        v-model="formData.deactivation.reactivationIn"
                        class="mt-4"
                        :label="
                          $t('slideOverModal.deactivate.reactivationIn.label')
                        "
                        :options="deactivationFormOptions.reactivationIn"
                        :placeholder="$t('generic.placeholder.singleSelect')"
                        required
                      />

                      <BaseButton
                        class="mt-6 w-full"
                        :text="$t('generic.confirm')"
                        size="md"
                        :disabled="
                          v$.deactivation.$invalid ||
                          v$.deactivation.$error ||
                          isLoading
                        "
                        @click="onDeactivateCampaign"
                      />

                      <BaseButton
                        class="mt-2 w-full"
                        :text="$t('generic.cancel')"
                        size="md"
                        state="scdr"
                        @click="onCloseSlideOverModal('cancel')"
                      />
                    </template>

                    <div
                      v-else-if="displayReactivationForm"
                      class="pt-8 lg:pt-0"
                    >
                      <svg
                        class="mx-auto"
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="32"
                          height="32"
                          rx="16"
                          fill="#E0EAFF"
                        />
                        <path
                          d="M24.3333 25V23.6667C24.3333 22.9594 24.0523 22.2811 23.5522 21.781C23.0521 21.281 22.3739 21 21.6666 21H16.3333C15.626 21 14.9478 21.281 14.4477 21.781C13.9476 22.2811 13.6666 22.9594 13.6666 23.6667V25M21.6666 15.6667C21.6666 17.1394 20.4727 18.3333 19 18.3333C17.5272 18.3333 16.3333 17.1394 16.3333 15.6667C16.3333 14.1939 17.5272 13 19 13C20.4727 13 21.6666 14.1939 21.6666 15.6667Z"
                          stroke="#444CE7"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <rect
                          x="3"
                          y="3"
                          width="32"
                          height="32"
                          rx="16"
                          stroke="#E0EAFF"
                          stroke-opacity="0.4"
                          stroke-width="6"
                        />
                      </svg>

                      <span
                        class="mx-auto mt-6 block text-center text-mgm-txt-md font-medium text-foreground-emphasis"
                      >
                        {{ $t("slideOverModal.reactivate.update") }}
                      </span>

                      <FieldAvatarUploader
                        id="profilePicture"
                        v-model="formData.reactivation.profilePicture"
                        class="mt-4"
                        :cta="$t('generic.change')"
                        :label="$t('generic.profilePicture')"
                        :locale="$i18n.locale"
                        :preview-url="
                          userStore.user?.profilePictureUrl || undefined
                        "
                        :validation="v$.reactivation.profilePicture"
                        @change="uploadProfilePicture"
                      />

                      <FieldPhoneNumber
                        id="phone"
                        v-model="formData.reactivation.phoneNumber"
                        data-cy="phone"
                        class="mt-4"
                        :locale="$i18n.locale"
                        :label="$t('generic.phoneNumber')"
                        :validation="v$.phoneNumber"
                      />

                      <FieldSelect
                        v-for="campaignCriteriaItem in campaignCriteria"
                        :id="campaignCriteriaItem.criteriaId"
                        :key="campaignCriteriaItem.criteriaId"
                        v-model="
                          formData.reactivation.criteria[
                            campaignCriteriaItem.criteriaId
                          ]
                        "
                        class="mt-4"
                        :label="campaignCriteriaItem.criteria?.labelHelper"
                        :options="
                          getCriteriaOptions(campaignCriteriaItem.criteriaId)
                        "
                        :multiple="
                          campaignCriteriaItem.criteria?.type === 'multiSelect'
                        "
                        :required="campaignCriteriaItem.weight === 'strong'"
                      />

                      <FieldSelect
                        id="matches"
                        v-model="formData.reactivation.matches"
                        class="mt-4"
                        :label="$t('generic.matchesLabel')"
                        :options="matchesOptions"
                      />

                      <div class="mt-4">
                        <div class="flex items-start">
                          <FieldLabel
                            :label="$t('generic.matchesTimingLabel')"
                          />

                          <span
                            class="ml-1 inline-block align-top text-mgm-txt-sm font-medium text-foreground-brand-default"
                          >
                            *
                          </span>
                        </div>

                        <FieldCheckbox
                          id="weekDaysMatching"
                          v-model="formData.reactivation.weekDaysMatching"
                          class="mt-2.5"
                        >
                          <span class="block text-mgm-txt-md font-normal">
                            {{ $t("generic.weekDaysMatching") }}
                          </span>
                        </FieldCheckbox>

                        <FieldCheckbox
                          id="weekEndMatching"
                          v-model="formData.reactivation.weekEndMatching"
                          class="mt-2"
                        >
                          <span class="block text-mgm-txt-md font-normal">
                            Week-ends
                          </span>
                        </FieldCheckbox>
                      </div>

                      <BaseButton
                        class="mt-[18px] w-full"
                        :text="$t('slideOverModal.reactivate.cta')"
                        size="md"
                        :disabled="
                          (!formData.reactivation.weekDaysMatching &&
                            !formData.reactivation.weekEndMatching) ||
                          hasStrongCriteriaSelected === false ||
                          v$.reactivation.$error ||
                          isLoading
                        "
                        @click="onReactivateCampaign"
                      />

                      <BaseButton
                        class="mt-2 w-full"
                        :text="$t('generic.cancel')"
                        size="md"
                        state="scdr"
                        @click="onCloseSlideOverModal('cancel')"
                      />
                    </div>

                    <template v-else>
                      <p class="text-center">
                        <span
                          class="block text-mgm-txt-md font-medium text-foreground-emphasis"
                        >
                          {{ modalText.title }}
                        </span>

                        <span
                          class="mt-2 block text-mgm-txt-sm font-normal text-foreground-default"
                        >
                          {{ modalText.description }}
                        </span>
                      </p>

                      <template
                        v-if="
                          [
                            'deactivate-campaign',
                            'reactivate-campaign',
                          ].includes(statusHelperRole) && !displaySuccessView
                        "
                      >
                        <BaseButton
                          v-if="statusHelperRole === 'reactivate-campaign'"
                          class="mt-6 w-full"
                          :text="$t('slideOverModal.reactivate.cta')"
                          size="md"
                          @click="onDisplayReactivationForm"
                        />

                        <BaseButton
                          v-else-if="statusHelperRole === 'deactivate-campaign'"
                          class="mt-6 w-full"
                          :text="$t('slideOverModal.deactivate.cta')"
                          size="md"
                          @click="onDisplayDeactivationForm"
                        />

                        <BaseButton
                          class="mt-2 w-full"
                          :text="$t('generic.cancel')"
                          size="md"
                          state="scdr"
                          @click="onCloseSlideOverModal('cancel')"
                        />
                      </template>

                      <BaseButton
                        v-else-if="
                          statusHelperRole === 'join-campaign' ||
                          ([
                            'deactivate-campaign',
                            'reactivate-campaign',
                          ].includes(statusHelperRole) &&
                            displaySuccessView)
                        "
                        class="mt-6 w-full"
                        :text="$t('generic.getIt')"
                        size="md"
                        @click="onCloseSlideOverModal()"
                      />
                    </template>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.backgroundImage {
  background:
    linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%),
    url("/img/illustration_helper_signup.png") no-repeat center center / cover;
}
</style>
