<script setup lang="ts">
// NPM
import { onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

// Components
import { BaseButton } from "@magma-app/magma-lapilli";

// Stores
import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

// Plugins
const { locale } = useI18n();
const route = useRoute();

// Lifecycle hooks
onBeforeMount(async () => {
  // Get sign in data
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if ((subdomain && subdomain != "v3") || route?.params.campaignUuid) {
    await authStore.getSignInData(
      subdomain,
      route?.params.campaignUuid as string
    );

    if (authStore.signInData) {
      locale.value = authStore.signInData.language;
    }
  }
});
</script>

<template>
  <div class="gradient__grey__07 flex flex-col items-center justify-center p-8">
    <div class="sm:w-[360px]">
      <img
        class="mx-auto h-20 w-20 rounded-full object-cover ring-[5px] ring-border-on-emphasis"
        :src="
          authStore.signInData?.logoUrl
            ? authStore.signInData?.logoUrl
            : '/img/logo_magma.png'
        "
      />

      <p
        class="mx-auto mt-8 text-center text-mgm-txt-md font-medium text-foreground-emphasis"
      >
        {{ $t("expiredLinkView.expired") }}
      </p>

      <BaseButton
        class="mt-8 w-full"
        :text="$t('generic.backToLogin')"
        size="md"
        type="submit"
        @click="() => $router.push({ name: 'login' })"
      />
    </div>
  </div>
</template>
