// NPM
import { defineStore } from 'pinia';

// Services
import Api from '@/services/api';

// Types
import type { IAllCampaigns } from '@/types/IAllCampaigns';
import type { ICampaign } from '@/types/ICampaign';
import type { ICampaignDetails } from '@/types/ICampaignDetails';
import type { IFormUpdateCampaign } from '@/types/IFormUpdateCampaign';
import type { IUser } from '@/types/IUser';
import type { IUserCriteria } from '@/types/IUserCriteria';
import type { ISupportAdmin } from '@/types/ISupportAdmin';

export const useUserStore = defineStore('user', {
	state: () => ({
		user: null as IUser | null,
		userCriteria: [] as IUserCriteria[],
		userTouchpoints: [] as {
			campaignId?: number;
			campaignTouchPointId: number;
			icon: string;
			id: number;
			name: string;
			type: string;
			userTouchPointValue?: string;
		}[],
		allCampaigns: [] as IAllCampaigns[],
		campaigns: [] as ICampaignDetails[],
		campaign: {} as ICampaign,
		campaignInfo: {} as {
			logoUrl: string;
			name: string;
			organizationName: string;
		},
		campaignDetails: {} as ICampaignDetails,
		helpeeTouchpoints: [] as {
			icon: string;
			id: number;
			name: string;
			type: string;
			value?: string;
		}[],
		referFormEnabled: false as boolean,
		sharingLink: null as string | null,
		sharingPageEnabled: false as boolean,
		supportData: null as ISupportAdmin | null,
	}),

	actions: {
		async getAllCampaigns() {
			const response = await Api.userDashboard().campaign.getAllCampaigns();

			this.allCampaigns = response.data;
		},

		async getCampaigns() {
			const response = await Api.userDashboard().campaign.getCampaigns();

			this.campaigns = response.data;

			this.campaigns.forEach((campaign) => {
				if (campaign.referFormEnabled) {
					this.referFormEnabled = true;
				}
			});

			this.campaigns.forEach((campaign) => {
				if (campaign.sharingPageEnabled) {
					this.sharingPageEnabled = true;
				}
			});
		},

		async getCampaign(campaignUuid: string) {
			const response =
				await Api.userDashboard().campaign.getCampaign(campaignUuid);

			this.campaign = response.data;
			this.campaignDetails = response.data.campaign as ICampaignDetails;
		},

		async getCampaignInfo(relationId: number) {
			const response =
				await Api.userDashboard().campaign.getCampaignInfo(relationId);

			this.campaignInfo = response.data;
		},

		async updateCampaign(payload: IFormUpdateCampaign) {
			const response =
				await Api.userDashboard().campaign.updateCampaign(payload);

			this.campaign = response.data;
		},

		async joinCampaign(payload: { campaignId: number }) {
			const response = await Api.userDashboard().campaign.joinCampaign(payload);

			this.allCampaigns = response.data;
		},

		async leaveCampaign(payload: {
			campaignId: number;
			optOutReason:
				| 'too-many-relations'
				| 'no-more-time'
				| 'doesnt-want'
				| 'no-more-in-org';
			keepUnstartedRelations: boolean | null;
			reactivationIn: 0 | 1 | 3 | 6 | null;
		}) {
			const response =
				await Api.userDashboard().campaign.leaveCampaign(payload);

			this.allCampaigns = response.data;
		},

		async getUser(
			needCriteria: boolean,
			needIsHelpee: boolean,
			needIsHelper: boolean,
			needPoints: boolean
		) {
			const response = await Api.userDashboard().user.getUser(
				needCriteria,
				needIsHelpee,
				needIsHelper,
				needPoints
			);

			this.user = response.data;
		},

		async getSupportData(relationId?: number, campaignId?: number) {
			const response = await Api.userDashboard().user.getSupportData(
				relationId,
				campaignId
			);

			this.supportData = response.data;
		},

		async getSharingLink() {
			const response = await Api.userDashboard().user.getSharingLink();

			this.sharingLink = response.data.shortUrl;
		},

		async uploadPicture(payload: { profilePicture: File }) {
			const response =
				await Api.userDashboard().user.uploadProfilePicture(payload);

			if (this.user) {
				this.user.profilePictureUrl = response.data.profilePictureUrl;
			}
		},

		async updateUser(payload: {
			firstname?: string;
			lastname?: string;
			phoneNumber?: string;
			notificationEmail?: string;
			acceptPhoneNotifications?: boolean | undefined;
			acceptEmailNotifications?: boolean | undefined;
			position?: string;
		}) {
			const response = await Api.userDashboard().user.updateUser(payload);

			this.user = {
				...this.user,
				...response.data,
			};
		},

		updateUserBalance(userBalance: number) {
			if (this.user) {
				this.user.balance = userBalance;
			}
		},

		async getUserCriteria() {
			const response = await Api.userDashboard().user.getUserCriteria();

			this.userCriteria = response.data;
		},

		async updateUserCriteria(payload: {
			criteriaId: number;
			answerKey?: string;
			answerKeys?: string[];
		}) {
			const response =
				await Api.userDashboard().user.updateUserCriteria(payload);

			if (this.userCriteria) {
				const criteria = this.userCriteria.find((criteria: IUserCriteria) => {
					return criteria.id === response.data.criteriaId;
				});

				if (criteria) {
					criteria.userAnswerKey = response.data.answerKey;
					criteria.userAnswerKeys = response.data.answerKeys;
				}
			}
		},

		async getUserTouchpoints() {
			const response = await Api.userDashboard().user.getUserTouchpoints();

			this.userTouchpoints = response.data;
		},

		async getHelpeeTouchpoints() {
			const response = await Api.userDashboard().user.getHelpeeTouchpoints();

			this.helpeeTouchpoints = response.data;
		},

		async updateUserTouchpoint(payload: {
			touchPointId: number;
			value: string;
		}) {
			const response =
				await Api.userDashboard().user.updateUserTouchpoint(payload);

			if (this.userTouchpoints) {
				const touchpoint = this.userTouchpoints.find((touchpoint) => {
					return touchpoint.id === response.data.touchPointId;
				});

				if (touchpoint) {
					touchpoint.userTouchPointValue = response.data.value;
				}
			}
		},

		async sendSupportMessage(payload: {
			message: string;
			campaignAdminUuid: string;
		}) {
			await Api.userDashboard().user.sendSupportMessage(payload);
		},
	},
});
