<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const authStore = useAuthStore();

const campaignUuid = route.params.campaignUuid;

watchEffect(async () => {
	const host = window.location.host;
	const subdomain = host.split('.')[0];

	await authStore.getSignInData(
		subdomain,
		route?.params.campaignUuid as string
	);
});

const helpersProfilePicture = [
	{
		picture: '/img/team/hugo.png',
		position: 'top-11 left-[15%]',
		size: 'h-12 w-12',
	},
	{
		picture: '/img/team/joris.png',
		position: 'bottom-4 left-[20%]',
		size: 'h-12 w-12',
	},
	{
		picture: '/img/team/marine.png',
		position: 'bottom-1/4 right-[40%]',
		size: 'h-12 w-12',
	},
	{
		picture: '/img/team/nada.png',
		position: 'top-6 right-[30%]',
		size: 'h-12 w-12',
	},
	{
		picture: '/img/team/edouard.png',
		position: 'bottom-4 right-[15%]',
		size: 'h-12 w-12',
	},
	{
		blur: true,
		picture: '/img/avatars/blur_1.png',
		position: 'top-1/3 right-[55%]',
		size: 'h-8 w-8',
	},
	{
		blur: true,
		picture: '/img/avatars/blur_2.png',
		position: 'top-[40%] right-7',
		size: 'h-8 w-8',
	},
	{
		blur: true,
		picture: '/img/avatars/blur_3.png',
		position: 'bottom-1/4 left-7',
		size: 'h-8 w-8',
	},
];
</script>

<template>
	<div class="flex items-center justify-center flex-col">
		<div
			class="mx-auto w-full md:relative md:mt-20 md:h-[650px] md:w-[654px] md:rounded-[15px] md:shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)] max-h-[650px]"
		>
			<div
				data-cy="signup-confirmation"
				class="relative h-full w-full flex-1 text-center"
			>
				<div
					:class="[
						`relative h-[200px] bg-[url('/img/illustration_redirection_background.svg')] bg-cover bg-center md:rounded-t-[15px]`,
					]"
				>
					<div
						v-for="(helper, helperIndex) in helpersProfilePicture"
						:key="helperIndex"
						:class="['absolute', helper.position]"
					>
						<div
							:class="[
								'overflow-hidden rounded-full',
								helper.size,
								{ 'border-2 border-border-on-emphasis': !helper.blur },
							]"
						>
							<img class="h-full w-full object-cover" :src="helper.picture" />
						</div>
					</div>
				</div>

				<div class="flex items-center justify-center py-10 gap-3">
					<img
						:src="authStore.signInData?.logoUrl"
						class="w-10 h-10 rounded-full"
					/>
					<h2 class="font-semibold text-foreground-emphasis">
						{{ authStore.signInData?.organizationName }}
					</h2>
				</div>

				<h3
					class="mx-auto mt-10 max-w-xs text-mgm-txt-xl font-semibold text-foreground-emphasis"
				>
					{{ $t('helpeeCampaignSignUpView.redirection.title') }}
				</h3>

				<p
					class="mx-auto mt-4 max-w-xs text-mgm-txt-sm font-normal text-foreground-default"
				>
					{{ $t('helpeeCampaignSignUpView.redirection.description') }}
				</p>
			</div>
		</div>

		<div class="hidden sm:block">
			<a
				class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-border-default hover:text-foreground-subtle focus:text-foreground-subtle flex gap-2 items-center"
				href="https://www.magma.app/"
				target="_blank"
			>
				{{ $t('generic.powered') }}
				<img src="/img/logo_magma_full_bw.svg" alt="Magma" />
			</a>
		</div>
	</div>
</template>
