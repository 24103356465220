<script setup lang="ts">
import { computed } from 'vue';

type Steps =
	| 'welcome'
	| 'start'
	| 'share'
	| 'email'
	| 'phone'
	| 'verification'
	| 'name'
	| 'profile'
	| 'criteria'
	| 'touchpoints'
	| 'resume';

const props = defineProps<{ step: Steps }>();

const currentStepPercentage = computed(() => {
	switch (props.step) {
		case 'email':
			return 10;
		case 'phone' || 'verification' || 'code':
			return 25;
		case 'name':
			return 50;
		case 'profile':
			return 62.5;
		case 'criteria':
			return 75;
		case 'touchpoints':
			return 87.5;
		case 'resume':
			return 100;
	}
});
</script>

<template>
	<div class="bg-background-subtle w-full h-1">
		<div
			class="bg-background-brand-emphasis h-1 rounded-r-full transition-all duration-300 ease-in-out"
			:style="{ width: `${currentStepPercentage}%` }"
		></div>
	</div>
</template>
