<script setup lang="ts">
// NPM
import { computed, nextTick, onBeforeMount, reactive, ref } from "vue";

import { useRoute, useRouter } from "vue-router";
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// Components
import {
  BaseAvatar,
  BaseBadge,
  BaseButton,
  BaseIcon,
  BaseLoader,
  FieldAvatarUploader,
  FieldCheckbox,
  FieldLabel,
  FieldPhoneNumber,
  FieldSelect,
} from "@magma-app/magma-lapilli";

// Types
import type { PropType } from "vue";
import type { IAllCampaigns } from "@/types/IAllCampaigns";

// Stores
import { useCommonStore } from "@/stores/common";
import { useUserStore } from "@/stores/user";
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Props
const props = defineProps({
  campaignToJoin: {
    type: Object as PropType<IAllCampaigns | null>,
    default: null,
  },
});

// Reactive variables
const consentSent = ref(false);
const formData = reactive({
  profilePicture: null,
  phoneNumber: userStore.user?.phoneNumber || "",
  matches: "unlimited" as number | "unlimited",
  weekDaysMatching: true as boolean | undefined,
  weekEndMatching: true as boolean | undefined,

  criteria: userStore.user?.userCriteria?.reduce(
    (accumulator: { [key: string]: string | string[] }, criteria) => {
      accumulator[criteria.criteriaId] =
        criteria.answerKeys || criteria.answerKey || "";

      return accumulator;
    },
    {}
  ) as { [key: string]: string | string[] },
});
const formSection = ref<HTMLInputElement>();
const initialized = ref(false);
const saved = ref(false);

let formDataSaved = reactive(cloneDeep(formData));

// Form validation
const v$ = useVuelidate(
  {
    phoneNumber: {
      validPhoneNumber: withI18nMessage(
        (value: string) => {
          return value?.length != 0 ? isValidPhoneNumber(value) : true;
        },
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    },
    profilePicture: {
      size: withI18nMessage(
        (value: File | null) => {
          if (value instanceof File && typeof value.size === "number") {
            return value.size < 9 * 1000 * 1000;
          }
          return false;
        },
        {
          messagePath: () => t("validation.pictureSize"),
        }
      ),
    },
  },
  formData
);

// Event emitters
const emit = defineEmits(["close", "notify"]);

// Computed
const campaignCriteria = computed(() => {
  if (userStore.campaignDetails?.campaignCriteria) {
    return userStore.campaignDetails?.campaignCriteria;
  } else if (props.campaignToJoin) {
    return props.campaignToJoin.criteria.map((criteria) => {
      return {
        criteriaId: criteria.id,
        weight: criteria.weight,
        criteria: {
          id: criteria.id,
          labelHelper: criteria.labelHelper,
          type: criteria.type,
          options: criteria.options,
        },
      };
    });
  } else {
    return [];
  }
});

const hasStrongCriteriaSelected = computed(() => {
  // No available criteria to select
  if (!campaignCriteria.value) return true;

  for (const item of campaignCriteria.value) {
    if (item.weight === "strong" && !formData.criteria[item.criteriaId]) {
      // No option has been selected in a 'strong' criteria
      return false;
    }
  }

  // An option in 'strong' criteria have been selected
  return true;
});

const matchesOptions = computed(() => {
  return [
    {
      label: t("helperCampaignSignUpView.matchesOptions.one"),
      value: 1,
    },
    {
      label: t("helperCampaignSignUpView.matchesOptions.five"),
      value: 5,
    },
    {
      label: t("helperCampaignSignUpView.matchesOptions.unlimited"),
      value: "unlimited",
    },
  ];
});

const points = computed(() => {
  return userStore.campaignDetails.moments?.reduce((accumulator, moment) => {
    if (moment.points) {
      return accumulator + moment.points;
    } else {
      return accumulator;
    }
  }, 0);
});

// Lifecycle hooks
onBeforeMount(async () => {
  if (route?.params.campaignUuid) {
    await userStore.getCampaign(route?.params.campaignUuid as string);
  }

  if (userStore.campaign && userStore.campaign.status === "joined") {
    return router.push({ name: "relations-empty-state" });
  }

  // Initialize form data with campaign
  formData.matches = userStore.campaign?.maxHelpees || "unlimited";
  formData.weekDaysMatching = userStore.campaign?.matchedOnWeekdays;
  formData.weekEndMatching = userStore.campaign?.matchedOnWeekends;

  // Synchonize formDataSaved
  formDataSaved = cloneDeep(formData);

  if (props.campaignToJoin && userStore.user?.language) {
    locale.value = userStore.user.language;
  } else if (userStore.campaign) {
    locale.value = userStore.campaignDetails.language;
  } else {
    locale.value = navigator.language.split("-")[0];
  }

  initialized.value = true;
});

// Event Listeners
const onCloseSignUp = () => {
  emit("close");
};

// Methods
const changeCriteria = async (
  criteriaId: number,
  criteriaValue: { [key: string]: string } | string
) => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  // Make sure the value is not empty and value has changed
  if (
    JSON.stringify(formData.criteria) !== JSON.stringify(formDataSaved.criteria)
  ) {
    try {
      const objectKey =
        typeof criteriaValue === "object" ? "answerKeys" : "answerKey";

      await userStore.updateUserCriteria({
        criteriaId,
        [objectKey]: criteriaValue,
      });

      // Synchonize formDataSaved
      formDataSaved.criteria[criteriaId] = (
        typeof criteriaValue === "object"
          ? cloneDeep(criteriaValue)
          : criteriaValue
      ) as string | string[];

      saved.value = true;

      if (saved.value) {
        setTimeout(() => (saved.value = false), 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const changePhoneNumber = async () => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  v$.value.phoneNumber.$touch();

  // Make sure the value is not empty and value has changed
  if (
    (await v$.value.phoneNumber.$validate()) &&
    formData.phoneNumber !== formDataSaved.phoneNumber
  ) {
    try {
      await userStore.updateUser({
        phoneNumber: formData.phoneNumber,
      });

      // Synchonize formDataSaved
      formDataSaved.phoneNumber = formData.phoneNumber;

      saved.value = true;

      if (saved.value) {
        setTimeout(() => {
          saved.value = false;
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const getCriteriaOptions = (criteriaId: number) => {
  let campaignCriteria = undefined;

  if (userStore?.campaignDetails?.campaignCriteria) {
    campaignCriteria = userStore.campaignDetails.campaignCriteria.find(
      (criteria) => criteria.criteriaId === criteriaId
    )?.criteria;
  } else if (props.campaignToJoin?.criteria) {
    campaignCriteria = props.campaignToJoin.criteria.find(
      (criteria) => criteria.id === criteriaId
    );
  }

  if (campaignCriteria) {
    return campaignCriteria.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};

const sendConsent = async () => {
  if (!consentSent.value) {
    // Send consent
    try {
      await userStore.joinCampaign({
        campaignId: (props.campaignToJoin?.id ||
          userStore.campaign.campaignId) as number,
      });

      consentSent.value = true;

      await nextTick();

      if (formSection.value) {
        formSection.value.scrollIntoView({ inline: "start" });
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    if (props.campaignToJoin) {
      window.scrollTo(0, 0);

      await nextTick();

      emit("close");
      emit("notify");

      commonStore.setSlideOverModal({
        open: true,
      });
    } else {
      router.push({ name: "relations-empty-state" });
    }
  }
};

const submitForm = async () => {
  // Wait for formData to be updated before comparing to form data saved
  await nextTick();

  v$.value.$touch();

  // Make sure the value is not empty and value has changed
  if (
    (await v$.value.$validate()) &&
    JSON.stringify(formData) !== JSON.stringify(formDataSaved)
  ) {
    try {
      await userStore.updateCampaign({
        campaignId: (props.campaignToJoin?.id ||
          userStore.campaign.campaignId) as number,
        maxHelpees: formData.matches === "unlimited" ? null : formData.matches,
        maxHelpeesPeriod: formData.matches === "unlimited" ? "" : "week",
        matchedOnWeekdays: formData.weekDaysMatching,
        matchedOnWeekends: formData.weekEndMatching,
      });

      // Synchonize formDataSaved
      formDataSaved = cloneDeep(formData);

      saved.value = true;

      if (saved.value) {
        setTimeout(() => {
          saved.value = false;
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const uploadProfilePicture = async (profilePicture: File) => {
  if (await v$.value.profilePicture.$validate()) {
    await userStore.uploadPicture({
      profilePicture,
    });

    saved.value = true;

    if (saved.value) {
      setTimeout(() => {
        saved.value = false;
      }, 1500);
    }
  }
};
</script>

<template>
  <div v-if="initialized" class="relative" data-cy="moments-summary">
    <div
      :class="[
        'mx-auto max-w-5xl bg-cover bg-center lg:rounded-[10px]',
        {
          'h-[23vh] max-h-96':
            userStore.campaignDetails.headerUrl || campaignToJoin?.headerUrl,
          'relative min-h-[44px]': campaignToJoin,
        },
      ]"
      :style="{
        backgroundImage: `url('${
          userStore.campaignDetails.headerUrl || campaignToJoin?.headerUrl
        }')`,
      }"
    >
      <BaseIcon
        v-if="campaignToJoin"
        class="absolute left-4 top-4 rounded-full bg-background-subtle p-1.5 lg:hidden"
        color="#5F6C85"
        icon="arrow-left"
        @click="onCloseSignUp"
      />
    </div>

    <div
      :class="[
        'mx-auto mt-8 max-w-4xl px-4 text-sm text-foreground-emphasis',
        campaignToJoin ? 'lg:px-0' : 'lg:px-8',
      ]"
    >
      <div
        v-if="campaignToJoin"
        class="hidden cursor-pointer items-center lg:flex"
        @click="onCloseSignUp"
      >
        <BaseIcon
          class="mr-2 rounded-full bg-background-subtle p-1.5"
          color="#5F6C85"
          icon="arrow-left"
        />

        <span class="text-mgm-txt-sm font-medium text-foreground-default">
          {{ $t("helperCampaignSignUpView.back") }}
        </span>
      </div>

      <h1
        :class="[
          'text-mgm-txt-lg font-medium text-foreground-emphasis',
          { 'lg:mt-4': campaignToJoin },
        ]"
        data-cy="campaign-title"
      >
        {{ userStore.campaignDetails.title || campaignToJoin?.name }}
      </h1>

      <div
        :class="[
          'mt-4 flex',
          campaignToJoin?.campaignAdmin.firstname ||
          campaignToJoin?.campaignAdmin.lastname
            ? 'justify-between'
            : 'justify-end',
        ]"
      >
        <div
          v-if="
            campaignToJoin?.campaignAdmin.firstname ||
            campaignToJoin?.campaignAdmin.lastname
          "
        >
          <span class="text-mgm-txt-sm font-normal text-foreground-default">
            {{ $t("generic.invited") }}
          </span>

          <div class="mt-1 flex items-center">
            <BaseAvatar
              :src="campaignToJoin?.campaignAdmin.profilePictureUrl"
              :alt="`${$t('generic.profilePicture')} ${
                campaignToJoin?.campaignAdmin.firstname
              } ${campaignToJoin?.campaignAdmin.lastname}`"
              :fallback="
                campaignToJoin?.campaignAdmin.firstname?.charAt(0) +
                campaignToJoin?.campaignAdmin.lastname?.charAt(0)
              "
              size="sm"
            />

            <span
              class="ml-2 text-mgm-txt-sm font-medium text-foreground-emphasis"
            >
              {{
                (campaignToJoin?.campaignAdmin.firstname
                  ? `${campaignToJoin?.campaignAdmin.firstname} `
                  : "") +
                (campaignToJoin?.campaignAdmin.lastname
                  ? campaignToJoin?.campaignAdmin.lastname
                  : "")
              }}
            </span>
          </div>
        </div>

        <div
          v-if="
            campaignToJoin?.helpersProfilePicture &&
            campaignToJoin?.helpersProfilePicture?.length > 0
          "
        >
          <span
            class="block text-right text-mgm-txt-sm font-normal text-foreground-default"
          >
            {{ $t("generic.community") }}
          </span>

          <BaseAvatar
            class="mt-1 justify-end"
            :group-avatars-urls="
              campaignToJoin.helpersProfilePicture
                .map((helper) => ({
                  url: helper,
                }))
                .slice(0, 4)
            "
            size="sm"
            type="group"
            plus
          />
        </div>
      </div>

      <div :class="['flex items-center', { 'mt-4': campaignToJoin }]">
        <BaseBadge
          v-if="campaignToJoin?.potentialPoints || points"
          class="mr-2"
          :text="campaignToJoin?.potentialPoints || `${points}`"
          size="md"
          icon-type="icon"
          icon-name="star"
        />

        <BaseBadge
          v-if="campaignToJoin?.helpersCount"
          class="text-mgm-txt-sm font-medium"
          :text="`${campaignToJoin?.helpersCount} helpers`"
          size="md"
          color="purple"
          icon-type="icon"
          icon-name="users"
        />
      </div>

      <div
        data-cy="campaign-description"
        class="description scrollbar-hide prose-sm mx-auto mt-8 overflow-y-scroll font-sans text-mgm-txt-sm font-normal text-foreground-default"
        v-html="
          userStore.campaignDetails.description || campaignToJoin?.description
        "
      ></div>

      <h2 class="mt-4 text-mgm-txt-md font-medium text-foreground-emphasis">
        Moments
      </h2>

      <p class="mt-1 text-mgm-txt-sm font-normal text-foreground-default">
        {{ $t("helperCampaignSignUpView.momentsDescription") }}
      </p>

      <div
        v-for="(moment, momentIndex) in userStore.campaignDetails.moments ||
        campaignToJoin?.moments"
        :key="momentIndex"
        class="mt-4 rounded-[5px] border border-border-subtle p-4"
        data-cy="moment"
      >
        <h3 class="text-mgm-txt-sm font-medium text-foreground-emphasis">
          {{ moment.name }}
        </h3>

        <h4 class="mt-1 text-mgm-txt-sm font-normal text-foreground-default">
          {{
            `${$t(
              "helperCampaignSignUpView.validation"
            )} ${moment.validationRules?.join(", ")}`
          }}
        </h4>

        <div class="mt-4 flex items-center">
          <BaseBadge
            v-if="moment.points"
            class="mr-2"
            :text="moment.points"
            size="md"
            icon-type="icon"
            icon-name="star"
          />

          <BaseBadge
            v-if="moment.maxDurationActionInDays"
            :text="`${moment.maxDurationActionInDays} ${$t('generic.days')}`"
            color="purple"
            size="md"
            icon-type="icon"
            icon-name="calendar"
          />
        </div>
      </div>

      <div v-if="consentSent" ref="formSection" data-cy="form">
        <div class="mt-8 flex items-center">
          <BaseIcon icon="check" color="#03874C" bg-color="#ECFDF3" />

          <h2 class="ml-4 text-mgm-txt-md font-medium text-foreground-emphasis">
            {{ $t("helperCampaignSignUpView.completeProfile") }}
          </h2>
        </div>

        <p class="mt-2 text-mgm-txt-sm font-normal text-foreground-default">
          {{ $t("helperCampaignSignUpView.completeProfileDescription") }}
        </p>

        <FieldAvatarUploader
          id="profilePicture"
          v-model="formData.profilePicture"
          class="mt-4"
          :cta="$t('generic.change')"
          :label="$t('generic.profilePicture')"
          :locale="$i18n.locale"
          :preview-url="userStore.user?.profilePictureUrl || undefined"
          :validation="v$.profilePicture"
          data-cy="profile-pic"
          @change="uploadProfilePicture"
        />

        <FieldPhoneNumber
          id="phone"
          v-model="formData.phoneNumber"
          data-cy="phone"
          class="mt-4"
          :locale="$i18n.locale"
          :label="$t('generic.phoneNumber')"
          :validation="v$.phoneNumber"
          @change="changePhoneNumber"
        />

        <FieldSelect
          v-for="campaignCriteriaItem in campaignCriteria"
          :id="campaignCriteriaItem.criteriaId"
          :key="campaignCriteriaItem.criteriaId"
          v-model="formData.criteria[campaignCriteriaItem.criteriaId]"
          class="mt-4"
          :label="campaignCriteriaItem.criteria?.labelHelper"
          :options="getCriteriaOptions(campaignCriteriaItem.criteriaId)"
          :multiple="campaignCriteriaItem.criteria?.type === 'multiSelect'"
          :required="campaignCriteriaItem.weight === 'strong'"
          data-cy="criteria"
          @change="changeCriteria"
        />

        <FieldSelect
          id="matches"
          v-model="formData.matches"
          class="mt-4"
          :label="$t('generic.matchesLabel')"
          :options="matchesOptions"
          data-cy="matches"
          @change="submitForm"
        />

        <div class="mt-4">
          <div class="flex items-start">
            <FieldLabel :label="$t('generic.matchesTimingLabel')" />

            <span
              class="ml-1 inline-block align-top text-mgm-txt-sm font-medium text-foreground-brand-default"
            >
              *
            </span>
          </div>

          <FieldCheckbox
            id="weekDaysMatching"
            v-model="formData.weekDaysMatching"
            class="mt-2.5"
            data-cy="matching-weekday"
            @change="submitForm"
          >
            <span class="block text-mgm-txt-md font-normal">
              {{ $t("generic.weekDaysMatching") }}
            </span>
          </FieldCheckbox>

          <FieldCheckbox
            id="weekEndMatching"
            v-model="formData.weekEndMatching"
            class="mt-2"
            data-cy="matching-weekend"
            @change="submitForm"
          >
            <span class="block text-mgm-txt-md font-normal"> Week-ends </span>
          </FieldCheckbox>
        </div>

        <span
          v-if="saved"
          id="saved"
          class="absolute bottom-[85px] right-0 flex items-center text-mgm-txt-sm font-normal text-foreground-brand-default"
        >
          <BaseIcon
            class="mr-1 pb-0.5"
            :size="12"
            color="#444CE7"
            icon="check"
          />
          saved
        </span>
      </div>
    </div>

    <div
      :class="[
        'gradient__white__01 sticky bottom-0 mx-auto max-w-4xl bg-background-default px-4 pb-4 pt-8',
      ]"
    >
      <BaseButton
        class="mb-3 w-full"
        :text="
          consentSent
            ? campaignToJoin
              ? $t('generic.confirm')
              : $t('helperCampaignSignUpView.goToDashboard')
            : $t('helperCampaignSignUpView.enrollAsHelper')
        "
        data-cy="signup-helper-complete"
        size="md"
        :disabled="
          consentSent &&
          ((!formData.weekDaysMatching && !formData.weekEndMatching) ||
            !hasStrongCriteriaSelected)
        "
        @click="sendConsent"
      />

      <div
        v-if="!consentSent"
        class="text-center text-mgm-txt-xs font-normal text-foreground-default"
      >
        {{ $t("generic.termsAndPrivacy.part1") }}
        <a
          class="font-medium text-foreground-brand-default"
          href="https://www.magma.app/legals/helpers-terms-of-use"
          target="_blankk"
        >
          {{ $t("generic.termsAndPrivacy.part2") }}
        </a>
        {{ $t("generic.termsAndPrivacy.part3") }}
        <a
          class="font-medium text-foreground-brand-default"
          href="https://www.magma.app/legals/privacy-policy"
          target="_blank"
        >
          {{ $t("generic.termsAndPrivacy.part4") }}
        </a>
      </div>
    </div>
  </div>

  <BaseLoader v-else class="min-h-screen" />
</template>

<style>
.description h1,
.description h2,
.description h3,
.description h4,
.description h5,
.description h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1c1f27;
}

.description p,
.description span,
.description a {
  color: #5f6c85 !important;
}

.description p:first-child,
.description img:first-child {
  margin-top: 0px !important;
}
</style>
