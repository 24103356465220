<script setup lang="ts">
import { FieldInput } from '@magma-app/magma-lapilli';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps([
	'modelValue',
	'validationFirstName',
	'validationLastName',
	'disableEmail',
]);
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

onMounted(() => {
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium mb-6">
			{{ t('helperOnboarding.name.title') }}
		</h2>
		<FieldInput
			type="text"
			id="firstName"
			name="firstName"
			class="mb-6"
			placeholder="John"
			@input="
				emit('update:modelValue', {
					firstName: $event.target.value,
				})
			"
			:model-value="props.modelValue.firstName"
			:label="t('helperOnboarding.name.labelFirstName')"
			:validation="props.validationFirstName"
			required
		/>
		<FieldInput
			type="text"
			id="lastName"
			name="lastName"
			placeholder="Doe"
			@input="
				emit('update:modelValue', {
					lastName: $event.target.value,
				})
			"
			:model-value="props.modelValue.lastName"
			:label="t('helperOnboarding.name.labelLastName')"
			:validation="props.validationLastName"
			required
		/>
	</div>
</template>
