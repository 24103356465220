export const hexToRgb = (hex: string): string | undefined => {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

	const rgb = result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
			}
		: undefined;

	return rgb ? `${rgb.r}, ${rgb.g}, ${rgb.b}` : undefined;
};
