<script setup lang="ts">
// NPM
import { useI18n } from 'vue-i18n';

// Components
import { BaseBadge, BaseButton, BaseIcon } from '@magma-app/magma-lapilli';

// Types
import type { PropType } from 'vue';
import type { IRelationMoment } from '@/types/IRelationMoment';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Stores
import { useCommonStore } from '@/stores/common';
import { useRelationsStore } from '@/stores/relations';
const commonStore = useCommonStore();
const relationsStore = useRelationsStore();

// Plugins
const { t } = useI18n({ useScope: 'global' });

// Props
const props = defineProps({
	moment: {
		type: Object as PropType<IRelationMoment>,
		required: true,
	},
});

// Event emitters
const emit = defineEmits(['close']);

// Event listeners
const onCloseModal = () => {
	emit('close');
};

const onMarkAsDone = async (relationMomentId: number) => {
	let modalText: {
		title: string;
		description: string;
		form: null | {
			rating: {
				label: string;
				negative: string;
			};
			comment: {
				label: string;
				placeholder: string;
			};
		};
	} = {
		title: t('generic.doneModalTitle'),
		description:
			t('generic.doneModalDescription') +
			relationsStore.relation?.relation?.helpee?.firstname +
			t('generic.doneModalDescriptionBis'),
		form: null,
	};

	if (props.moment.isLast) {
		modalText.description = `${t(
			'generic.feedbackModalDescription'
		)}${relationsStore.relation?.relation?.helpee?.firstname}.`;
	} else if (props.moment.helper?.needFeedback && !props.moment.isLast) {
		modalText = {
			title: t('baseRelationMomentDetails.modal.helperNeedFeedback.title'),
			description: `${t(
				'baseRelationMomentDetails.modal.helperNeedFeedback.description.partOne'
			)}${relationsStore.relation?.relation?.helpee?.firstname}${t(
				'baseRelationMomentDetails.modal.helperNeedFeedback.description.partTwo'
			)}`,
			form: {
				rating: {
					label: t(
						'baseRelationMomentDetails.modal.helperNeedFeedback.question'
					),
					negative: t('generic.doubtful'),
				},
				comment: {
					label: t('generic.comments'),
					placeholder: t(
						'baseRelationMomentDetails.modal.helperNeedFeedback.placeholder'
					),
				},
			},
		};
	}

	commonStore.setManageRelationModal({
		open: true,
		type: 'helper',
		role: props.moment.helper?.needFeedback
			? 'done-with-feedback'
			: 'done-without-feedback',
		feedbackType: 'full',
		relationMomentId: relationMomentId,
		title: modalText.title,
		description: modalText.description,
		formText: modalText.form,
		icon:
			props.moment.helper?.needFeedback && !props.moment.isLast ? true : false,
	});

	AnalyticsHelper.trackEvent('validate_moment', {
		event_properties: 'moment_details',
		user_properties: 'helper',
	});

	emit('close');
};

const onSendMessage = () => {
	commonStore.setTouchpointsModal({
		moment: props.moment,
		name:
			relationsStore.relation?.relation?.helpee?.firstname ||
			relationsStore.relation?.relation?.helper?.firstname,
		open: true,
		touchpoints:
			relationsStore.relation?.relation?.helpee?.touchPoints ||
			relationsStore.relation?.relation?.helper?.touchPoints,
	});

	AnalyticsHelper.trackEvent('send_message', {
		event_properties: 'moment_details',
		user_properties: 'helper',
	});
};
</script>

<template>
	<div
		class="flex flex-1 flex-col justify-between px-4 pb-6 lg:m-10 lg:rounded-[32px] lg:border lg:border-border-subtle lg:p-10 lg:shadow-[0_12px_16px_-4px_rgba(28,31,39,0.08),0_4px_6px_-2px_rgba(28,31,39,0.03)]"
	>
		<div>
			<div class="hidden lg:mb-6 lg:flex lg:justify-between">
				<svg
					width="56"
					height="56"
					viewBox="0 0 56 56"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect x="4" y="4" width="48" height="48" rx="24" fill="#E0EAFF" />
					<path
						d="M32 20H34C34.5304 20 35.0391 20.2107 35.4142 20.5858C35.7893 20.9609 36 21.4696 36 22V36C36 36.5304 35.7893 37.0391 35.4142 37.4142C35.0391 37.7893 34.5304 38 34 38H22C21.4696 38 20.9609 37.7893 20.5858 37.4142C20.2107 37.0391 20 36.5304 20 36V22C20 21.4696 20.2107 20.9609 20.5858 20.5858C20.9609 20.2107 21.4696 20 22 20H24M25 18H31C31.5523 18 32 18.4477 32 19V21C32 21.5523 31.5523 22 31 22H25C24.4477 22 24 21.5523 24 21V19C24 18.4477 24.4477 18 25 18Z"
						stroke="#444CE7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<rect
						x="4"
						y="4"
						width="48"
						height="48"
						rx="24"
						stroke="#EEF4FF"
						stroke-width="8"
					/>
				</svg>

				<BaseIcon
					icon="close"
					color="#5F6C85"
					hover-state
					@click="onCloseModal"
				/>
			</div>

			<h2 class="mb-4 text-mgm-txt-lg font-medium text-foreground-emphasis">
				{{ moment.name }}
			</h2>

			<div
				class="mb-2 flex items-center text-mgm-txt-sm font-medium text-foreground-default"
			>
				<template v-if="moment.daysLeft">
					<BaseIcon class="mr-2.5" color="#5F6C85" icon="calendar" />

					<span class="mr-4">
						{{
							moment.daysLeft +
							(moment.daysLeft > 1
								? $t('generic.daysLeft')
								: $t('generic.dayLeft'))
						}}
					</span>
				</template>

				<template v-if="moment.points">
					<BaseIcon class="mr-2.5" color="#5F6C85" icon="star" />

					<span>{{ `${moment.points}pts` }}</span>
				</template>
			</div>

			<p
				class="mb-6 text-mgm-txt-sm font-normal text-foreground-default"
				v-html="moment.description"
			></p>

			<h3
				v-if="moment.resources?.length != 0"
				class="mgm-txt-sm font-medium text-foreground-emphasis"
			>
				Documents
			</h3>

			<BaseBadge
				v-for="(resource, resourceIndex) in moment.resources"
				:key="resourceIndex"
				class="mt-2"
				size="lg"
				:text="resource.name"
				:link="resource.url"
			/>
		</div>

		<div
			v-if="
				!relationsStore.relation?.isReported &&
				!['skipped', 'validatedByAdmin'].includes(moment.status)
			"
			class="lg:flex"
		>
			<BaseButton
				v-if="moment.isCurrentMoment && moment.status != 'notStarted'"
				data-cy="btn-message"
				class="mb-4 w-full lg:mb-0 lg:mr-1"
				:text="$t('generic.sendMessage')"
				size="md"
				state="scdr"
				@click="onSendMessage"
			/>

			<BaseButton
				v-if="
					moment.helper?.needHelperValidation &&
					!['notStarted', 'validated'].includes(moment.status)
				"
				data-cy="btn-done"
				class="w-full lg:ml-1"
				:text="$t('generic.validateMoment')"
				size="md"
				@click="onMarkAsDone(moment.relationMomentId)"
			/>
		</div>
	</div>
</template>
