import Api from '@/services/api';
import { defineStore } from 'pinia';

import type {
	CampaignSignUpHelperFillCriteriaRequestType,
	CampaignSignUpHelperFillCriteriaResponseType,
	CampaignSignUpHelperFillTouchPointsRequestType,
	CampaignSignUpHelperFillTouchPointsResponseType,
	CampaignSignUpHelperGetProtectedSignUpHelperResponseType,
	CampaignSignUpHelperManagePhoneNumberRequestType,
	CampaignSignUpHelperManagePhoneNumberResponseType,
	CampaignSignUpHelperRegisterHelperByEmailRequestType,
	CampaignSignUpHelperRegisterHelperByEmailResponseType,
	CampaignSignUpHelperUpdateInfoRequestType,
	CampaignSignUpHelperUpdateInfoResponseType,
	CampaignSignUpHelperUpdateProfilePictureRequestType,
	CampaignSignUpHelperUpdateProfilePictureResponseType,
	CampaignSignUpHelperVerifyPhoneNumberCodeRequestType,
	CampaignSignUpHelperVerifyPhoneNumberCodeResponseType,
} from '@magma-app/magma-types/campaign-sign-up-helper';

export const useHelperSignupStore = defineStore('helperSignup', {
	state: () => {
		return {
			campaignData:
				null as CampaignSignUpHelperGetProtectedSignUpHelperResponseType | null,
		};
	},
	actions: {
		async getPublicCampaignData(payload: { campaignUuid: string }) {
			const response =
				await Api.userDashboard().helperCampaignSignUp.getPublicCampaignData(
					payload.campaignUuid
				);

			this.campaignData = response.data;

			return response.data;
		},

		async getAuthCampaignData(payload: { campaignUuid: string }) {
			const response =
				await Api.userDashboard().helperCampaignSignUp.getAuthCampaignData(
					payload.campaignUuid
				);

			if (response.data.error) return null;

			this.campaignData = response.data;

			return response.data;
		},

		async createHelperByEmail(
			payload: CampaignSignUpHelperRegisterHelperByEmailRequestType
		): Promise<CampaignSignUpHelperRegisterHelperByEmailResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.createHelperWithEmail(
					payload
				);

			return response.data;
		},

		async registerPhoneNumber(
			payload: CampaignSignUpHelperManagePhoneNumberRequestType
		): Promise<CampaignSignUpHelperManagePhoneNumberResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.registerPhoneNumber(
					payload
				);

			return response.data;
		},

		async verifyCode(
			payload: CampaignSignUpHelperVerifyPhoneNumberCodeRequestType
		): Promise<CampaignSignUpHelperVerifyPhoneNumberCodeResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.verifyCode(payload);

			return response.data;
		},

		async updateInfo(
			payload: CampaignSignUpHelperUpdateInfoRequestType
		): Promise<CampaignSignUpHelperUpdateInfoResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.updateInfo(payload);

			return response.data;
		},

		async updateProfilePicture(
			payload: CampaignSignUpHelperUpdateProfilePictureRequestType
		): Promise<CampaignSignUpHelperUpdateProfilePictureResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.updateProfilePicture(
					payload
				);

			return response.data;
		},

		async sendCriteria(
			payload: CampaignSignUpHelperFillCriteriaRequestType
		): Promise<CampaignSignUpHelperFillCriteriaResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.sendCriteria(payload);

			return response.data;
		},

		async sendTouchPoints(
			payload: CampaignSignUpHelperFillTouchPointsRequestType
		): Promise<CampaignSignUpHelperFillTouchPointsResponseType> {
			const response =
				await Api.userDashboard().helperCampaignSignUp.sendTouchPoints(payload);

			return response.data;
		},
	},
});
