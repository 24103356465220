<script setup lang="ts">
// NPM
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

// Components
import { BaseButton } from "@magma-app/magma-lapilli";

// Stores
import { useCommonStore } from "@/stores/common";
const commonStore = useCommonStore();

// Props
defineProps({
  description: {
    type: String,
    default: null,
  },
  helpeeName: {
    type: String,
    default: null,
  },
  helperName: {
    type: String,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
  touchpoint: {
    type: String,
    default: null,
  },
});

// Reactive variables
const root = ref<HTMLInputElement>();

// Methods
const onCloseModal = async () => {
  commonStore.closeCallTextModal();
};
</script>

<template>
  <TransitionRoot as="template" :show="open" data-cy="modal">
    <Dialog
      :initial-focus="root"
      as="div"
      class="relative z-10"
      @close="onCloseModal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div ref="root" class="flex min-h-full items-center justify-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="mx-4 max-w-sm transform overflow-hidden rounded-[15px] bg-background-default shadow-xl transition-all md:mx-auto"
            >
              <div
                class="border-b border-border-subtle p-2.5 text-center text-mgm-txt-sm font-normal capitalize text-foreground-default"
              >
                {{ `${$t("generic.contact")} ${helpeeName || helperName}...` }}
              </div>

              <p
                class="scrollbar-hide max-h-44 overflow-scroll text-mgm-txt-sm font-normal text-foreground-default"
                v-html="description"
              ></p>

              <div class="flex h-52 w-80 flex-col">
                <div
                  class="flex flex-1 items-center justify-center border-b border-border-subtle px-6"
                >
                  <a
                    class="mr-2 flex h-24 w-36 cursor-pointer flex-col content-center items-center justify-center rounded-[5px] border border-border-default py-2 text-mgm-txt-sm hover:bg-background-subtle-hover"
                    :href="`sms:${touchpoint}`"
                    target="_self"
                  >
                    <span class="text-mgm-dspl-md">💬</span>
                    via SMS
                  </a>

                  <a
                    class="flex h-24 w-36 cursor-pointer flex-col content-center items-center justify-center rounded-[5px] border border-border-default py-2 text-mgm-txt-sm hover:bg-background-subtle-hover"
                    :href="`tel:${touchpoint}`"
                    target="_self"
                  >
                    <span class="text-mgm-dspl-md">📞</span>
                    {{ $t("callTextModal.call") }}
                  </a>
                </div>

                <BaseButton
                  class="mx-auto my-1"
                  state="trtr"
                  :text="$t('generic.cancel')"
                  danger
                  @click="onCloseModal"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
