<script setup lang="ts">
import { FieldInput, BaseIcon } from '@magma-app/magma-lapilli';
import { onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps(['modelValue', 'validation']);
const emit = defineEmits(['update:modelValue', 'resendCode', 'goBack']);

const { t } = useI18n();

const timerBeforeResend = ref<number>(30);

const handleResendCode = () => {
	emit('resendCode');
	timerBeforeResend.value = 30;
};

watchEffect(() => {
	if (timerBeforeResend.value > 0) {
		setTimeout(() => {
			timerBeforeResend.value--;
		}, 1000);
	}
});

onMounted(() => {
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ t('helperOnboarding.verification.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ t('helperOnboarding.verification.subtitle.text') }}(<button
				class="underline"
				@click="emit('goBack')"
			>
				{{ t('helperOnboarding.verification.subtitle.link') }}</button
			>)
		</h3>
		<FieldInput
			id="code"
			type="number"
			autocomplete="one-time-code"
			max-length="6"
			min-length="6"
			@input="emit('update:modelValue', $event.target.value)"
			:model-value="props.modelValue"
			data-cy="code"
			class="mt-4"
			:locale="$i18n.locale"
			:label="$t('helpeeCampaignSignUpView.codeLabel')"
			:validation="props.validation"
			required
		/>

		<div class="flex items-center flex-col mt-4">
			<p class="text-foreground-default text-mgm-txt-sm mb-1">
				{{ $t('helpeeCampaignSignUpView.noReceive') }}
			</p>
			<p
				v-if="timerBeforeResend > 0"
				class="text-foreground-default text-mgm-txt-sm mb-1 font-medium"
			>
				{{ $t('helpeeCampaignSignUpView.code.resend') }}
				{{ timerBeforeResend }}
				{{ $t('helpeeCampaignSignUpView.code.seconds') }}
			</p>
			<button
				v-else
				class="text-foreground-brand-default text-mgm-txt-sm flex items-center"
				@click="handleResendCode"
			>
				<BaseIcon
					icon="refresh-cw"
					class="mr-2 -scale-x-100"
					color="rgb(68 76 231)"
				/>
				<span class="underline">{{
					$t('helpeeCampaignSignUpView.resendCode')
				}}</span>
			</button>
		</div>
	</div>
</template>
