<script setup lang="ts">
// NPM
import {
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// Components
import {
  BaseButton,
  BaseLoader,
  FieldCheckbox,
  FieldInput,
  FieldPhoneNumber,
  FieldSelect,
} from "@magma-app/magma-lapilli";

// Stores
import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

// Plugins
const route = useRoute();
const { t, locale } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const containerWidth = ref(0);
const formStep = ref(true);
const initialized = ref(false);
const redirectionStep = ref(false);
const root = ref<HTMLInputElement>();
const isLoading = ref(false);

const formData = reactive({
  email: authStore.referredSignUpData?.referred.email || "",
  firstname: authStore.referredSignUpData?.referred.firstname || "",
  lastname: authStore.referredSignUpData?.referred.lastname || "",
  phoneNumber: authStore.referredSignUpData?.referred.phoneNumber || "",
  criteria: null as null | string,
  consent: undefined,
});

// Form validation
const v$ = useVuelidate(
  {
    email: {
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    firstname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    lastname: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    criteria: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    phoneNumber: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
      validPhoneNumber: withI18nMessage(
        (value: string) => isValidPhoneNumber(value),
        {
          messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
        }
      ),
    },
    consent: {
      required: withI18nMessage((value: boolean) => value === true, {
        messagePath: () => t("validation.value"),
      }),
    },
  },
  formData
);

// Lifecycle hooks
onMounted(async () => {
  if (authStore.referredSignUpData) {
    locale.value = authStore.referredSignUpData.referred.language;
  }

  initialized.value = true;

  await computeContainerWidth();

  window.addEventListener("resize", computeContainerWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", computeContainerWidth);
});

// Watchers
watch(
  () => formData.email,
  async () => await v$.value.email.$validate()
);

watch(
  () => formData.firstname,
  async () => await v$.value.firstname.$validate()
);

watch(
  () => formData.lastname,
  async () => await v$.value.lastname.$validate()
);

watch(
  () => formData.phoneNumber,
  async () => await v$.value.phoneNumber.$validate()
);

// Methods
const computeContainerWidth = async () => {
  await nextTick();

  const container = root.value?.querySelector(".js-container") as HTMLElement;

  if (container) {
    containerWidth.value = container.offsetWidth || 0;
  }
};

const getCriteriaOptions = () => {
  if (authStore.referredSignUpData?.strongCriteria.options) {
    return authStore.referredSignUpData?.strongCriteria.options.map(
      (criteriaOption: { key: string; value: string }) => ({
        label: criteriaOption.value,
        value: criteriaOption.key,
      })
    );
  } else {
    return [];
  }
};

const signUp = async () => {
  isLoading.value = true;
  try {
    v$.value.$touch();

    if (await v$.value.$validate()) {
      const objectKey =
        typeof formData.criteria === "object" ? "answerKeys" : "answerKey";

      const payload = {
        referredUuid: route?.params.referredUuid as string,
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        strongUserCriteria: {
          criteriaId: `${authStore.referredSignUpData?.strongCriteria.id}`,
          [objectKey]: formData.criteria,
        },
      };

      await authStore.sendReferredSignUpData(payload);

      v$.value.$reset();
      authStore.referredSignUpData = null;

      formStep.value = false;
      redirectionStep.value = true;

      await nextTick();

      setTimeout(() => {
        if (authStore.referredRelationUrl) {
          location.href = authStore.referredRelationUrl;
        }
      }, 1000);
    }
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};
</script>

<template>
  <div ref="root" class="flex flex-col">
    <div
      v-if="initialized"
      class="scrollbar-hide mx-auto w-full bg-background-default md:relative md:mt-12 md:w-[710px] md:rounded-[15px] md:shadow-[0px_20px_24px_-4px_rgba(28,31,39,0.08),0px_8px_8px_-4px_rgba(28,31,39,0.03)]"
    >
      <div ref="root" class="js-container px-4 pb-4 pt-8 md:px-10">
        <template v-if="formStep">
          <p class="text-mgm-txt-lg font-medium text-foreground-emphasis">
            <span class="block">
              {{
                `${$t("helpeeReferralView.hi.partOne")} ${
                  authStore.referredSignUpData?.referred.firstname
                },`
              }}
            </span>
            <span class="block">
              {{
                `${$t("helpeeReferralView.hi.partTwo")} ${
                  authStore.referredSignUpData?.referrer.firstname
                } ${$t("helpeeReferralView.hi.partThree")} ${
                  authStore.referredSignUpData?.campaign.title
                }`
              }}
            </span>
          </p>

          <FieldInput
            id="email"
            v-model="formData.email"
            class="mt-6"
            role="email"
            type="email"
            :locale="$i18n.locale"
            :label="$t('generic.yourEmail')"
            :placeholder="`${$t('generic.example')}@email.com`"
            autocomplete="email"
            required
            :validation="v$.email"
          />

          <FieldInput
            id="firstname"
            v-model="formData.firstname"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.firstNameLabel')"
            placeholder="John"
            required
            :validation="v$.firstname"
          />

          <FieldInput
            id="lastname"
            v-model="formData.lastname"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.lastNameLabel')"
            placeholder="Doe"
            required
            :validation="v$.lastname"
          />

          <FieldPhoneNumber
            id="phone"
            v-model="formData.phoneNumber"
            class="mt-4"
            :locale="$i18n.locale"
            :label="$t('generic.phone')"
            required
            :validation="v$.phoneNumber"
          />

          <FieldSelect
            v-if="authStore.referredSignUpData?.strongCriteria.options"
            :id="authStore.referredSignUpData?.strongCriteria.id"
            v-model="formData.criteria"
            class="mt-4"
            :label="authStore.referredSignUpData?.strongCriteria.labelReferred"
            :options="getCriteriaOptions()"
            :multiple="
              authStore.referredSignUpData?.strongCriteria.type ===
              'multiSelect'
            "
            :validation="v$.criteria"
            required
          />

          <FieldCheckbox
            id="consent"
            v-model="formData.consent"
            class="mt-6"
            :validation="v$.consent"
            required
          >
            <span class="text-mgm-txt-sm">
              {{ $t("generic.agree") }}

              <a
                class="font-medium text-foreground-brand-default"
                href="https://www.magma.app/legals/helpers-terms-of-use"
                target="_blank"
              >
                {{ $t("generic.termsAndPrivacy.part2") }}
              </a>

              {{ $t("generic.termsAndPrivacy.part3") }}

              <a
                class="font-medium text-foreground-brand-default"
                href="https://www.magma.app/legals/privacy-policy"
                target="_blank"
              >
                {{ $t("generic.termsAndPrivacy.part4") }}
              </a>
            </span>
          </FieldCheckbox>

          <BaseButton
            class="mt-6 w-full"
            :disabled="v$.$invalid || v$.$error || isLoading"
            :text="$t('helpeeReferralView.accept')"
            size="md"
            @click="signUp"
          />
        </template>

        <div v-else-if="redirectionStep">
          <BaseLoader />

          <h3
            class="mt-6 text-center text-mgm-txt-lg font-medium text-foreground-emphasis"
          >
            {{ $t("helpeeReferralView.redirect") }}
          </h3>

          <p
            class="mt-2 text-center text-mgm-txt-sm font-normal text-foreground-default"
          >
            {{ $t("helpeeReferralView.redirectMessage") }}
          </p>
        </div>

        <a
          class="mx-auto mt-8 block text-center text-mgm-txt-xs font-normal text-border-default hover:text-foreground-subtle focus:text-foreground-subtle"
          href="https://www.magma.app/"
          target="_blank"
        >
          {{ $t("generic.powered") }}
          <span class="font-medium text-foreground-subtle"> Magma app </span>
        </a>
      </div>
    </div>

    <BaseLoader v-else class="min-h-screen" />
  </div>
</template>
