<script lang="ts" setup></script>

<template>
	<div>
		<div
			class="sm:rounded-lg sm:border sm:border-border-subtle p-10 sm:shadow-lg bg-white w-full sm:w-[480px]"
		>
			<div
				class="bg-[url('/img/wave_background.png')] bg-cover bg-center w-full h-[300px] flex justify-center items-center"
			>
				<div
					class="w-12 h-12 bg-white rounded-xl border border-subtle shadow-xl mt-10 flex items-center justify-center"
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
							stroke="#1C1F27"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="p-6 text-center">
				<h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
					{{ $t('reviews.email.title') }}
				</h3>
				<p class="text-mgm-txt-sm text-foreground-default mb-10">
					{{ $t('reviews.email.subtitle') }}
				</p>
			</div>
		</div>
	</div>
</template>
