<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { ReviewCard } from '@magma-app/magma-lapilli';
import { onMounted } from 'vue';
import { useReviewsStore } from '@/stores/reviews';
import { reactive } from 'vue';
import Infos from '../../components/views/reviews/infos.vue';
import Resume from '../../components/views/reviews/resume.vue';
import Email from '../../components/views/reviews/email.vue';
import { hexToRgb } from '@/utils/color';

type Step = 'review' | 'info' | 'resume' | 'email';

const route = useRoute();
const uuid = route.params.uuid as string;
const token = localStorage.getItem('jwtToken');

const reviewsStore = useReviewsStore();

const init = ref(false);
const currentStep = ref<Step>('review');
const data = reactive({
	rating: 0,
	comment: '',
	firstName: '',
	lastName: '',
	email: '',
	position: '',
	photoUrl: undefined as string | undefined,
	photoFile: null,
	isHelper: false,
});

const handleGoToInfos = async (infos: { rating: number; comment: string }) => {
	if (reviewsStore.reviews?.isHelper) {
		await reviewsStore.submitConnectedReview({
			comment: infos.comment,
			rating: infos.rating,
			uuid,
		});

		data.rating = infos.rating;
		data.comment = infos.comment;
		currentStep.value = 'resume';
	} else {
		data.rating = infos.rating;
		data.comment = infos.comment;
		currentStep.value = 'info';
	}
};

const handleSubmitInfos = (infos: any) => {
	data.firstName = infos.d.firstName;
	data.lastName = infos.d.lastName;
	data.email = infos.d.email;
	data.position = infos.d.position;
	data.photoUrl = infos.infos.user.profilePictureUrl;
	data.isHelper = infos.infos.isHelper;
	currentStep.value = 'resume';
};

onMounted(async () => {
	(await reviewsStore.getReviewStatus(uuid, token ? true : false)) as any;

	data.firstName = reviewsStore.reviews?.user.firstname || '';
	data.lastName = reviewsStore.reviews?.user.lastname || '';
	data.email = reviewsStore.reviews?.user.email || '';
	data.position = reviewsStore.reviews?.user.position || '';
	data.photoUrl = reviewsStore.reviews?.user.profilePictureUrl || undefined;
	data.comment = reviewsStore.reviews?.comment || '';
	data.rating = reviewsStore.reviews?.rating || 0;
	data.isHelper = reviewsStore.reviews?.isHelper || false;

	if (reviewsStore.reviews?.isCompleted) {
		currentStep.value = 'resume';
	}

	init.value = true;
});
</script>

<template>
	<main
		v-if="init"
		class="w-dvw h-dvh bg-white sm:bg-background-subtle flex flex-col sm:justify-center items-center"
	>
		<ReviewCard
			v-if="currentStep === 'review'"
			class="bg-white w-full sm:w-[480px] shadow-none sm:shadow-lg border-none sm:border !h-dvh sm:!h-auto"
			:custom-color="
				reviewsStore.reviews?.organization.primaryColor || undefined
			"
			:customRGBColor="
				hexToRgb(reviewsStore.reviews?.organization.primaryColor as string)
			"
			:title="$t('reviews.share.title')"
			:logoUrl="reviewsStore.reviews?.organization.logoUrl || ''"
			:intro="{
				text: reviewsStore.reviews?.message || '',
				avatarUrl: reviewsStore.reviews?.contactAdmin.profilePictureUrl || '',
				name: `${reviewsStore.reviews?.contactAdmin.firstname} ${reviewsStore.reviews?.contactAdmin.lastname}`,
				role: reviewsStore.reviews?.contactAdmin.position || '',
			}"
			:rateTitle="$t('reviews.share.rate')"
			:commentLabel="$t('reviews.share.comment')"
			:submitLabel="$t('reviews.share.submit')"
			@submit="handleGoToInfos($event)"
			:stars="data.rating"
			:comment="data.comment"
		/>
		<Infos
			v-if="currentStep === 'info'"
			:logoUrl="reviewsStore.reviews?.organization.logoUrl || ''"
			:comment="data.comment"
			:rating="data.rating"
			:data="data"
			:customColor="
				reviewsStore.reviews?.organization.primaryColor || undefined
			"
			@back="currentStep = 'review'"
			@submit="handleSubmitInfos"
		/>
		<Resume
			v-if="currentStep === 'resume'"
			:logoUrl="reviewsStore.reviews?.organization.logoUrl || ''"
			:customColor="
				reviewsStore.reviews?.organization.primaryColor || undefined
			"
			:data="data"
			:points="reviewsStore.reviews?.points || 0"
			@login="currentStep = 'email'"
		/>
		<Email v-if="currentStep === 'email'" @login="currentStep === 'email'" />
		<a
			class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-foreground-subtle hover:text-foreground-subtle focus:text-foreground-subtle hidden sm:flex items-center"
			href="https://www.magma.app/"
			target="_blank"
		>
			{{ $t('generic.powered') }}
			<img class="ml-2" src="/img/logo_magma_full_bw.svg" />
		</a>
	</main>
</template>
