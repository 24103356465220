<script setup lang="ts">
// NPM
import { onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMq } from "vue3-mq";

// Components
import { BaseCard, BaseEmptyState } from "@magma-app/magma-lapilli";
import BaseRewardChallengeDetails from "@/components/bases/BaseRewardChallengeDetails.vue";

// Types
import type { IReward } from "@/types/IReward";

// Stores
import { useCommonStore } from "@/stores/common";
import { useRewardsStore } from "@/stores/rewards";
const commonStore = useCommonStore();
const rewardsStore = useRewardsStore();

// Plugins
const { t } = useI18n({ useScope: "global" });
const mq = useMq();

// Reactive variables
const initialized = ref(false);
const purchasedRewardDetails = ref<IReward | null>(null);

// Lifecycle hooks
onBeforeMount(async () => {
  await rewardsStore.getPurchasedRewards();

  initialized.value = true;
});

// Event listeners
const onClosePurchasedRewardDetails = () => {
  rewardsStore.setPurchasedRewardDetails(false);

  purchasedRewardDetails.value = null;
};

const onPurchasedRewardClick = (reward: IReward) => {
  if (!mq.lgPlus) {
    rewardsStore.setPurchasedRewardDetails(true);
    purchasedRewardDetails.value = reward;
  } else if (mq.lgPlus) {
    commonStore.setManageChallengesRewardsModal({
      open: true,
      item: reward,
      role: "reward-purchased",
    });
  }
};

// Methods
const badgeStatus = (status: string | undefined) => {
  if (status === "purchased") {
    return {
      color: "yellow",
      text: t("generic.awaiting"),
    };
  } else {
    return {
      color: "green",
      text: t("generic.distributed"),
    };
  }
};
</script>

<template>
  <div
    class="block h-full flex-1 pb-8"
    :class="{
      'content-start lg:flex lg:flex-wrap':
        rewardsStore.purchasedRewards?.length,
      ' px-4 md:px-8': !purchasedRewardDetails,
    }"
  >
    <template v-if="initialized && rewardsStore.purchasedRewards?.length">
      <template v-if="!purchasedRewardDetails">
        <BaseCard
          v-for="(reward, rewardIndex) in rewardsStore.purchasedRewards"
          :key="rewardIndex"
          class="mb-2 lg:mb-6 lg:mr-6"
          :title="reward.name"
          :img-url="
            reward.pictureUrl != null ? reward.pictureUrl : '/img/reward.png'
          "
          :badge-status="badgeStatus(reward.status)"
          @click="onPurchasedRewardClick(reward)"
        />
      </template>

      <BaseRewardChallengeDetails
        v-else
        :item="purchasedRewardDetails"
        purchased
        @close="onClosePurchasedRewardDetails"
      />
    </template>

    <BaseEmptyState
      v-else
      class="flex h-full flex-1 flex-col items-center justify-center"
      :text="$t('purchasedRewardsView.empty')"
      type="empty-list"
    />
  </div>
</template>
