<script setup lang="ts">
import { animate, spring } from 'motion';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

type Props = {
	organizationName: string;
	withoutTitle?: boolean;
};

defineProps<Props>();

const { t } = useI18n();

onMounted(() => {
	animate(
		'.msg1',
		{
			scale: [0, 1],
			y: ['50px', 0],
			opacity: [0, 1],
		},
		{
			duration: 1,
			easing: spring({ velocity: 0.1, damping: 12 }),
		}
	);
	animate(
		'.msg2',
		{
			scale: [0, 1],
			y: ['50px', 0],
			opacity: [0, 1],
		},
		{
			duration: 1,
			delay: 0.5,
			easing: spring({ velocity: 0.1, damping: 12 }),
		}
	);
});
</script>

<template>
	<div
		class="flex flex-col h-full flex-1 md:px-16 gap-0 md:gap-4 -mt-8 md:mt-0"
	>
		<div
			class="w-full aspect-square bg-[url('/img/illustration_helper_onboarding_background.svg')] bg-contain bg-no-repeat bg-center relative flex items-center justify-center flex-col gap-6"
		>
			<div
				class="msg1 md:w-96 w-80 p-4 bg-white border border-border-subtle rounded-2xl shadow-lg flex gap-4 items-center"
			>
				<img src="/img/avatar_instagram.png" class="shrink-0 h-auto w-[46px]" />
				<div class="flex-1">
					<div class="w-full flex justify-between text-mgm-txt-xs mb-0.5">
						<p class="font-semibold text-foreground-emphasis">Sarah B.</p>
						<span class="text-foreground-subtle">9:41</span>
					</div>
					<p class="text-mgm-txt-xs text-foreground-default">
						{{ t('helperOnboarding.share.message1.start') }}
						{{ organizationName }}{{ t('helperOnboarding.share.message1.end') }}
					</p>
				</div>
			</div>
			<div
				class="msg2 md:w-96 w-80 p-4 bg-white border border-border-subtle rounded-2xl shadow-lg flex gap-4 items-center"
			>
				<img src="/img/avatar_whatsapp.png" class="shrink-0 h-12 w-[46px]" />
				<div class="flex-1">
					<div class="w-full flex justify-between text-mgm-txt-xs mb-0.5">
						<p class="font-semibold text-foreground-emphasis">Vincent R.</p>
						<span class="text-foreground-subtle">8:25</span>
					</div>
					<p class="text-mgm-txt-xs text-foreground-default">
						{{ t('helperOnboarding.share.message2.start') }}
						{{ organizationName }}{{ t('helperOnboarding.share.message2.end') }}
					</p>
				</div>
			</div>
		</div>

		<h1
			v-if="!withoutTitle"
			class="text-mgm-dspl-xs md:text-mgm-dspl-sm text-foreground-emphasis text-center mb-10 md:mb-0"
		>
			{{ t('helperOnboarding.share.title') }}
		</h1>
	</div>
</template>
