import type { IAuth } from '@/types/IAuth';
import type { IPostSignUpData } from '@/types/IPostSignUpData';
import type { IPublicOrganizationData } from '@/types/IPublicOrganizationData';
import type { IRelationMoment } from '@/types/IRelationMoment';
import type { ISupportAdmin } from '@/types/ISupportAdmin';
import type { IRelation } from '@/types/IRelation';
import type { IUser } from '@/types/IUser';
import type { ICampaign } from '@/types/ICampaign';
import type { ICampaignDetails } from '@/types/ICampaignDetails';
import type { IAllCampaigns } from '@/types/IAllCampaigns';
import type { IUserCriteria } from '@/types/IUserCriteria';
import type { IRelationsHelper } from '@/types/IRelationsHelper';

export interface HelpeeSignupFormData {
	data: IPublicOrganizationData;
}

export interface SignupHelpeeData {
	data: IPostSignUpData;
}

export interface AuthJwtData {
	data: IAuth;
}

export interface RelationData {
	data: IRelation;
}

export interface SupportAdminData {
	data: ISupportAdmin;
}

export interface RelationMomentData {
	data: IRelationMoment;
}

export interface UserData {
	data: IUser;
}

export interface CampaignData {
	data: ICampaign;
}

export interface CampaignDetailsData {
	data: ICampaignDetails[];
}

export interface AllCampaignsData {
	data: IAllCampaigns[];
}

export interface UserProfilePictureData {
	data: {
		profilePictureUrl?: string;
	};
}

export interface UserCriteriaData {
	data: IUserCriteria;
}

export interface HelperRelationsData {
	data: IRelationsHelper;
}

export const ApiContract = {
	main: {
		routes: {
			POST_SIGNUP_HELPEE_EMAIL: '/sign-up-helpee/email',
			PUT_SIGNUP_HELPEE_MAIN_INFO: '/sign-up-helpee/main-information',
			PUT_SIGNUP_HELPEE_OPTIONAL_INFO: '/sign-up-helpee/optional-information',
			POST_SEND_CODE_REQUEST: '/sign-up-helpee/send-code',
		},
	},
	userDashboard: {
		routes: {
			GET_HELPEE_SIGNUP: '/public/sign-up-helpee-form',
			GET_JWT_TOKEN: '/auth/jwt-token',
			GET_HELPEE_RELATION: '/relation/helpee',
			GET_SUPPORT_ADMIN: '/support/admin',
			POST_HELPEE_RELATION_MOMENT_DONE: '/relation-moments/helpee/done',
			GET_USER: '/user',
			GET_CAMPAIGN_HELPER_SIGNEDUP: '/campaigns/helper/signed-up',
			GET_CAMPAIGN_HELPER: '/campaign-helper',
			POST_CAMPAIGN_HELPER_JOIN: '/campaign-helper/join',
			PUT_USER_PROFILE_PICTURE: '/user/profile-picture',
			PUT_USER: '/user',
			PUT_USER_CRITERIA: '/user-criteria',
			PUT_CAMPAIGN_HELPER_SETTINGS: '/campaign-helper/settings',
			GET_RELATIONS_HELPER: '/relations/helper',
			POST_RELATIONS_MOMENT_HELPER_READ: '/relation-moments/helper/read',
			POST_RELATIONS_MOMENT_HELPER_DONE: '/relation-moments/helper/done',
			POST_RELATIONS_MOMENT_HELPER_ONGOING: '/relation-moments/helper/ongoing',
		},
	},
};
