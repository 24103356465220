<script setup lang="ts">
// NPM
import { ref, watchEffect } from "vue";
import QRCode from "qrcode";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

// Components
import { BaseButton, BaseIcon } from "@magma-app/magma-lapilli";

// Stores
import { useCommonStore } from "@/stores/common";
const commonStore = useCommonStore();

// Props
const props = defineProps({
  link: {
    type: String,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

// Reactive variables
const initialized = ref(false);
const root = ref<HTMLInputElement>();
const qrCodeDataURL = ref("");

// Watchers
watchEffect(async () => {
  if (props.open && props.link) {
    qrCodeDataURL.value = await QRCode.toDataURL(props.link);
    initialized.value = true;
  } else {
    initialized.value = false;
  }
});

// Event listeners
const onCloseQrCode = async () => {
  commonStore.closeQrCodeModal();
};
</script>

<template>
  <TransitionRoot as="template" :show="open" data-cy="qrCode">
    <Dialog
      :initial-focus="root"
      as="div"
      class="relative z-10"
      @close="onCloseQrCode"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-background-emphasis bg-opacity-70 backdrop-blur-md transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div ref="root" class="flex min-h-full items-center justify-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="mx-6 w-[343px] transform overflow-hidden rounded-[15px] bg-background-default p-6 shadow-xl transition-all md:mx-auto"
            >
              <div class="flex justify-between">
                <span
                  class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
                >
                  {{ $t("generic.scan") }}
                </span>

                <BaseIcon
                  icon="close"
                  color="#5F6C85"
                  hover-state
                  @click="onCloseQrCode"
                />
              </div>

              <img
                v-if="qrCodeDataURL && initialized"
                class="mx-auto h-56 w-56"
                :src="qrCodeDataURL"
                alt="QR Code"
              />

              <BaseButton
                class="w-full"
                :text="$t('generic.close')"
                state="scdr"
                @click="onCloseQrCode"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
