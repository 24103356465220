<script setup lang="ts">
// NPM
import { onMounted, reactive, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required, url } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js";

// Components
import { BaseButton, BaseIcon, FieldInput } from "@magma-app/magma-lapilli";
import TheFooter from "@/components/shell/TheFooter.vue";

// Stores
import { useRelationsStore } from "@/stores/relations";
import { useUserStore } from "@/stores/user";
const relationsStore = useRelationsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const { locale, t } = useI18n({ useScope: "global" });
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const initialized = ref(false);
const isConfirmed = ref(false);

// Form
const formData = reactive({} as { [key: string]: string });
const formDataSaved = reactive({ ...formData });

// Computed
const finalRules = computed(() => {
  const formRules = {
    phone: "phoneNumber",
    line: "phoneNumber",
    whatsapp: "phoneNumber",
    calendly: "pseudo",
    discord: "pseudo",
    linkedin: "pseudo",
    weChat: "pseudo",
    youtube: "pseudo",
    tiktok: "pseudo",
    telegram: "pseudo",
    snapchat: "pseudo",
    x: "pseudo",
    messenger: "pseudo",
    instagram: "pseudo",
    facebook: "pseudo",
  } as { [key: string]: string };

  const generatedRules = Object.keys(formRules).reduce((acc: any, key) => {
    const helpeeTouchpointsMap = userStore.helpeeTouchpoints.reduce(
      (acc: any, touchpoint: any) => {
        acc[touchpoint.name] = touchpoint;
        return acc;
      },
      {}
    );

    if (formRules[key] === "phoneNumber") {
      acc[key] = {
        validPhoneNumber: withI18nMessage(
          (value: string) => (value != "" ? isValidPhoneNumber(value) : true),
          {
            messagePath: () => t("generic.phoneNumber") + t("validation.valid"),
          }
        ),
      };

      if (helpeeTouchpointsMap[key]) {
        acc[key] = {
          ...acc[key],
          required: withI18nMessage(required, {
            messagePath: () =>
              t("generic.phoneNumber") + t("validation.required"),
          }),
        };
      }
    } else if (formRules[key] === "pseudo") {
      acc[key] = {
        url: withI18nMessage(url, {
          messagePath: () => t("validation.urlValidation"),
        }),
      };

      if (helpeeTouchpointsMap[key]) {
        acc[key] = {
          ...acc[key],
          required: withI18nMessage(required, {
            messagePath: () => t("validation.required"),
          }),
        };
      }
    } else if (helpeeTouchpointsMap[key]) {
      acc[key] = {
        ...acc[key],
        required: withI18nMessage(required, {
          messagePath: () => t("validation.required"),
        }),
      };
    }

    return acc;
  }, {});

  return {
    mail: {
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
    },
    ...generatedRules,
  };
});

// Form validation
const v$ = useVuelidate(finalRules, formData);

// Watchers
const watchFields: string[] = [];

for (const field of watchFields) {
  watch(
    () => formData[field],
    async () => await v$.value[field].$validate()
  );
}

// Lifecycle hooks
onMounted(async () => {
  initialized.value = false;

  await userStore.getCampaignInfo(parseInt(route?.params.relationId as string));

  if (userStore.user?.language) {
    locale.value = userStore.user.language;
  } else if (relationsStore.relation?.language) {
    locale.value = relationsStore.relation?.language;
  } else {
    await relationsStore.getRelation({
      relationId: parseInt(route?.params.relationId as string),
      type: "helpee",
    });

    if (relationsStore.relation?.language) {
      locale.value = relationsStore.relation?.language;
    }
  }

  await userStore.getHelpeeTouchpoints();

  if (userStore.helpeeTouchpoints) {
    userStore.helpeeTouchpoints.forEach((touchpoint) => {
      if (touchpoint.name) {
        formData[touchpoint.name] = touchpoint.name;
        formDataSaved[touchpoint.name] = touchpoint.name;
        watchFields.push(touchpoint.name);
      }
    });
  }

  userStore.helpeeTouchpoints?.forEach((touchpoint) => {
    formData[touchpoint.name] = touchpoint.value || "";
  });

  initialized.value = true;
});

// Event listeners
const onConfirmTouchpoint = async (
  touchPointId: number,
  touchpointName: string
) => {
  v$.value[touchpointName].$touch();

  // Make sure the value is not empty and value has changed
  if (
    formData[touchpointName] !== formDataSaved[touchpointName] &&
    (await v$.value[touchpointName].$validate())
  ) {
    try {
      await userStore.updateUserTouchpoint({
        touchPointId,
        value: formData[touchpointName],
      });

      // Update formdata saved to the new value
      formDataSaved[touchpointName] = formData[touchpointName];
    } catch (error) {
      console.log(error);
    }
  }
};

const onValidate = () => {
  isConfirmed.value = true;
};
</script>

<template>
  <div class="min-h-[calc(100vh-65px)] bg-background-subtle-hover sm:pt-20">
    <div
      v-if="initialized"
      class="relative mx-auto min-h-[calc(100vh-65px)] max-w-[600px] bg-background-default px-4 py-8 sm:h-fit sm:min-h-fit sm:rounded-2xl sm:px-10 sm:pb-0 sm:pt-10 sm:shadow-[0px_4px_6px_-2px_rgba(28,31,39,0.03),0px_12px_16px_-4px_rgba(28,31,39,0.08)]"
    >
      <template v-if="route?.params.type === 'touchpoints'">
        <template v-if="!isConfirmed">
          <header>
            <BaseIcon
              class="mx-auto w-fit rounded-full border-[6px] border-mgm-prmr-bg"
              :size="20"
              bg-color="#E0EAFF"
              color="#444CE7"
              icon="passport"
            />

            <h2
              class="mx-auto mt-6 text-center text-mgm-txt-lg font-medium text-foreground-emphasis"
            >
              {{ $t("helpeeReportFeedbackView.confirmTouchpoints.confirm") }}
            </h2>
          </header>

          <FieldInput
            v-for="touchpoint of userStore.helpeeTouchpoints"
            :id="touchpoint.name"
            :key="touchpoint.name"
            v-model="formData[touchpoint.name]"
            class="mt-4"
            :role="touchpoint.type === 'email' ? 'email' : 'default'"
            :type="
              touchpoint.type === 'phoneNumber'
                ? 'tel'
                : touchpoint.type === 'email'
                  ? 'email'
                  : 'url'
            "
            :locale="$i18n.locale"
            :label="
              touchpoint.name.charAt(0).toUpperCase() +
              touchpoint.name.slice(1).toLowerCase()
            "
            :placeholder="
              touchpoint.type === 'phoneNumber'
                ? '+33606060606'
                : touchpoint.type === 'email'
                  ? `${$t('generic.example')}@email.com`
                  : `https://www.${$t('generic.example')}.com/${$t(
                      'generic.example'
                    )}`
            "
            required
            :validation="v$[touchpoint.name]"
            :custom-icon="touchpoint.icon"
            @blur="onConfirmTouchpoint(touchpoint.id, touchpoint.name)"
          />

          <BaseButton
            class="mt-8 w-full"
            :text="$t('generic.confirm')"
            size="md"
            @click="onValidate"
          />
        </template>

        <div
          v-else-if="isConfirmed"
          class="mx-auto mt-12 flex flex-col items-center text-center sm:mt-0"
        >
          <header class="flex flex-col items-center text-center">
            <img
              v-if="userStore.supportData"
              class="mb-6 h-[88px] w-[88px] rounded-full"
              :fallback="
                userStore.supportData.firstname?.charAt(0) +
                userStore.supportData.lastname?.charAt(0)
              "
              :src="userStore.supportData.profilePictureUrl"
            />

            <h2
              v-if="
                userStore.supportData?.firstname ||
                userStore.supportData?.lastname
              "
              class="text-mgm-txt-xl font-semibold text-foreground-emphasis"
            >
              {{
                userStore.supportData?.firstname +
                (userStore.supportData?.firstname ? " " : "") +
                userStore.supportData?.lastname
              }}
            </h2>

            <h3
              v-if="userStore.supportData?.position"
              class="text-mgm-txt-sm font-normal text-foreground-default"
            >
              {{ userStore.supportData?.position }}
            </h3>
          </header>

          <p class="mt-4 text-mgm-txt-sm font-normal text-foreground-default">
            {{ $t("helpeeReportFeedbackView.confirmTouchpoints.thanks") }}
          </p>
        </div>
      </template>

      <div
        v-else-if="route?.params.type === 'interest'"
        class="mx-auto mt-12 flex flex-col items-center text-center sm:mt-0"
      >
        <header class="flex flex-col items-center text-center">
          <img
            v-if="userStore.supportData"
            class="mb-6 h-[88px] w-[88px] rounded-full"
            :fallback="
              userStore.supportData.firstname?.charAt(0) +
              userStore.supportData.lastname?.charAt(0)
            "
            :src="userStore.supportData.profilePictureUrl"
          />

          <h2
            v-if="
              userStore.supportData?.firstname ||
              userStore.supportData?.lastname
            "
            class="text-mgm-txt-xl font-semibold text-foreground-emphasis"
          >
            {{
              userStore.supportData?.firstname +
              (userStore.supportData?.firstname ? " " : "") +
              userStore.supportData?.lastname
            }}
          </h2>

          <h3
            v-if="userStore.supportData?.position"
            class="text-mgm-txt-sm font-normal text-foreground-default"
          >
            {{ userStore.supportData?.position }}
          </h3>
        </header>

        <p class="mt-4 text-mgm-txt-sm font-normal text-foreground-default">
          {{ $t("helpeeReportFeedbackVIew.update") }}
        </p>
      </div>

      <div
        v-else
        class="mx-auto mt-12 flex flex-col items-center text-center sm:mt-0"
      >
        <header class="flex flex-col items-center text-center">
          <BaseIcon
            class="mx-auto w-fit rounded-full border-[6px] border-background-danger-default"
            :size="24"
            bg-color="#FEE4E2"
            color="#D92D20"
            icon="close"
          />

          <h2
            class="mt-6 text-mgm-txt-xl font-semibold text-foreground-emphasis"
          >
            {{ $t("helpeeReportFeedbackView.expired.title") }}
          </h2>
        </header>

        <p class="mt-2 text-mgm-txt-sm font-normal text-foreground-default">
          {{ $t("helpeeReportFeedbackView.expired.description") }}
        </p>
      </div>

      <TheFooter class="absolute bottom-0 left-0 right-0 sm:static" />
    </div>
  </div>
</template>
