<script setup lang="ts">
import { useHelpeeData } from '@/api/complete-helpee-signup/use-helpee-data';
import type { ITouchPoint } from '@/types/ITouchPoint';
import { hexToRgb } from '@/utils/color';
import {
	BaseButton,
	FieldCheckbox,
	FieldInput,
} from '@magma-app/magma-lapilli';
import useVuelidate from '@vuelidate/core';
import { createI18nMessage } from '@vuelidate/validators';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { computed } from 'vue';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

interface Props {
	disableEmail: boolean;
	email: string;
	phoneNumber: string;
}

defineProps<Props>();
const emit = defineEmits(['submit']);

const { t } = useI18n();

const route = useRoute();

const { data } = useHelpeeData(
	route.params.campaignHelpeeUuid as string | undefined
);

const withI18nMessage = createI18nMessage({ t });

const tempTouchpoints = [
	{
		touchPointId: 2,
		name: 'instagram',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/instagram.png',
		value: '',
	},
	{
		touchPointId: 1,
		name: 'facebook',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/facebook.png',
		value: '',
	},
	{
		touchPointId: 3,
		name: 'messenger',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/messenger.png',
		value: '',
	},
	{
		touchPointId: 14,
		name: 'linkedin',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/linkedin.png',
		value: '',
	},
	{
		touchPointId: 7,
		name: 'snapchat',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/snapchat.png',
		value: '',
	},
	{
		touchPointId: 4,
		name: 'X (Twitter)',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/x.png',
		value: '',
	},
	{
		touchPointId: 5,
		name: 'whatsapp',
		type: 'phoneNumber',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/whatsapp.png',
		value: '',
	},
	{
		touchPointId: 8,
		name: 'telegram',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/telegram.png',
		value: '',
	},
	{
		touchPointId: 6,
		name: 'line',
		type: 'phoneNumber',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/line.png',
		value: '',
	},
	{
		touchPointId: 15,
		name: 'discord',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/discord.png',
		value: '',
	},
	{
		touchPointId: 16,
		name: 'calendly',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/calendly-logo.png',
		value: '',
	},
	{
		touchPointId: 13,
		name: 'weChat',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/wechat.png',
		value: '',
	},
	{
		touchPointId: 9,
		name: 'tiktok',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/tiktok.png',
		value: '',
	},
	{
		touchPointId: 10,
		name: 'youtube',
		type: 'pseudo',
		icon: 'https://magma-assets.s3.eu-west-3.amazonaws.com/youtube.png',
		value: '',
	},
	{
		touchPointId: 17,
		name: 'calendar',
		type: 'pseudo',
		icon: 'https://magma-bucket.s3.eu-west-3.amazonaws.com/calendar.png',
		value: '',
	},
	{
		touchPointId: 18,
		name: 'littleredbook',
		type: 'pseudo',
		icon: 'https://magma-bucket.s3.eu-west-3.amazonaws.com/littleredbook.png',
		value: '',
	},
];

const touchPointsPrefix = {
	instagram: 'https://www.instagram.com/',
	facebook: 'https://www.facebook.com/',
	'X (Twitter)': 'https://www.twitter.com/',
	telegram: 'https://t.me/',
	tiktok: 'https://www.tiktok.com/@',
	youtube: 'https://www.youtube.com/channel/',
	weChat: 'https://weixin.qq.com/',
	linkedin: 'https://www.linkedin.com/in/',
	discord: 'https://discord.com/',
	calendar: null,
	whatsapp: null,
	line: 'https://line.me/',
	phone: null,
	messenger: 'https://m.me/',
	snapchat: 'https://www.snapchat.com/add/',
};

const touchPointsPlaceholder = {
	instagram: 'username',
	facebook: 'username',
	'X (Twitter)': 'username',
	telegram: 'username',
	tiktok: 'username',
	youtube: 'username',
	weChat: 'username',
	linkedin: 'username',
	discord: 'username',
	calendar: 'url',
	whatsapp: 'phone',
	line: 'username',
	phone: 'phone',
	messenger: 'username',
	snapchat: 'username',
	littleredbook: 'url',
};

const formRules = {
	phone: 'phoneNumber',
	line: 'phoneNumber',
	whatsapp: 'phoneNumber',
	calendly: 'pseudo',
	discord: 'pseudo',
	linkedin: 'pseudo',
	weChat: 'pseudo',
	youtube: 'pseudo',
	tiktok: 'pseudo',
	telegram: 'pseudo',
	snapchat: 'pseudo',
	'X (Twitter)': 'pseudo',
	messenger: 'pseudo',
	instagram: 'pseudo',
	facebook: 'pseudo',
} as { [key: string]: string };

const touchPointRules = computed(() => {
	return Object.keys(formRules).reduce((acc: any, key) => {
		if (formRules[key] === 'phoneNumber') {
			acc[key] = {
				validPhoneNumber: withI18nMessage(
					(value: any) => {
						if (value.value !== '') {
							return isValidPhoneNumber(value.value);
						} else {
							return true;
						}
					},
					{
						messagePath: () => t('generic.phoneNumber') + t('validation.valid'),
					}
				),
			};
		} else if (formRules[key] === 'pseudo') {
			acc[key] = {
				required: withI18nMessage(
					(value: any) => {
						return value.value !== '';
					},
					{ messagePath: () => t('validation.required') }
				),
			};
		}

		return acc;
	}, {});
});

const touchPoints = ref<any>({});
const acceptMail = ref(true);
const acceptPhone = ref(false);

const v$ = useVuelidate(touchPointRules, touchPoints, {});

const handleClickTouchpoint = (touchpoint: ITouchPoint) => {
	const findInSelected = touchPoints.value[touchpoint.name];

	if (findInSelected) {
		delete touchPoints.value[touchpoint.name];
	} else {
		const newTouchpoint = {
			...touchPoints.value,
			[touchpoint.name]: touchpoint,
		};

		touchPoints.value = { ...newTouchpoint };

		new Promise<void>((resolve) => {
			resolve();
		}).then(() => {
			const input = document.querySelector(
				`#touchpoint-${touchpoint.touchPointId}`
			) as HTMLInputElement;

			const container = document.querySelector('.touchpoint-container');

			if (input && container) {
				container.scrollTo({
					top: container.scrollHeight + 2000,
					behavior: 'smooth',
				});
				input.focus();
			}
		});
	}
};

const selectedTouchpointsToArray = computed(() => {
	return Object.values(touchPoints.value);
});

const handleTouchPointSubmit = () => {
	emit('submit', {
		touchPoints: selectedTouchpointsToArray.value,
		acceptMail: acceptMail.value,
		acceptPhone: acceptPhone.value,
	});
};

watch(
	() => touchPoints.value,
	() => {
		v$.value.$validate();
	},
	{ deep: true }
);
</script>

<template>
	<div class="px-5 lg:px-10 py-5 lg:py-7 flex flex-col flex-1 justify-between">
		<div
			class="touchpoint-container flex-1 overflow-y-auto pb-0 scroll-pb-0 scrollbar-hide transform-[translate3d(0,0,0)]"
		>
			<h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4">
				{{ t('helperOnboarding.touchPoints.title') }}
			</h3>

			<div class="pb-5 mb-5 border-b border-border-subtle">
				<FieldCheckbox
					id="email"
					v-model="acceptMail"
					data-cy="email"
					class="mb-3"
				>
					<span class="text-mgm-txt-sm text-foreground-default block">
						{{ $t('helpeeCampaignSignUpValidationView.touchPointMail') }}
						{{ email }}
					</span>
				</FieldCheckbox>
				<FieldCheckbox id="phone" v-model="acceptPhone" data-cy="phone">
					<span class="text-mgm-txt-sm text-foreground-default block">
						{{ $t('helpeeCampaignSignUpValidationView.touchPointPhone') }}
						{{ phoneNumber }}
					</span>
				</FieldCheckbox>

				<span
					class="flex items-center gap-2 text-mgm-txt-sm mt-3 text-foreground-danger-default"
					v-if="!acceptMail && !acceptPhone"
				>
					<BaseIcon icon="alert-circle" color="#D92D20" />
					{{ $t('helpeeCampaignSignUpValidationView.touchPointError') }}
				</span>
			</div>

			<p class="mb-4 text-mgm-txt-sm text-foreground-default">
				{{ $t('helpeeCampaignSignUpValidationView.touchPointChoose') }}
			</p>

			<div class="flex flex-wrap gap-2 mb-4">
				<button
					v-for="touchpoint in tempTouchpoints"
					:class="`h-8 rounded-full py-1.5 px-3 bg-background-subtle inline-flex items-center text-mgm-txt-sm gap-2 border ${
						touchPoints[touchpoint.name]
							? 'border-border-brand-emphasis text-foreground-brand-default'
							: 'border-background-subtle text-foreground-default'
					}`"
					:key="touchpoint.touchPointId"
					@click="handleClickTouchpoint(touchpoint)"
				>
					<img class="w-4 h-auto" :src="touchpoint.icon" />
					{{ touchpoint.name }}
				</button>
			</div>

			<FieldInput
				v-for="touchpoint of Object.values(touchPoints) as unknown as any"
				:id="`touchpoint-${touchpoint.touchPointId}`"
				:key="touchpoint.touchPointId"
				v-model="touchPoints[touchpoint.name].value"
				:validation="v$[touchpoint.name]"
				:data-cy="touchpoint.touchPointId + '-touchpoint'"
				class="mt-4 touchpoint-input"
				:role="touchpoint.type === 'email' ? 'email' : 'default'"
				:type="
					touchpoint.type === 'phoneNumber'
						? 'tel'
						: touchpoint.type === 'email'
							? 'email'
							: 'url'
				"
				:locale="$i18n.locale"
				:placeholder="
					touchPointsPlaceholder[
						touchpoint.name as keyof typeof touchPointsPlaceholder
					] === 'phone'
						? '+33606060606'
						: touchPointsPlaceholder[
									touchpoint.name as keyof typeof touchPointsPlaceholder
							  ] === 'email'
							? `${$t('generic.example')}@email.com`
							: touchPointsPlaceholder[
										touchpoint.name as keyof typeof touchPointsPlaceholder
								  ] === 'url'
								? `https://www.${$t('generic.example')}.com/${$t(
										'generic.example'
									)}`
								: `${$t('generic.username')}`
				"
				:custom-icon="touchpoint.icon"
				:prefix="
					touchPointsPrefix[touchpoint.name as keyof typeof touchPointsPrefix]
						? (touchPointsPrefix[
								touchpoint.name as keyof typeof touchPointsPrefix
							] as string)
						: undefined
				"
			/>
		</div>
		<BaseButton
			@click="handleTouchPointSubmit"
			size="lg"
			class="w-full mb-8 mt-3"
			:custom-color="data?.signUpInformation?.primaryColor"
			:custom-rgb-color="hexToRgb(data?.signUpInformation?.primaryColor || '')"
			:disabled="
				(selectedTouchpointsToArray.length !== 0 &&
					selectedTouchpointsToArray.filter((t: any) => t.value !== '')
						.length === 0) ||
				(!acceptMail && !acceptPhone)
			"
		>
			Continue</BaseButton
		>
	</div>
</template>
