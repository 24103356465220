<script setup lang="ts">
// NPM
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// Components
import { BaseBadge, FieldTabs } from "@magma-app/magma-lapilli";
import TheSidebar from "@/components/shell/TheSidebar.vue";

// Stores
import { useRewardsStore } from "@/stores/rewards";
import { useUserStore } from "@/stores/user";
const rewardsStore = useRewardsStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: "global" });

// Data
const tabs = [
  {
    label: t("generic.allRewards"),
    value: "allRewards",
  },
  {
    label: t("generic.myRewards"),
    value: "purchasedRewards",
  },
];

// Reactive variables
const currentTab = ref<"allRewards" | "purchasedRewards">(
  route?.name === "rewards" ? "allRewards" : "purchasedRewards"
);
</script>

<template>
  <div class="relative flex bg-background-subtle">
    <TheSidebar />

    <div
      class="flex w-full flex-1 flex-col bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:pt-8 2xl:ml-64"
    >
      <div
        class="mb-4 flex items-center justify-between px-4 md:px-8 lg:mb-8"
        :class="[
          rewardsStore.displayRewardDetails ||
          rewardsStore.displayPurchasedRewardDetails
            ? 'hidden lg:flex'
            : 'flex',
        ]"
      >
        <h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
          {{ $t("generic.rewards") }}
        </h2>

        <BaseBadge
          v-if="userStore.user?.organization?.pointEnabled"
          :text="userStore.user?.balance ? userStore.user?.balance : 0"
          size="md"
          icon-type="icon"
          icon-name="star"
        />
      </div>

      <FieldTabs
        v-model="currentTab"
        class="mx-4 mb-3 flex justify-start md:mx-8 lg:mb-6"
        :class="[
          rewardsStore.displayRewardDetails ||
          rewardsStore.displayPurchasedRewardDetails
            ? 'hidden lg:flex'
            : 'flex',
        ]"
        :tabs="tabs"
        @update:model-value="
          currentTab === 'purchasedRewards'
            ? router.push({ name: 'purchased-rewards' })
            : router.push({ name: 'rewards' })
        "
      />

      <routerView />
    </div>
  </div>
</template>
