<script setup lang="ts">
import { FieldInput, FieldAvatarUploader } from '@magma-app/magma-lapilli';
import { useI18n } from 'vue-i18n';

const props = defineProps([
	'modelValue',
	'validationProfilePicture',
	'validationBio',
	'pictureUrl',
]);
const emit = defineEmits(['update:modelValue', 'pictureUploaded']);

const { t } = useI18n();
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ t('helperOnboarding.profile.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ t('helperOnboarding.profile.subtitle') }}
		</h3>
		<FieldAvatarUploader
			id="profilePicture"
			class="mb-6"
			:cta="$t('generic.change')"
			:locale="$i18n.locale"
			:preview-url="pictureUrl"
			:validation="props.validationProfilePicture"
			data-cy="profile-pic"
			variant="vertical"
			icon-cta="grid-03"
			@change="
				emit('update:modelValue', {
					...props.modelValue,
					profilePicture: $event,
				})
			"
		/>
		<div class="relative">
			<span class="absolute right-0 text-mgm-txt-sm text-foreground-default"
				>{{ props.modelValue.bio.length }} / 50</span
			>
			<FieldInput
				type="bio"
				:placeholder="t('helperOnboarding.profile.placeholder')"
				@input="
					emit('update:modelValue', {
						...props.modelValue,
						bio: $event.target.value,
					})
				"
				:model-value="props.modelValue.bio"
				:label="t('helperOnboarding.profile.label')"
				:validation="props.validationBio"
			/>
		</div>
	</div>
</template>
