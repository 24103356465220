<script setup lang="ts">
// NPM
import { nextTick, onBeforeMount, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required } from "@vuelidate/validators";

// Components
import {
  BaseAvatar,
  BaseButton,
  BaseIcon,
  FieldInput,
} from "@magma-app/magma-lapilli";

// Stores
import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

// Plugins
const route = useRoute();
const { locale, t } = useI18n();
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const disableBtn = ref(false);
const loginAs = ref(false);
const failure = ref(false);
const genericLogin = ref(false);
const success = ref(false);

const formData = reactive({
  email: "",
});

// Form validation
const v$ = useVuelidate(
  {
    email: {
      required: withI18nMessage(required, {
        messagePath: () => t("loginView.emailRequired"),
      }),
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
    },
  },
  formData
);

// Watchers
watch(
  () => formData.email,
  async () => {
    if (disableBtn.value) {
      disableBtn.value = false;
    }
  }
);

// Lifecycle hooks
onBeforeMount(async () => {
  // Get sign in data
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if ((subdomain && subdomain === "login") || subdomain === "staging-login") {
    genericLogin.value = true;
  } else if ((subdomain && subdomain != "v3") || route?.params.campaignUuid) {
    await authStore.getSignInData(
      subdomain,
      route?.params.campaignUuid as string
    );

    if (authStore.signInData) {
      locale.value = authStore.signInData.language;
    }
  }
});

// Methods
const onChooseProduct = async (product: string) => {
  await authStore.signInAs(formData.email, product);

  success.value = true;
  loginAs.value = false;
};

const submitForm = async () => {
  v$.value.$touch();

  if (await v$.value.$validate()) {
    v$.value.email.$reset();

    if (genericLogin.value) {
      try {
        disableBtn.value = true;
        await authStore.signInAs(formData.email);

        await nextTick();
        disableBtn.value = false;

        if (authStore.loginAs?.needLoginAs) {
          loginAs.value = true;
        } else {
          success.value = true;
        }
      } catch (error) {
        failure.value = true;
        disableBtn.value = true;
      }
    } else {
      try {
        disableBtn.value = true;
        await authStore.signIn(formData.email);

        disableBtn.value = false;
        success.value = true;
      } catch (error) {
        failure.value = true;
        disableBtn.value = true;
      }
    }
  }
};
</script>

<template>
  <div class="gradient__grey__07 flex flex-col items-center justify-center p-8">
    <div v-if="!loginAs" class="sm:w-[360px]">
      <img
        class="mx-auto h-20 w-20 rounded-full object-cover ring-[5px] ring-border-on-emphasis"
        :src="
          authStore.signInData?.logoUrl
            ? authStore.signInData?.logoUrl
            : '/img/logo_magma.png'
        "
      />

      <h1
        class="mx-auto mt-8 text-center text-mgm-dspl-xs font-medium text-foreground-emphasis"
      >
        {{
          success
            ? $t("loginView.checkEmail")
            : authStore.signInData?.organizationName
              ? $t("loginView.toDashboard")
              : $t("loginView.login")
        }}
      </h1>

      <p
        class="mx-auto mt-2 text-center text-mgm-txt-sm font-normal text-foreground-default"
      >
        <span
          v-html="
            success
              ? $t('loginView.checkEmailDescription')
              : $t('loginView.description')
          "
        >
        </span>
        <span v-if="success" class="font-medium text-foreground-emphasis">
          {{ formData.email }}
        </span>
      </p>

      <div
        v-if="success"
        class="mx-auto cursor-pointer pt-12 text-center text-mgm-txt-sm font-medium text-foreground-subtle hover:text-border-default"
        @click="
          success = false;
          formData.email = '';
        "
      >
        {{ $t("generic.backToLogin") }}
      </div>

      <div
        v-if="failure"
        class="mt-8 flex w-full items-start rounded-[5px] bg-background-danger-default px-3 py-2"
      >
        <BaseIcon class="mr-2" icon="slash" color="#D92D20" />

        <p class="text-mgm-txt-sm">
          <span class="block font-medium text-foreground-danger-default">{{
            $t("loginView.nonExistentEmail")
          }}</span>

          <span class="block font-normal text-foreground-default">
            {{ $t("loginView.nonExistentEmailDescription") }}
          </span>
        </p>
      </div>

      <template v-if="!success">
        <form class="mt-8" @submit.prevent="submitForm">
          <div>
            <FieldInput
              id="email"
              v-model="formData.email"
              role="email"
              type="email"
              :locale="$i18n.locale"
              :placeholder="$t('loginView.emailPlaceholder')"
              autocomplete="email"
              :validation="v$.email"
              @update:model-value="failure = false"
            />
          </div>

          <BaseButton
            class="mt-6 w-full"
            :disabled="disableBtn"
            :text="$t('loginView.cta')"
            size="md"
            type="submit"
          />
        </form>

        <div class="mt-8 h-px w-full bg-border-subtle"></div>

        <div
          class="mt-8"
          :class="{ flex: authStore.signInData?.contactProfilePicture }"
        >
          <BaseAvatar
            v-if="authStore.signInData?.contactProfilePicture"
            class="mr-4"
            :fallback="
              (authStore.signInData?.contactFirstname || '').charAt(0) +
              (authStore.signInData?.contactLastname || '').charAt(0)
            "
            :src="authStore.signInData?.contactProfilePicture"
            size="md"
          />

          <p
            class="text-mgm-txt-sm font-normal text-foreground-subtle"
            :class="{
              'text-center': !authStore.signInData?.contactProfilePicture,
            }"
          >
            <span
              :class="[
                authStore.signInData?.contactProfilePicture ? 'block' : 'mr-1',
              ]"
            >
              {{ $t("loginView.cant") }}
            </span>
            <a
              :href="
                authStore.signInData?.contactEmail
                  ? `mailto:${authStore.signInData?.contactEmail}`
                  : 'mailto:partnership@magma.app'
              "
              class="font-medium text-foreground-brand-default hover:text-foreground-brand-hover"
              target="_blank"
            >
              {{
                authStore.signInData?.contactEmail
                  ? $t("loginView.contact")
                  : $t("generic.adminContact")
              }}
            </a>
          </p>
        </div>
      </template>
    </div>

    <div v-else>
      <h1
        class="mx-auto mt-8 text-center text-mgm-dspl-xs font-medium text-foreground-emphasis"
      >
        {{ $t("loginView.logAs") }}
      </h1>

      <div class="mt-10 sm:flex">
        <div
          class="mb-10 box-border max-w-sm cursor-pointer rounded-[15px] border border-border-subtle bg-background-default hover:shadow-[0px_12px_16px_-4px_rgba(28,31,39,0.08),0px_4px_6px_-2px_rgba(28,31,39,0.03)] sm:mb-0 sm:mr-10 sm:w-[260px] md:w-[330px] lg:w-[384px]"
          @click="onChooseProduct('admin')"
        >
          <div
            class="h-[212px] bg-[url('/img/illustration_login_admin.png')] bg-cover bg-center"
          ></div>
          <div class="px-8 pb-8 pt-4">
            <h2 class="text-mgm-txt-md font-medium text-foreground-emphasis">
              {{ $t("generic.admin") }}
            </h2>

            <p class="text-mgm-txt-sm font-normal text-foreground-default">
              {{ $t("loginView.logAdmin") }}
            </p>
          </div>
        </div>

        <div
          class="box-border max-w-sm cursor-pointer rounded-[15px] border border-border-subtle bg-background-default hover:shadow-[0px_12px_16px_-4px_rgba(28,31,39,0.08),0px_4px_6px_-2px_rgba(28,31,39,0.03)] sm:w-[260px] md:w-[330px] lg:w-[384px]"
          @click="onChooseProduct('helper')"
        >
          <div
            class="h-[212px] bg-[url('/img/illustration_login_helper.png')] bg-cover bg-center"
          ></div>
          <div class="px-8 pb-8 pt-4">
            <h2 class="text-mgm-txt-md font-medium text-foreground-emphasis">
              Helper
            </h2>

            <p class="text-mgm-txt-sm font-normal text-foreground-default">
              {{ $t("loginView.logHelper") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
