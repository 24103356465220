<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { computed, watch, watchEffect } from 'vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
	FieldTextarea,
	FieldInput,
	FieldCheckbox,
	BaseButton,
	BaseAvatar,
} from '@magma-app/magma-lapilli';
import type { ITouchPoint } from '@/types/ITouchPoint';
import useVuelidate from '@vuelidate/core';
import { createI18nMessage, email } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useConvertHexToRgb } from '@/composables/useConvertHexToRgb';
import { useUserStore } from '@/stores/user';
import AnalyticsHelper from '@/helpers/analytics';

const $route = useRoute();
const { t, locale } = useI18n({ useScope: 'global' });
const withI18nMessage = createI18nMessage({ t });
const { hexColor, rgbColor } = useConvertHexToRgb();

const authStore = useAuthStore();
const userStore = useUserStore();
const router = useRouter();

const describeStep = ref(true);
const touchpointStep = ref(false);
const finalStep = ref(false);

const formData = ref({
	describe: '',
	acceptEmail: true,
	acceptPhone: true,
	selectedTouchpoints: {} as { [key: string]: ITouchPoint },
});

const initialized = ref(false);

const formRules = {
	phone: 'phoneNumber',
	line: 'phoneNumber',
	whatsapp: 'phoneNumber',
	calendly: 'pseudo',
	discord: 'pseudo',
	linkedin: 'pseudo',
	weChat: 'pseudo',
	youtube: 'pseudo',
	tiktok: 'pseudo',
	telegram: 'pseudo',
	snapchat: 'pseudo',
	'X (Twitter)': 'pseudo',
	messenger: 'pseudo',
	instagram: 'pseudo',
	facebook: 'pseudo',
} as { [key: string]: string };

const finalRules = Object.keys(formRules).reduce((acc: any, key) => {
	if (formRules[key] === 'phoneNumber') {
		acc[key] = {
			validPhoneNumber: withI18nMessage(
				(value: string) => {
					if (value != '') {
						return isValidPhoneNumber(value);
					} else {
						return true;
					}
				},
				{
					messagePath: () => t('generic.phoneNumber') + t('validation.valid'),
				}
			),
		};
	} else if (formRules[key] === 'pseudo') {
		acc[key] = {
			required: withI18nMessage(
				(value: string) => {
					return value === '';
				},
				{ messagePath: () => t('validation.required') }
			),
		};
	}

	return acc;
}, {});

const touchPointsPrefix = {
	instagram: 'https://www.instagram.com/',
	facebook: 'https://www.facebook.com/',
	'X (Twitter)': 'https://www.twitter.com/',
	telegram: 'https://t.me/',
	tiktok: 'https://www.tiktok.com/@',
	youtube: 'https://www.youtube.com/channel/',
	weChat: 'https://weixin.qq.com/',
	linkedin: 'https://www.linkedin.com/in/',
	discord: 'https://discord.com/',
	calendar: null,
	whatsapp: null,
	line: 'https://line.me/',
	phone: null,
	messenger: 'https://m.me/',
	snapchat: 'https://www.snapchat.com/add/',
};

const touchPointsPlaceholder = {
	instagram: 'username',
	facebook: 'username',
	'X (Twitter)': 'username',
	telegram: 'username',
	tiktok: 'username',
	youtube: 'username',
	weChat: 'username',
	linkedin: 'username',
	discord: 'username',
	calendar: 'url',
	whatsapp: 'phone',
	line: 'username',
	phone: 'phone',
	messenger: 'username',
	snapchat: 'username',
	littleredbook: 'url',
};

const v$ = useVuelidate(
	{
		selectedTouchpoints: {
			mail: {
				email: withI18nMessage(email, {
					messagePath: () => t('validation.invalidEmail'),
				}),
			},
			...finalRules,
		},
	},
	formData
);

const changeStep = (step: 'describe' | 'touchpoints' | 'finish') => {
	switch (step) {
		case 'describe':
			describeStep.value = true;
			touchpointStep.value = false;
			finalStep.value = false;
			break;
		case 'touchpoints':
			describeStep.value = false;
			touchpointStep.value = true;
			finalStep.value = false;
			break;
		case 'finish':
			describeStep.value = false;
			touchpointStep.value = false;
			finalStep.value = true;
			break;
	}
};

const handleSendExpectation = async () => {
	const campaignHelpeeUuid = $route.params.campaignHelpeeUuid as string;

	await authStore.sendExpectationSignUpHelpee({
		campaignHelpeeUuid,
		expectation: formData.value.describe,
	});

	if (authStore.haveSendExpectation) {
		changeStep('touchpoints');
	}
};

const handleClickTouchpoint = (touchpoint: ITouchPoint) => {
	const findInSelected = formData.value.selectedTouchpoints[touchpoint.name];

	if (findInSelected) {
		delete formData.value.selectedTouchpoints[touchpoint.name];
	} else {
		formData.value.selectedTouchpoints = {
			...formData.value.selectedTouchpoints,
			[touchpoint.name]: touchpoint,
		};

		new Promise<void>((resolve) => {
			resolve();
		}).then(() => {
			const input = document.querySelector(
				`#touchpoint-${touchpoint.touchPointId}`
			) as HTMLInputElement;

			const container = document.querySelector('.touchpoint-container');

			if (input && container) {
				container.scrollTo({
					top: container.scrollHeight + 2000,
					behavior: 'smooth',
				});
				input.focus();
			}
		});
	}
};

const handleSendTouchpoints = async () => {
	// Check if selectedTouchpoints value is empty
	const tP = formData.value.selectedTouchpoints;
	const tPValues = Object.values(tP);
	const tPValuesEmpty = tPValues.filter((tPValue) => tPValue.value === '');

	if (tPValuesEmpty.length !== 0) {
		v$.value.selectedTouchpoints.$validate();
		return;
	}

	selectedTouchpointsToArray().forEach((touchpoint) => {
		AnalyticsHelper.trackEvent(`${touchpoint.name}_touchpoint_filled`);
	});

	await authStore.sendTouchPointsSignUpHelpee({
		campaignHelpeeUuid: $route.params.campaignHelpeeUuid as string,
		acceptEmailNotifications: formData.value.acceptEmail,
		acceptPhoneNotifications: formData.value.acceptPhone,
		touchPoints: selectedTouchpointsToArray().map((touchpoint) => ({
			touchPointId: touchpoint.touchPointId,
			value: `${
				touchPointsPrefix[touchpoint.name as keyof typeof touchPointsPrefix]
			}${formData.value.selectedTouchpoints[touchpoint.name].value}`,
		})),
	});

	changeStep('finish');
};

const selectedTouchpointsToArray = () => {
	return Object.values(formData.value.selectedTouchpoints);
};

watchEffect(async () => {
	const campaignHelpeeUuid = $route.params.campaignHelpeeUuid as string;
	await authStore.getCampaignHelpeeSignUpData(campaignHelpeeUuid);
	//await userStore.getCampaigns();

	locale.value = authStore.helpeeQualificationData?.language || locale.value;

	initialized.value = true;

	if (authStore.helpeeQualificationData?.primaryColor) {
		hexColor.value = authStore.helpeeQualificationData?.primaryColor;
	}

	authStore.helpeeQualificationData?.touchPoints
		.filter((t) => t.value !== '')
		.forEach((touchpoint) => {
			formData.value.selectedTouchpoints[touchpoint.name] = touchpoint;
			const prefix =
				touchPointsPrefix[touchpoint.name as keyof typeof touchPointsPrefix];
			if (prefix) {
				const value = touchpoint.value.replace(prefix, '');
				formData.value.selectedTouchpoints[touchpoint.name].value = value;
			}
		});

	if (authStore.helpeeQualificationData?.isAlreadyMatched) {
		router.push({ name: 'login' });
	}

	if (authStore.helpeeQualificationData?.isQualificationDone) {
		changeStep('finish');
	}
});
</script>

<template>
	<div ref="root" data-cy="e2e-main-div" class="flex flex-col h-screen">
		<div
			v-if="initialized"
			:class="`mx-auto md:mt-20 md:rounded-2xl md:shadow-lg md:border md:border-border-subtle w-full h-dvh md:h-auto ${
				touchpointStep ? 'md:w-[520px]' : 'md:w-96'
			}`"
		>
			<!-- HEADER -->
			<div
				class="flex h-16 items-center p-4 md:px-10 border-b border-b-border-subtle"
			>
				<BaseAvatar
					:src="authStore.helpeeQualificationData?.logoUrl"
					:alt="'logo ' + authStore.helpeeQualificationData?.organizationName"
					:fallback="authStore.helpeeSignUpData?.name"
				/>

				<h1
					class="max-w-[300px] truncate px-2 text-mgm-txt-sm font-medium text-foreground-emphasis lg:max-w-[300px]"
				>
					{{ authStore.helpeeSignUpData?.name }}
				</h1>
			</div>

			<div
				:class="`flex-1 h-[calc(100%_-_64px)] ${
					finalStep ? '' : 'px-6 pb-6 pt-6'
				}`"
			>
				<!-- DESCRIBE YOUR NEED -->
				<div
					v-if="describeStep"
					class="relative h-full flex-1 flex flex-col justify-between"
				>
					<div>
						<h3
							class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4"
						>
							{{ $t('helpeeCampaignSignUpValidationView.expectationTitle') }}
						</h3>

						<p class="text-mgm-txt-sm text-foreground-default block mb-4">
							{{
								$t(
									'helpeeCampaignSignUpValidationView.expectationDescription.start'
								)
							}}
							{{ authStore.helpeeQualificationData?.organizationName
							}}{{
								$t(
									'helpeeCampaignSignUpValidationView.expectationDescription.end'
								)
							}}
						</p>

						<FieldTextarea
							:label="$t('helpeeCampaignSignUpValidationView.expectationLabel')"
							:placeholder="
								$t('helpeeCampaignSignUpValidationView.expectationPlaceholder')
							"
							v-model="formData.describe"
							required
						/>
					</div>

					<BaseButton
						data-cy="signup-next"
						class="mt-16 w-full"
						:text="$t('generic.next')"
						size="md"
						:disabled="formData.describe === ''"
						@click="handleSendExpectation"
						:custom-color="authStore.helpeeQualificationData?.primaryColor"
						:custom-rgb-color="
							rgbColor
								? rgbColor
								: authStore.helpeeQualificationData?.primaryColor
						"
					/>
				</div>

				<!-- CHOOSE TOUCHPOINTS -->
				<div
					v-if="touchpointStep"
					class="relative h-full flex-1 md:pb-0 md:max-h-[75dvh] flex flex-col"
				>
					<div
						class="touchpoint-container flex-1 overflow-y-auto pb-24 scroll-pb-24 scrollbar-hide"
					>
						<h3
							class="text-mgm-txt-lg font-medium text-foreground-emphasis mb-4"
						>
							{{ $t('helpeeCampaignSignUpView.moreDetails') }}
						</h3>

						<div class="pb-5 mb-5 border-b border-border-subtle">
							<FieldCheckbox
								id="email"
								v-model="formData.acceptEmail"
								data-cy="email"
								class="mb-3"
							>
								<span class="text-mgm-txt-sm text-foreground-default block">
									{{ $t('helpeeCampaignSignUpValidationView.touchPointMail') }}
									{{ authStore.helpeeQualificationData?.helpeeEmail }}
								</span>
							</FieldCheckbox>
							<FieldCheckbox
								id="phone"
								v-model="formData.acceptPhone"
								data-cy="phone"
							>
								<span class="text-mgm-txt-sm text-foreground-default block">
									{{ $t('helpeeCampaignSignUpValidationView.touchPointPhone') }}
									{{ authStore.helpeeQualificationData?.helpeePhoneNumber }}
								</span>
							</FieldCheckbox>

							<span
								class="flex items-center gap-2 text-mgm-txt-sm mt-3 text-foreground-danger-default"
								v-if="!formData.acceptEmail && !formData.acceptPhone"
							>
								<BaseIcon icon="alert-circle" color="#D92D20" />
								{{ $t('helpeeCampaignSignUpValidationView.touchPointError') }}
							</span>
						</div>

						<p class="mb-4 text-mgm-txt-sm text-foreground-default">
							{{ $t('helpeeCampaignSignUpValidationView.touchPointChoose') }}
						</p>

						<div class="flex flex-wrap gap-2 mb-4">
							<button
								v-for="touchpoint in authStore.helpeeQualificationData
									?.touchPoints"
								:class="`h-8 rounded-full py-1.5 px-3 bg-background-subtle inline-flex items-center text-mgm-txt-sm gap-2 border ${
									formData.selectedTouchpoints[touchpoint.name]
										? 'border-border-brand-emphasis text-foreground-brand-default'
										: 'border-background-subtle text-foreground-default'
								}`"
								:key="touchpoint.touchPointId"
								@click="handleClickTouchpoint(touchpoint)"
							>
								<img class="w-4 h-auto" :src="touchpoint.icon" />
								{{ touchpoint.name }}
							</button>
						</div>

						<FieldInput
							v-for="touchpoint of Object.values(formData.selectedTouchpoints)"
							:id="`touchpoint-${touchpoint.touchPointId}`"
							:key="touchpoint.touchPointId"
							:validation="v$.selectedTouchpoints[touchpoint.name]"
							v-model="formData.selectedTouchpoints[touchpoint.name].value"
							:data-cy="touchpoint.touchPointId + '-touchpoint'"
							class="mt-4 touchpoint-input"
							:role="touchpoint.type === 'email' ? 'email' : 'default'"
							:type="
								touchpoint.type === 'phoneNumber'
									? 'tel'
									: touchpoint.type === 'email'
										? 'email'
										: 'url'
							"
							:locale="$i18n.locale"
							:placeholder="
								touchPointsPlaceholder[
									touchpoint.name as keyof typeof touchPointsPlaceholder
								] === 'phone'
									? '+33606060606'
									: touchPointsPlaceholder[
												touchpoint.name as keyof typeof touchPointsPlaceholder
										  ] === 'email'
										? `${$t('generic.example')}@email.com`
										: touchPointsPlaceholder[
													touchpoint.name as keyof typeof touchPointsPlaceholder
											  ] === 'url'
											? `https://www.${$t('generic.example')}.com/${$t(
													'generic.example'
												)}`
											: `${$t('generic.username')}`
							"
							:custom-icon="touchpoint.icon"
							:prefix="
								touchPointsPrefix[
									touchpoint.name as keyof typeof touchPointsPrefix
								]
									? (touchPointsPrefix[
											touchpoint.name as keyof typeof touchPointsPrefix
										] as string)
									: undefined
							"
						/>
					</div>

					<div
						class="fixed md:absolute px-6 md:px-0 -bottom-1 left-0 w-full pb-10 md:pb-6 pt-2 bg-gradient-to-t from-white to-transparent"
					>
						<BaseButton
							data-cy="signup-finish"
							class="mt-8 w-full"
							:disabled="!formData.acceptEmail && !formData.acceptPhone"
							:text="$t('generic.validate')"
							size="md"
							@click="handleSendTouchpoints"
							:custom-color="authStore.helpeeQualificationData?.primaryColor"
							:custom-rgb-color="
								rgbColor
									? rgbColor
									: authStore.helpeeQualificationData?.primaryColor
							"
						/>
					</div>
				</div>

				<!-- FINISH -->
				<div
					v-else-if="finalStep"
					data-cy="signup-confirmation"
					class="relative h-full w-full flex-1 text-center"
				>
					<div
						:class="[
							`relative h-[336px] w-full bg-[url('/img/illustration_redirection_helpee_background.svg')] bg-cover bg-center md:rounded-t-[15px]`,
						]"
					>
						<div
							v-for="(helper, helperIndex) in authStore.helpeeQualificationData
								?.helperProfilePictures"
							:key="helperIndex"
						>
							<div
								:class="[
									'overflow-hidden border border-white rounded-full w-12 h-12 absolute',
									{
										'top-8 left-10': helperIndex === 0,
										'top-10 right-16': helperIndex === 1,
										'top-28 left-36': helperIndex === 2,
										'bottom-24 right-20': helperIndex === 3,
										'bottom-12 left-12': helperIndex === 4,
									},
								]"
							>
								<img class="h-full w-full object-cover" :src="helper" />
							</div>
						</div>
					</div>

					<h3
						class="mx-auto max-w-xs text-mgm-txt-lg font-medium text-foreground-emphasis"
					>
						{{ $t('helpeeCampaignSignUpValidationView.finalTitle') }}
					</h3>

					<p
						class="mx-auto mt-4 max-w-xs text-mgm-txt-sm font-normal text-foreground-default mb-10"
					>
						{{ $t('helpeeCampaignSignUpValidationView.finalDescription') }}
					</p>
				</div>
			</div>
		</div>

		<a
			class="mx-auto mt-8 text-center text-mgm-txt-xs font-normal text-border-default hover:text-foreground-subtle focus:text-foreground-subtle hidden md:flex items-center"
			href="https://www.magma.app/"
			target="_blank"
		>
			{{ $t('generic.powered') }}
			<img class="ml-2" src="/img/logo_magma_full_bw.svg" />
		</a>
	</div>
</template>

<style scoped>
.touchpoint-input img {
	height: auto !important;
}
</style>
