const helpers = {
  loadScript(
    src: string,
    attributes: { [key: string]: string } = {},
    force = false
  ) {
    if (!src) {
      return;
    }
    return new Promise((resolve, reject) => {
      const existingEl = document.querySelector(`script[src="${src}"]`);

      if (existingEl && !force) {
        if (existingEl.classList.contains("is-loading")) {
          existingEl.addEventListener("load", resolve);
          existingEl.addEventListener("error", reject);
          existingEl.addEventListener("abort", reject);
        } else {
          resolve(true);
        }
      } else {
        const el = document.createElement("script");

        el.type = "text/javascript";
        el.defer = true;
        el.src = src;
        el.classList.add("is-loading");

        for (const key in attributes) {
          el.setAttribute(key, attributes[key]);
        }

        el.addEventListener("load", () => {
          el.classList.remove("is-loading");

          resolve(true);
        });

        el.addEventListener("error", reject);
        el.addEventListener("abort", reject);

        document.head.appendChild(el);
      }
    });
  },
};

export default Object.freeze(helpers);
