<script setup lang="ts">
// NPM
import { reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, required } from "@vuelidate/validators";
import { useMq } from "vue3-mq";

// Components
import {
  BaseAvatar,
  BaseButton,
  BaseIcon,
  FieldFileUploader,
  FieldLabel,
  FieldTextarea,
} from "@magma-app/magma-lapilli";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";

// Stores
import { useCommonStore } from "@/stores/common";
import { useUserStore } from "@/stores/user";
import { useRelationsStore } from "@/stores/relations";
const commonStore = useCommonStore();
const userStore = useUserStore();
const relationsStore = useRelationsStore();

// Plugins
const mq = useMq();
const route = useRoute();
const { t } = useI18n();
const withI18nMessage = createI18nMessage({ t });

// Props
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  // When the modal is opened from the helpee/helper relation overview view
  relationMomentId: {
    type: Number,
    default: undefined,
  },
});

// Reactive variables
const isSending = ref(false);
const success = ref(false);

const formData = reactive({
  document: undefined as File | undefined,
  message: "",
});

// Form validation
const v$ = useVuelidate(
  {
    message: {
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
    document: {
      size: withI18nMessage((value: File) => value.size <= 50 * 1000 * 1000, {
        messagePath: () => t("validation.fileSize"),
      }),
    },
  },
  formData
);

// Event Listeners
const onOpenSupport = () => {
  commonStore.setAdminModal({
    open: true,
  });

  toggleBodyOverflow(true);
};

const onCloseSupport = () => {
  commonStore.setAdminModal({
    relationMomentId: undefined as File | undefined,
    open: false,
  });

  toggleBodyOverflow(false);
};

const onSendMessage = async () => {
  isSending.value = true;

  v$.value.$touch();

  if (props.relationMomentId && (await v$.value.$validate())) {
    sendRelationMomentMessage(props.relationMomentId);
  } else if (userStore.supportData && (await v$.value.message.$validate())) {
    sendGeneralSupportMessage(userStore.supportData?.campaignAdminUuid);
  }

  isSending.value = false;
};

// Methods
const sendGeneralSupportMessage = async (campaignAdminUuid: string) => {
  v$.value.$reset();

  try {
    await userStore.sendSupportMessage({
      message: formData.message,
      campaignAdminUuid,
    });

    success.value = true;

    sendAnalytics();
  } catch (error) {
    console.log(error);
  }
};

const sendRelationMomentMessage = async (relationMomentId: number) => {
  v$.value.$reset();

  try {
    await relationsStore.contactAdmin({
      file: formData.document,
      message: formData.message,
      relationMomentId,
    });
    success.value = true;
  } catch (error) {
    console.log(error);
  }
};

const sendAnalytics = () => {
  AnalyticsHelper.trackEvent("ask_support", {
    event_properties: "support_bubble",
    user_properties: userStore.user?.isHelper === true ? "helper" : "helpee",
  });
};

const toggleBodyOverflow = (property: boolean) => {
  if (mq.smMinus) {
    document.body.classList[property ? "add" : "remove"]("overflow-hidden");
  }
};
</script>

<template>
  <!-- Button -->
  <div
    :class="[
      'fixed bottom-4 right-4 flex cursor-pointer items-center justify-center rounded-full border border-border-subtle bg-background-default p-3 shadow-[0px_4px_8px_-2px_rgba(28,31,39,0.1),0px_2px_4px_-2px_rgba(28,31,39,0.6)] sm:bottom-6 sm:right-6 sm:px-4 sm:py-2.5',
      { 'sm:hidden': open },
    ]"
    @click="onOpenSupport"
  >
    <BaseIcon color="#5F6C85" icon="help-circle" />

    <span
      class="ml-2 hidden text-mgm-txt-sm font-medium text-foreground-default sm:block"
    >
      {{ $t("generic.help") }}
    </span>
  </div>

  <!-- Modal -->
  <div
    v-if="open"
    :class="[
      'scrollbar-hide fixed bottom-0 left-0 right-0 top-0 z-10 flex h-full flex-col bg-background-default sm:bottom-6 sm:left-auto sm:right-6 sm:top-auto sm:h-auto sm:w-[376px] sm:rounded-[10px] sm:border sm:border-border-subtle sm:shadow-[0px_4px_8px_-2px_rgba(28,31,39,0.1),0px_2px_4px_-2px_rgba(28,31,39,0.6)]',
      relationMomentId ? 'overflow-scroll' : 'max-h-screen',
    ]"
  >
    <!-- Step 1: Form -->
    <template v-if="!success">
      <!-- Header -->
      <div
        class="rounded-t-[10px] border-b border-border-subtle bg-background-subtle-hover px-6 pb-6 pt-10 sm:pt-6"
      >
        <div class="flex items-start justify-between">
          <BaseAvatar
            v-if="userStore.supportData"
            :src="userStore.supportData.profilePictureUrl"
            size="md"
            :fallback="
              userStore.supportData.firstname?.charAt(0) +
              userStore.supportData.lastname?.charAt(0)
            "
          />

          <BaseIcon
            class="cursor-pointer"
            color="#5F6C85"
            icon="close"
            @click="onCloseSupport"
          />
        </div>

        <h4 class="mt-4 text-mgm-txt-md font-medium text-foreground-emphasis">
          {{
            relationMomentId
              ? $t("generic.adminContact")
              : $t("baseSupport.contact")
          }}
        </h4>

        <p class="mt-1 text-mgm-txt-sm font-normal text-foreground-default">
          {{
            relationMomentId
              ? $t("baseSupport.contactAdmin.description")
              : $t("baseSupport.problem")
          }}
        </p>
      </div>

      <!-- Form -->
      <div class="flex-1 p-6">
        <FieldTextarea
          id="message"
          v-model="formData.message"
          :validation="v$.message"
          :label="$t('baseSupport.description')"
          :description="$t('baseSupport.specific')"
          :placeholder="$t('baseSupport.placeholder')"
          :rows="mq.smPlus ? 7 : 11"
          required
        />

        <template v-if="relationMomentId">
          <div class="mb-[5px] mt-4 flex items-start">
            <FieldLabel
              id="document"
              :label="$t('baseSupport.contactAdmin.file.join')"
            />

            <span
              class="ml-1 inline-block text-mgm-txt-sm font-normal italic text-foreground-default"
            >
              {{ `(${$t("baseSupport.contactAdmin.file.optional")})` }}
            </span>
          </div>

          <FieldFileUploader
            id="document"
            v-model:modelValue="formData.document"
            class="mb-6"
            :validation="v$.document"
            accept="audio/*, video/*, image/*, .pdf, .doc"
            :locale="$i18n.locale"
          />
        </template>
      </div>

      <!-- CTA -->
      <div
        class="flex flex-col items-end border-t border-border-subtle px-6 py-4"
      >
        <BaseButton
          :disabled="isSending"
          :text="$t('baseSupport.send')"
          size="md"
          @click="onSendMessage"
        />
      </div>
    </template>

    <!-- Step 2: Success -->
    <div
      v-else
      class="relative mx-auto flex h-full flex-col items-center justify-center p-6 sm:min-h-[527px]"
    >
      <BaseIcon
        class="absolute right-0 top-10 cursor-pointer sm:right-6 sm:top-6"
        color="#5F6C85"
        icon="close"
        @click="onCloseSupport"
      />

      <BaseIcon
        class="max-w-fit border-8 border-background-success-default"
        icon="check"
        color="#03874C"
        bg-color="#D1FADF"
        :size="24"
      />

      <p
        class="my-4 max-w-xs text-center text-mgm-txt-lg font-medium text-foreground-emphasis"
        v-html="$t('baseSupport.sent')"
      ></p>

      <BaseButton
        :text="$t('generic.close')"
        size="md"
        @click="onCloseSupport"
      />
    </div>
  </div>
</template>
