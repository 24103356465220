<script setup lang="ts">
import type { IUserCriteria } from '@/types/IUserCriteria';
import { FieldSelect } from '@magma-app/magma-lapilli';
import { computed } from 'vue';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps([
	'modelValue',
	'validation',
	'disableEmail',
	'criteria',
]);
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

interface i extends IUserCriteria {
	answerKey?: string;
	answerKeys?: string[];
}

const currentCriteriaKey = (crits: i[], currentCriteriaId: number) => {
	const criteria = crits as IUserCriteria[];
	const currentCriteria = criteria.find(
		(crit) => crit.id === currentCriteriaId
	);

	if (currentCriteria) {
		if (currentCriteria.type === 'singleSelect') {
			return currentCriteria.answerKey;
		} else {
			return currentCriteria.answerKeys;
		}
	}
};
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ t('helperOnboarding.criteria.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ t('helperOnboarding.criteria.subtitle') }}
		</h3>
		<FieldSelect
			v-for="(crit, index) in props.criteria"
			:key="index"
			:options="
				crit.options.map((o: any) => ({ label: o.value, value: o.key }))
			"
			:placeholder="crit.label"
			@update:model-value="
				if ($event) {
					emit(
						'update:modelValue',
						crit.type === 'singleSelect'
							? {
									...crit,
									criteriaId: crit.id,
									answerKey: $event,
								}
							: {
									...crit,
									criteriaId: crit.id,
									answerKeys: $event,
								}
					);
				}
			"
			:model-value="currentCriteriaKey(props.modelValue, crit.id)"
			:label="crit.label"
			:validation="props.validation"
			required
			:multiple="crit.type === 'multiSelect'"
			class="mb-6"
		/>
	</div>
</template>
